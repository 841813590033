import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { serializeError } from 'serialize-error';
import { PersonalShopperContentfulData } from '../types/Sagas';
import { personalShopperActionTypes as actionTypes } from '../actions/personalShopperPageActions';
import { getClient } from '../utilities/helpers';

function* loadPersonalShopperSaga(): Generator<
  CallEffect | PutEffect,
  void,
  PersonalShopperContentfulData
> {
  try {
    const client = getClient();
    const data = yield call(client.getEntries, {
      content_type: 'personalShoppingPage',
    });
    yield put({
      type: actionTypes.LOAD_PERSONAL_SHOPPER_DATA_SUCCESS,
      data,
    });
  } catch (error) {
    const errorObj = serializeError(error);
    const message =
      typeof errorObj.message !== 'undefined'
        ? errorObj.message
        : 'There was an error.';
    const stack =
      typeof errorObj.stack !== 'undefined' ? errorObj.stack : '';

    yield put({
      type: actionTypes.LOAD_PERSONAL_SHOPPER_DATA_ERROR,
      error: message,
      stack,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(
    actionTypes.LOAD_PERSONAL_SHOPPER_DATA,
    loadPersonalShopperSaga,
  );
}

export default loadSagaAll;
