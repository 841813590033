/**
 * locationPageSaga.js
 * Fetches data from contentful, which will interact with Redux to store data
 */
import axios from 'axios';
import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { locationPageActionTypes as actionTypes } from '../actions/locationPageActions';
import {
  calculateNearestStores,
  getClient,
} from '../utilities/helpers';
import {
  Location,
  LocationType,
  PopularProductResponse,
} from '../types/Locations';

const LOCATION_DISTANCE_THRESHOLD = 100;

type LocationPageData = {
  items: {
    fields: Location;
  }[];
  data: { data: PopularProductResponse };
};

function getNearStores(
  currentStoreLat: number,
  currentStoreLon: number,
  stores: Location[],
): Location[] {
  const nearStores = calculateNearestStores(
    currentStoreLat,
    currentStoreLon,
    stores,
    LOCATION_DISTANCE_THRESHOLD,
  );
  const MAX_NEAR_STORES_TO_DISPLAY = 2;
  const nearStoresToDisplay = [];
  if (nearStores.length > MAX_NEAR_STORES_TO_DISPLAY) {
    nearStoresToDisplay.push(nearStores[1]);
    nearStoresToDisplay.push(nearStores[2]);
  } else if (nearStores.length === MAX_NEAR_STORES_TO_DISPLAY) {
    nearStoresToDisplay.push(nearStores[1]);
  }
  return nearStoresToDisplay;
}

function* loadLocationPageSaga({
  params,
}: {
  params: {
    slug: string;
  };
  type: string;
}): Generator<CallEffect | PutEffect, void, LocationPageData> {
  try {
    const client = getClient('storeLocation');

    const storesData: {
      items: { fields: Location }[];
    } = yield call(client.getEntries, {
      content_type: 'storeLocation',
    });

    const slug = params?.slug;

    let store: Location = {};
    const flagshipStores: Location[] = [];
    const fashionphileStores: Location[] = [];
    const salonStores: Location[] = [];
    const popUpStores: Location[] = [];
    const studioStores: Location[] = [];
    const serviceDeskStores: Location[] = [];
    storesData.items.forEach((element) => {
      if (element?.fields?.type === LocationType.FLAGSHIP) {
        flagshipStores.push(element.fields);
      }
      if (element?.fields?.type === LocationType.FASHIONPHILE) {
        fashionphileStores.push(element.fields);
      }
      if (element?.fields?.type === LocationType.SALON) {
        salonStores.push(element.fields);
      }
      if (element?.fields?.type === LocationType.POP_UP) {
        popUpStores.push(element.fields);
      }
      if (element?.fields?.type === LocationType.STUDIO) {
        studioStores.push(element.fields);
      }
      if (element?.fields?.type === LocationType.SERVICE_DESK) {
        serviceDeskStores.push(element.fields);
      }
      if (element?.fields?.slug === slug) {
        store = element.fields;
      }
    });
    const stores = [
      ...flagshipStores,
      ...fashionphileStores,
      ...salonStores,
      ...popUpStores,
      ...studioStores,
    ];

    if (!slug) {
      yield put({
        type: actionTypes.LOAD_LOCATION_DATA_SUCCESS,
        data: {
          stores,
          serviceDeskStores,
        },
      });
    } else {
      const nearStores =
        store?.address &&
        getNearStores(store.address.lat, store.address.lon, stores);

      const url = `${process.env.PRODUCT_API}/productList?options=${store.citySlug}&sort=popularity-desc`;

      const popularProductsResponse: {
        data: { data: PopularProductResponse };
      } = yield call(axios, url);

      const data = {
        flagshipStores,
        nearStores,
        popularProducts: popularProductsResponse?.data?.data,
        store,
        slug,
        stores,
        serviceDeskStores,
      };

      yield put({
        type: actionTypes.LOAD_LOCATION_DATA_SUCCESS,
        data,
      });
    }
  } catch (error) {
    const message =
      error instanceof Error ? error?.message : 'There was an error.';
    yield put({
      type: actionTypes.LOAD_LOCATION_DATA_ERROR,
      error: message,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(
    actionTypes.LOAD_LOCATION_PAGE_DATA,
    loadLocationPageSaga,
  );
}

export default loadSagaAll;
