/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { NewsletterReducer } from '../types/Store';
import { newsletterActionTypes as actionTypes } from '../actions/newsletterActions';
import {
  reducerErrorMessage,
  reducerHelper,
} from '../utilities/helpers';

const initialState = {
  success: false,
  error: null,
  data: null,
  isLoading: false,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: NewsletterReducer = initialState,
  action: any,
): NewsletterReducer => {
  switch (action.type) {
    case HYDRATE: {
      const newState = {
        ...state,
        ...action.payload.newsletterReducer,
      };
      newState.success = state.success;

      return newState;
    }
    case actionTypes.ADD_NEWSLETTER_ERROR: {
      const newState = reducerHelper(state, action.type);
      reducerErrorMessage(state, action);
      return {
        ...newState,
        isLoading: false,
      };
    }
    case actionTypes.ADD_NEWSLETTER_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      return {
        ...newState,
        success: action?.data?.success,
        isLoading: false,
      };
    }
    case actionTypes.LOADING_NEWSLETTER: {
      const newState = reducerHelper(state, action.type);
      return {
        ...newState,
        isLoading: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;
