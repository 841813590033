import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { algoliaListingPageActionTypes as actionTypes } from '../actions/algoliaListingPageActions';
import axios, { AxiosResponse } from 'axios';
import { productApiHelper } from '../utilities/helpers';
import { validateSort } from '../utilities/algoliaHelpers';

function* algoliaListingPageSaga({
  params,
}: {
  params: {
    listingPageSlug: any;
    page: number;
    sort: string;
    filters: string;
    search: string;
    query: any;
  };
  type: string;
}): Generator<CallEffect | PutEffect, void, AxiosResponse<any>> {
  const { listingPageSlug, page, sort, filters, search, query } =
    params;

  const categoryPageID =
    listingPageSlug?.[listingPageSlug?.length - 1] || '';
  const domain = productApiHelper();
  const url = `${domain}/content/productList/${categoryPageID}`;
  const isValidSort = sort ? validateSort(sort) : false;
  const isNewArrivals =
    listingPageSlug.length > 0 &&
    listingPageSlug[0] === 'new-arrivals';
  const isSearch = search?.length > 0;
  if (search || !categoryPageID) {
    yield put({
      type: actionTypes.LOAD_ALGOLIA_LISTING_PAGE_SUCCESS,
      data: {
        listingPageSlug,
        page,
        ...(isNewArrivals
          ? { defaultSort: 'date-desc', sort: 'date-desc' }
          : {}),
        ...(isSearch
          ? { defaultSort: 'relevance', sort: 'relevance' }
          : {}),
        ...(isValidSort ? { sort } : {}),
        filters,
        search,
        query,
      },
    });
  } else {
    const res = yield call(axios.get, url);
    const contentfulData = res.data?.data ? res.data?.data : {};
    yield put({
      type: actionTypes.LOAD_ALGOLIA_LISTING_PAGE_SUCCESS,
      data: {
        ...contentfulData,
        listingPageSlug,
        page,
        ...(isValidSort ? { sort } : {}),
        ...(contentfulData?.sort
          ? { defaultSort: contentfulData.sort }
          : {}),
        filters,
        search,
        query,
      },
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(
    actionTypes.LOAD_ALGOLIA_LISTING_PAGE,
    algoliaListingPageSaga,
  );
}

export default loadSagaAll;
