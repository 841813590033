import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { COOKIES as COOKIE_NAMES } from '../constants';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { productApiHelper } from '../helpers';
import { RecentProduct } from '../searchHelpers';

const LAST_INDEX = -1;
const useRecentProducts = (): {
  recentProductsData: RecentProduct[];
  recentProducts: number[];
  addRecentProduct: (productId: number) => void;
  removeRecentProduct: (productId: number) => void;
  clearRecentProducts: () => void;
} => {
  const [cookies] = useCookies([COOKIE_NAMES.IDENTITY_ID]);
  const key = `Recently-Viewed-${cookies[COOKIE_NAMES.IDENTITY_ID]}`;
  const [itemAdded, setItemAdded] = useState(false);

  const [state, setState] = useState<number[]>(() => {
    let value;
    try {
      const storedValue = localStorage.getItem(key);
      value = storedValue ? JSON.parse(storedValue) : [];
    } catch (_e) {
      value = [];
    }
    if (value.length > 0) {
      value = value.filter((id: unknown) => typeof id === 'number');
    }
    return value;
  });

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (e) {
      console.error('error setting recent products', e);
    }
  }, [state]);

  const addRecentProduct = (productId: number): void => {
    const recentProductsArrayMaxLength = 30;
    const recentProductsArrayIsFull =
      state.length >= recentProductsArrayMaxLength;

    const newState: number[] = recentProductsArrayIsFull
      ? state.slice(0, LAST_INDEX)
      : state;
    setState([
      productId,
      ...newState.filter(
        (id) =>
          id !== productId && id !== null && typeof id === 'number',
      ),
    ]);
    setItemAdded(true);
  };

  const removeRecentProduct = (productId: number): void => {
    setState([
      ...state.filter(
        (id) =>
          id !== productId && id !== null && typeof id === 'number',
      ),
    ]);
  };

  const clearRecentProducts = (): void => {
    setState([]);
  };

  const MAX_ITEMS = 15;
  const MAX_ITEMS_ADDED = 16;
  const firstRecentItem = itemAdded ? 1 : 0;
  const lastRecentItem = itemAdded ? MAX_ITEMS_ADDED : MAX_ITEMS;

  const recentProducts = state.slice(firstRecentItem, lastRecentItem);

  const domain = productApiHelper();

  const getRecentProductData = async (): Promise<
    RecentProduct[] | undefined
  > => {
    try {
      const url = `${domain}/recentProducts?ids=${recentProducts.join(
        '+',
      )}`;
      const { data: recentProductsResponse } = await axios.get(url);
      if (recentProductsResponse?.status === 'success') {
        const recentProductsApiResponse =
          recentProductsResponse?.data.results;
        const productHistoryContainsSoldOutItem =
          recentProducts.length !== recentProductsApiResponse.length;

        if (productHistoryContainsSoldOutItem) {
          const soldOutProducts = recentProducts.filter(
            (productId) => {
              let itemIsSoldOut = true;
              recentProductsApiResponse.forEach(
                (product: RecentProduct) => {
                  if (product.id === productId) {
                    itemIsSoldOut = false;
                  }
                },
              );
              return itemIsSoldOut;
            },
          );
          soldOutProducts?.forEach((soldOutProductId) => {
            removeRecentProduct(soldOutProductId);
          });
        }

        return recentProductsApiResponse;
      }
    } catch (error) {
      console.error('Error in getRecentProductData', error);
    }
  };

  const { data: recentProductsResponse } = useQuery({
    queryKey: ['recentlyViewed', recentProducts],
    queryFn: () => getRecentProductData(),
    enabled: recentProducts.length > 0,
    refetchOnWindowFocus: false,
  });

  const loadingInfo = {
    brand: 'loading',
    condition: 'loading',
    conditionValue: 'loading',
    discountedPrice: '000.00',
    image: '/icons/apple-touch-icon-152x152.png',
    price: '000.00',
    slug: 'loading',
    title: 'loading',
    titleWithoutBrand: 'loading',
  };

  const fallbackData = recentProducts.map((product) => ({
    id: product,
    ...loadingInfo,
  }));

  const recentProductsData = recentProductsResponse || fallbackData;

  return {
    recentProductsData,
    recentProducts,
    addRecentProduct,
    removeRecentProduct,
    clearRecentProducts,
  };
};

export { useRecentProducts };
