const myAccountData = [
  {
    title: 'My Account',
    header: 'My Account',
    content: [
      {
        text: 'Dashboard',
        link: `${process.env.WEB_APP}/account/dashboard`,
      },
      {
        text: 'Alerts',
        link: `${process.env.WEB_APP}/account/alerts`,
      },
      {
        text: 'My Purchases',
        link: `${process.env.WEB_APP}/account/purchases`,
      },
      {
        text: 'My Quotes',
        link: `${process.env.WEB_APP}/account/sales/quotes`,
      },
      {
        text: 'My Sales',
        link: `${process.env.WEB_APP}/account/sales/products`,
      },
    ],
  },
];
const desktopLinks = [
  {
    link: `${process.env.WEB_APP}/sell-with-us`,

    ariaLabel: 'Sell Link',
    name: 'Sell',
  },
  {
    link: `${process.env.WEB_APP}/account/followed-products`,

    ariaLabel: 'Following Link',
    name: 'Following',
    loggedIn: true,
  },
  {
    link: `${process.env.WEB_APP}/alerts`,

    ariaLabel: 'Alert Link',
    name: 'Alerts',
    loggedIn: false,
  },
  {
    link: `${process.env.WEB_APP}/account/dashboard`,
    ariaLabel: 'Profile Link',
    name: 'My Account',
    loggedIn: true,
  },
  {
    link: `${process.env.WEB_APP}/login`,
    ariaLabel: 'Login Link',
    name: 'Sign in',
    loggedIn: false,
  },
  {
    link: `${process.env.CHECKOUT_APP}/shopping-bag`,
    ariaLabel: 'Shopping Bag Link',
    name: 'Shopping Bag',
  },
];

const sellDropDownLinks = [
  {
    name: 'Submit item for quote',
    link: `${process.env.SELLER_APP}/sell-your-bag`,
  },
  {
    name: 'Schedule a virtual appointment',
    link: `${process.env.SELLER_APP}/virtual-appointment`,
  },
  {
    name: 'Find a selling location',
    link: `${process.env.SELLER_APP}/locations`,
  },
];

export { desktopLinks, myAccountData, sellDropDownLinks };
