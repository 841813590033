export const partnersProgramActionTypes = {
  LOAD_PARTNERS_PROGRAM_DATA: 'LOAD_PARTNERS_PROGRAM_DATA',
  LOAD_PARTNERS_PROGRAM_DATA_ERROR:
    'LOAD_PARTNERS_PROGRAM_DATA_ERROR',
  LOAD_PARTNERS_PROGRAM_DATA_SUCCESS:
    'LOAD_PARTNERS_PROGRAM_DATA_SUCCESS',
};

export function loadPartnersProgram() {
  return {
    type: partnersProgramActionTypes.LOAD_PARTNERS_PROGRAM_DATA,
  };
}
