import React from 'react';
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary';
import Custom404 from './customized404';

export default function ErrorBoundaryComponent({
  children,
  errorMessage = '',
}: {
  children: React.ReactElement;
  errorMessage?: string;
}): React.ReactElement {
  const handleError = useErrorHandler();
  if (errorMessage !== '') {
    handleError(errorMessage);
  }
  return (
    <ErrorBoundary FallbackComponent={Custom404 as any}>
      {children}
    </ErrorBoundary>
  );
}

ErrorBoundaryComponent.defaultProps = {
  errorMessage: '',
};
