export const locationPageActionTypes = {
  LOAD_LOCATION_PAGE_DATA: 'LOAD_LOCATION_PAGE_DATA',
  LOAD_LOCATION_DATA_SUCCESS: 'LOAD_LOCATION_DATA_SUCCESS',
  LOAD_LOCATION_DATA_ERROR: 'LOAD_LOCATION_DATA_ERROR',
};

export function loadLocationPage(params) {
  return {
    params,
    type: locationPageActionTypes.LOAD_LOCATION_PAGE_DATA,
  };
}
