import { validateEventSchema } from '../validateSchema';
import { productMapper } from './productMapper';
import detailClickSchema from '../schema/detailClick.json';

/**
 * @param {{
 * brand?: string;
 * brands?:  any[];
 * categories?:  any[];
 * category?:  string;
 * color?:  string | null;
 * condition?:  string;
 * currency?:  string;
 * daysOnSale?:  number;
 * discountTier?:  number;
 * lastCall?:  number;
 * material?:  string | null;
 * name?:  string;
 * price?:  number;
 * productDiscount?:  number;
 * productDiscountAmount?:  number;
 * productId?:  number;
 * productSubcategory?:  any[];
 * quantity?:  number;
 * sku?:  string | null;
 * style?:  any[] | null;
 * tabClick:  number;
 * tabClickType:  string;
 * url?:  string;
 * value?:  number;
 * }} data;
 * @returns see schema/detailClick
 */
export const detailClickMapper = ({
  tabClick,
  tabClickType,
  ...data
}) => {
  const validData = validateEventSchema(
    {
      ...productMapper(data),
      tab_click: tabClick,
      tab_click_type: tabClickType,
    },
    detailClickSchema,
  );
  return validData || {};
};
