/**
 * aboutPageSaga.js
 * Fetches data from contentful, which will interact with Redux to store data
 */
import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { AboutPageContentfulData } from '../types/Sagas';
import { aboutPageActionTypes as actionTypes } from '../actions/aboutPageActions';
import { getClient } from '../utilities/helpers';
import { ABOUT_SLUG } from '../utilities/constants';

function* loadAboutPageSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AboutPageContentfulData
> {
  try {
    const client = getClient();
    const data = yield call(client.getEntries, {
      content_type: 'post',
      limit: 1,
      'fields.slug[in]': ABOUT_SLUG,
    });

    yield put({
      type: actionTypes.LOAD_ABOUT_PAGE_DATA_SUCCESS,
      data,
      aboutPage: 'about',
    });
  } catch (error) {
    const message =
      error instanceof Error ? error?.message : 'There was an error.';
    yield put({
      type: actionTypes.LOAD_ABOUT_PAGE_DATA_ERROR,
      error: message,
    });
  }
}

// loadSagaAll is a watcher saga that detects action type for loading about page data
// and will fire off the loadAboutPageSaga saga function
function* loadSagaAll(): Generator {
  yield takeEvery(
    actionTypes.LOAD_ABOUT_PAGE_DATA,
    loadAboutPageSaga,
  );
}

export default loadSagaAll;
