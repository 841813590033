/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { cloneDeep, get } from 'lodash';
import { aboutPageActionTypes as actionTypes } from '../actions/aboutPageActions';
import type { AboutPageReducer } from '../types/Store';

import {
  reducerErrorMessage,
  reducerHelper,
} from '../utilities/helpers';

const initialState = {
  aboutData: {
    backgroundImage: [],
    description: [],
  },
  error: null,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AboutPageReducer = initialState,
  action: any,
): AboutPageReducer => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.aboutPageReducer };
    }
    case actionTypes.LOAD_ABOUT_PAGE_DATA_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_ABOUT_PAGE_DATA_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      const data = cloneDeep(action.data);
      const aboutData = get(data, 'items.0.fields', {});
      aboutData.canonical = `${process.env.DOMAIN}/about`;
      return {
        ...newState,
        aboutData,
      };
    }
    default:
      return state;
  }
};

export default reducer;
