import { HYDRATE } from 'next-redux-wrapper';
import { MetadataReducer } from '../types/Store';
import { actionTypes } from '../actions/metaDataActions';
import { reducerErrorMessage } from '../utilities/helpers';

const initialState = {
  metadata: null,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: MetadataReducer = initialState,
  action: any,
): MetadataReducer => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        metadata: action.payload.metadataReducer.metadata,
      };
    }
    case actionTypes.METADATA_LOAD_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.METADATA_LOAD_SUCCESS: {
      const newState = {
        ...state,
        metadata: action.data,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
