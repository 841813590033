import axios from 'axios';
import isEmail from 'validator/lib/isEmail';

export const isValidEmailFormat = (email) => {
  if (!isEmail(email)) {
    return false;
  }
  return true;
};

const emailValidator = async (email) => {
  if (!email || !isValidEmailFormat(email)) {
    return false;
  }
  try {
    const validationReturn = await axios.get(
      `/api/emailValidate/${encodeURIComponent(email)}`,
    );
    if (validationReturn.data?.isValid === false) {
      return false;
    }
    return true;
  } catch {
    return true;
  }
};

export default emailValidator;
