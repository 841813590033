import events, { EVENTS } from './events';
import { productClickedMapper } from './mapper/productClicked';
import { capitalizeMulti, undefToNull } from '../helpers';
import type {
  ProductDataEntity,
  FilterDictWithParentMeta,
  FilterWithParentMeta,
  SortValue,
  ListingData,
  AlgoliaListingData,
} from '../../types/Store';
import {
  GetFilterArgs,
  GetSegmentFilterEvent,
  SegmentFilterEvent,
  SegmentFilterValue,
  SegmentSortValue,
} from './interfaces';
import favoriteMapper from './mapper/favoriteMapper';

/**
 * Plugs into
 * ```
 * <Carousel
 *  ...
 *  tealium={{ link: useCarouselProductClicked(productData) }}
 * />
 * ```
 */
export const useCarouselProductClicked = (
  productData: ProductDataEntity[] | null | undefined,
  { product_id, idx }: { [key: string]: number },
  listData: { listType: string; listValue: String | undefined },
): void => {
  const foundProduct = productData?.find(
    (pd: { id: number }) => pd.id === product_id,
  );
  events.emit(
    EVENTS.productClicked,
    productClickedMapper({
      ...foundProduct,
      ...listData,
      position: idx + 1,
    }),
  );
};

/**
 * sort.key contains 'asc' | 'desc'
 * sort.value is the label
 */
export const mapSortToSegment = ({
  dir,
  trackingType,
}: SortValue): SegmentSortValue[] => {
  if (!trackingType) {
    return [
      {
        type: 'Age',
        value: 'Descending',
      },
    ];
  }

  return [
    {
      type: trackingType,
      value: dir || null,
    },
  ];
};

/**
 * Currency / Misc String mapping:
 * strictRegex - used to format strings to replace everything but:
 *   alphanumeric, period, dollar sign, comma, dash, forward slash, ampersand
 * strictRegexTest - used to test for occurrence of:
 *   dollar sign, comma, dash, forward slash, ampersand
 * looseRegex - used to format strings to replace everything but:
 *   alphanumeric, period, dollar sign, comma, forward slash, ampersand
 */
const strictRegex = /[^0-9.a-zA-Z$,-/&]/g;
const strictRegTest = /\$|,|-|\/|&/;
const looseRegex = /[^0-9.a-zA-Z$,/&]/g;
const mapFilterToSegment = ({
  slug,
  name,
  parentName,
  type,
}: FilterWithParentMeta): SegmentFilterValue => {
  let useValue = slug?.replace(looseRegex, ' ');

  if (type === 'discounted_tier') {
    useValue = name.includes('%') ? name.split(' ')[0] : name;
  } else if (name && strictRegTest.test(name)) {
    useValue = name.replace(strictRegex, ' ');
  }

  return {
    type: capitalizeMulti(parentName.replace(looseRegex, ' ')),
    value: capitalizeMulti(useValue),
  };
};

// nb. needs .flatMap to account for multiples of the same filter type
// e.g. color == 'black' & 'beige'
const mapFiltersToSegment = (
  filters: FilterDictWithParentMeta,
): SegmentFilterValue[] =>
  Object.values(filters).flatMap((categoryFilters) =>
    categoryFilters.map(mapFilterToSegment),
  );

const getFilterArgs: GetFilterArgs = (
  baseArgs,
  sortBy,
  { filters, sorts },
) => ({
  ...baseArgs,
  filters: filters ? mapFiltersToSegment(filters) : [],
  sorts: mapSortToSegment(sorts || sortBy),
});

export const getSegmentFilterEvent: GetSegmentFilterEvent = (
  listType,
  listValue,
  sortBy,
) => {
  const baseArgs: SegmentFilterEvent = {
    listType: capitalizeMulti(listType),
    listValue: capitalizeMulti(listValue),
    filters: [],
    sorts: mapSortToSegment(sortBy),
  };
  return {
    cleared: (): void => {
      if (!listType) {
        return;
      }
      events.emit(EVENTS.filter, 'Unfiltered', baseArgs);
    },
    filtered: (args): void => {
      if (!listType) {
        return;
      }
      const filterArgs = getFilterArgs(baseArgs, sortBy, args);
      events.emit(EVENTS.filter, 'Filtered', filterArgs);
    },
    unfiltered: (args): void => {
      if (!listType) {
        return;
      }
      const filterArgs = getFilterArgs(baseArgs, sortBy, args);
      events.emit(EVENTS.filter, 'Unfiltered', filterArgs);
    },
  };
};

const properCaps = (str: string | undefined): string => {
  if (!str) {
    return 'Unknown';
  }
  return str.replace(/^[_-]*(.)|[_-]+(.)/g, (_, c, d) =>
    c ? c.toUpperCase() : ` ${d.toUpperCase()}`,
  );
};

export const getSegmentProductList = (
  listingPageData: ListingData | AlgoliaListingData,
): {
  productData: null | string;
  listType: string;
  listValue: string;
} => {
  const productData = null;

  switch (true) {
    case listingPageData.hasOwnProperty('customPLP') &&
      !!(listingPageData as ListingData)?.customPLP:
      return {
        productData,
        listType: 'Custom',
        listValue: properCaps(
          (listingPageData as ListingData)?.customPLP?.title,
        ),
      };
    case listingPageData.hasOwnProperty('legacyType') &&
      (listingPageData as AlgoliaListingData)?.legacyType ===
        'CustomPLP':
      return {
        productData,
        listType: 'Custom',
        listValue: properCaps(
          (listingPageData as AlgoliaListingData)?.heading,
        ),
      };
    case !!listingPageData?.search:
      return {
        productData,
        listType: 'Search',
        listValue: properCaps(listingPageData?.search),
      };
    case listingPageData?.categoryType === 'brands':
      return {
        productData,
        listType: 'Brand',
        listValue: properCaps(listingPageData?.heading),
      };
    case listingPageData?.categoryType === 'collection':
      return {
        productData,
        listType: 'Collection',
        listValue: properCaps(listingPageData?.heading),
      };
    case listingPageData?.categoryType === 'discounted':
      return {
        productData,
        listType: 'Discounted',
        listValue: properCaps(listingPageData?.heading),
      };
    case listingPageData?.categoryType === 'new-arrivals' ||
      listingPageData?.listingPageSlug?.[0] === 'new-arrivals':
      return {
        productData,
        listType: 'New Arrivals',
        listValue: 'New Arrivals',
      };
    case listingPageData?.heading === 'Styles':
      return {
        productData,
        listType: 'Category',
        listValue: 'Bags',
      };
    case !listingPageData?.categoryType || !listingPageData?.heading:
      return {
        productData,
        listType: 'Brand',
        listValue: 'All',
      };
    case !!listingPageData?.categoryType:
      return {
        productData,
        listType: 'Category',
        listValue: properCaps(listingPageData?.heading),
      };
    default:
      return {
        productData,
        listType: 'Unknown',
        listValue: 'Unknown',
      };
  }
};

export const analyticsFavorite = (
  product: Partial<ProductDataEntity>,
  isAdd: boolean,
): void => {
  events.emit(
    EVENTS.favorite,
    favoriteMapper(product),
    isAdd
      ? 'Product Added to Wishlist'
      : 'Product Removed from Wishlist',
  );
};

export const mapSegmentUser = (user: any): any => {
  const { city, country, postalCode, state, address1, phone } =
    user?.sellerAddress ||
    user?.primaryShippingAddress ||
    user?.primaryBillingAddress ||
    {};

  const useAddress = {
    city,
    country,
    postalCode,
    state,
    street: address1,
  };

  return undefToNull({
    address: useAddress,
    createdAt: user?.createdAt,
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    name: `${user?.firstName} ${user?.lastName}`,
    phone,
    user_id: user?.id,
  });
};
