export const actionTypes = {
  HYDRATE: 'HYDRATE',
  SET_CART_DATA: 'SET_CART_DATA',
  SET_CART_DATA_SUCCESS: 'SET_CART_DATA_SUCCESS',
  SET_CART_DATA_ERROR: 'SET_CART_DATA_ERROR',
  GET_CART_DATA: 'GET_CART_DATA',
  GET_CART_DATA_SUCCESS: 'GET_CART_DATA_SUCCESS',
  GET_CART_DATA_ERROR: 'GET_CART_DATA_ERROR',
};

export function setCartData(cartData) {
  return {
    type: actionTypes.SET_CART_DATA,
    cartData,
  };
}

// get all cart data: whoami + getcart
export function getAllCartData(cartData) {
  return {
    type: actionTypes.GET_CART_DATA,
    cartData,
  };
}
