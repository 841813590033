import { undefToNull } from '../../helpers';
import { recommendedProductMapper } from './recommendedProductMapper';

/**
 * @param {object} data
 * @returns see schema/productListViewed
 */
export const productListViewedMapper = (data) => {
  const formatted = undefToNull({
    list_id: data?.listType,
    category: data?.listValue || data?.title || '',
    products: data.productData
      ? data.productData.map((product) =>
          recommendedProductMapper(product),
        )
      : null,
  });
  return {
    ...formatted,
  };
};

export const PRODUCT_LIST_VIEWED_TYPE = {
  category: 'Category',
  collection: 'Collection',
  designers: 'Designers',
  featuredItems: 'Featured Items',
  newArrivals: 'New Arrivals',
  recentlyViewed: 'Recently Viewed',
  recommendations: 'Recommendations',
  sale: 'Sale',
};
