import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Footer } from '@fashionphile/component-library';
import { useDispatch, useSelector } from 'react-redux';
import { parseFooterData } from '../../utilities/helpers';
import { isValidEmailFormat } from '../../utilities/emailValidator';
import { newsletter } from '../../actions/newsletterActions';
import type { ShopState } from '../../types/Store';

const FooterComponent = (): React.ReactElement => {
  const footerData =
    useSelector(
      (state: ShopState) => state.headerFooterReducer.footerData,
    ) || [];
  const newsletterStatus = useSelector(
    (state: ShopState) => state?.newsletterReducer?.success,
  );
  const error = useSelector(
    (state: ShopState) => state?.newsletterReducer?.error,
  );

  const isLoading = useSelector(
    (state: ShopState) => state?.newsletterReducer?.isLoading,
  );
  const [formVal, setFormVal] = useState('');
  const [emailError, setEmailError] = useState('');
  const FOOTER_CUSTOMER_SERVICE_LINKS_INDEX = 0;
  const FOOTER_LEGAL_LINKS_INDEX = 5;
  const columns = footerData.slice(
    FOOTER_CUSTOMER_SERVICE_LINKS_INDEX,
    FOOTER_LEGAL_LINKS_INDEX,
  );
  const parsedColumns = parseFooterData(columns);
  const dispatch = useDispatch();
  const validate = (): boolean => {
    if (!formVal) {
      setEmailError('Please enter email');
      return false;
    }
    if (!isValidEmailFormat(formVal)) {
      setEmailError('Please enter valid email');
      return false;
    }
    return true;
  };
  const handleChange = (e: Event): void => {
    setFormVal((e.target as HTMLInputElement).value);
  };
  const handleSubmit = (): void => {
    if (validate()) {
      dispatch(newsletter(formVal));
    }
  };
  const errorText = emailError || error?.customMessage;
  const router = useRouter();

  useEffect(() => {
    if (!isLoading) {
      setFormVal('');
      setEmailError('');
    }
  }, [isLoading]);

  return (
    <Footer
      ariaLabel="Footer"
      buttonAriaLabel="button"
      buttonTabIndex="0"
      columnsData={parsedColumns}
      id="footer id"
      inputAriaLabel="footer email subscribe form"
      inputBaseHeader="Subscribe to our newsletter"
      placeholder="Email address"
      socialIcons={[
        {
          ariaLabel: 'Instagram',
          href: '//instagram.com/fashionphile',
          iconName: 'icon-brand-instagram',
          rel: 'noreferrer',
          target: '_blank',
        },
        {
          ariaLabel: 'Facebook',
          href: '//www.facebook.com/fashionphile',
          iconName: 'icon-brand-facebook-f',
          rel: 'noreferrer',
          target: '_blank',
        },
        {
          ariaLabel: 'Pinterest',
          href: '//www.pinterest.com/fashionphile/',
          iconName: 'icon-brand-pinterest',
          rel: 'noreferrer',
          target: '_blank',
        },
        {
          ariaLabel: 'Twitter',
          href: '//twitter.com/fashionphile',
          iconName: 'icon-brand-twitter',
          rel: 'noreferrer',
          target: '_blank',
        },
        {
          ariaLabel: 'Youtube',
          href: '//www.youtube.com/user/fashionphile',
          iconName: 'icon-brand-youtube',
          rel: 'noreferrer',
          target: '_blank',
        },
        {
          ariaLabel: 'TikTok',
          href: '//www.tiktok.com/@fashionphile',
          iconName: 'icon-brand-tiktok',
          rel: 'noreferrer',
          target: '_blank',
        },
      ]}
      socialsHeader="Follow us"
      subscriptionMessage="You've successfully subscribed!"
      tabIndex="0"
      onChange={handleChange}
      buttonOnClick={(): void => handleSubmit()}
      buttonSubmission={newsletterStatus}
      value={formVal}
      error={errorText || ''}
      className="footerClass"
      nextRouter={router}
    />
  );
};

export default FooterComponent;
