/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { cloneDeep } from 'lodash';
import { partnersProgramActionTypes as actionTypes } from '../actions/partnersProgramActions';
import {
  reducerErrorMessage,
  reducerHelper,
} from '../utilities/helpers';
import { PartnersProgramReducer } from '../types/Store';

const initialState = {
  partnersProgramData: {
    author: null,
    canonical: '',
    content: null,
    coverImage: null,
    date: '',
    description: '',
    excerpt: '',
    hed: null,
    listContent: null,
    ogDescription: '',
    ogImage: '',
    slug: '',
    subhed: null,
    title: '',
  },
  error: null,
};

function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: PartnersProgramReducer = initialState,
  action: any,
): PartnersProgramReducer {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.partnersProgramReducer };
    }
    case actionTypes.LOAD_PARTNERS_PROGRAM_DATA_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_PARTNERS_PROGRAM_DATA_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      const data = cloneDeep(action.data.items[0].fields);
      data.listContent = cloneDeep(data.content);
      data.listContent.content = [data.content.content.shift()];
      data.ogImage = data.ogImage.fields.file.url;
      data.canonical = `${process.env.DOMAIN}/partners-program`;
      return {
        ...newState,
        ...{ partnersProgramData: data },
      };
    }
    default:
      return state;
  }
}

export default reducer;
