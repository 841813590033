import React, { useState } from 'react';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Header as HeaderNav } from '@fashionphile/component-library';
import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import {
  Node,
  BLOCKS,
  Document,
  MARKS,
} from '@contentful/rich-text-types';
import { useQuery } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';
import { ShopState } from '../../types/Store';
import { Bold, Text, SpanText } from '../contentful/text';
import {
  COOKIES as COOKIE_NAMES,
  DEFAULT_MILLISECONDS_WAIT_DEBOUNCE,
  DEFAULT_STALE_TIME,
} from '../../utilities/constants';
import events, { EVENTS } from '../../utilities/segment/events';
import {
  algoliaAutocompleteIndex,
  algoliaIndex,
} from '../../utilities/algoliaHelpers';
import {
  SearchProp,
  getSearchSuggestions,
} from '../../utilities/searchHelpers';
import { useRecentProducts } from 'src/utilities/hooks/useRecentProducts';
import { setLoader } from '../../actions';

const headerComponent = (): React.ReactElement => {
  const [searchValue, setSearchValue] = useState('');
  const MIN_SEARCH_LENGTH = 2;
  const setTerm = debounce((term) => {
    setSearchValue(term.toLowerCase());
  }, DEFAULT_MILLISECONDS_WAIT_DEBOUNCE);
  const { data } = useQuery({
    queryKey: ['suggestions', searchValue],
    queryFn: () => getSearchSuggestions(searchValue),
    enabled: searchValue.length > MIN_SEARCH_LENGTH,
    staleTime: DEFAULT_STALE_TIME,
  });
  const listingData = useSelector(
    (state: ShopState) => state.listingPageReducer.listingData,
  );
  const navigation = useSelector(
    (state: ShopState) => state.navigationReducer.navigation,
  );
  const rotatorData = useSelector(
    (state: ShopState) => state.headerFooterReducer.rotatorData,
  );
  const productsInCart = useSelector(
    (state: ShopState) => state.cartReducer.productsInCart,
  );
  const user = useSelector(
    (state: ShopState) => state.cartReducer.user,
  );
  const desktopLinks = useSelector(
    (state: ShopState) => state.headerFooterReducer.desktopLinks,
  );
  const myAccountData = useSelector(
    (state: ShopState) => state.headerFooterReducer.myAccountData,
  );
  const sellDropDownLinks = useSelector(
    (state: ShopState) => state.headerFooterReducer.sellDropDownLinks,
  );
  const dispatch = useDispatch();

  const suggestions: SearchProp = {
    setTerm,
    results: data ? data.data.results : null,
  };

  const options = {
    renderMark: {
      [MARKS.BOLD]: (
        text: React.ReactElement,
      ): React.ReactElement => <Bold className="bold">{text}</Bold>,
      [MARKS.ITALIC]: (
        text: React.ReactElement,
      ): React.ReactElement => (
        <SpanText className="italic">{text}</SpanText>
      ),
      [MARKS.UNDERLINE]: (
        text: React.ReactElement,
      ): React.ReactElement => (
        <SpanText className="underline">{text}</SpanText>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <Text className="excerpt">{children}</Text>
      ),
    },
    renderText: (text: string): string => text.replace('!', '?'),
  };

  const algoliaRouter = {
    push: ({
      pathname,
      query,
    }: {
      pathname: string;
      query: {
        history_search_suggestion?: boolean;
        search_suggestion?: boolean;
        search?: string;
      };
    }): void => {
      window.location.assign(
        `${pathname}${
          query?.search ? `?search=${query.search}` : ''
        }`,
      );
    },
  };

  const router =
    process.env.NEXT_PUBLIC_ALGOLIA_FLAG === 'true'
      ? algoliaRouter
      : useRouter();

  const [cookies] = useCookies();
  const currentSearchCookie = cookies[COOKIE_NAMES.CURRENT_SEARCH];

  const SearchLabelMap = {
    keyword_search: 'Keyword Search',
    recent_search: 'Recent Search',
    autosuggest: 'Autosuggest',
    trending_searches: 'Trending Searches',
    popular_designers: 'Popular Designers',
    recently_viewed: 'Recently Viewed',
    autosuggest_brand: 'Brand Autosuggest',
    saved_search: 'Saved Search',
  };

  const searchTrackingEvent = (
    value: string,
    searchType?: keyof typeof SearchLabelMap,
  ): void => {
    events.emit(EVENTS.productSearch, {
      query: value,
      search_type: searchType
        ? SearchLabelMap[searchType]
        : SearchLabelMap[
            currentSearchCookie?.searchAction as keyof typeof SearchLabelMap
          ],
    });
    dispatch(setLoader(true));
  };

  const handleSearch = (value: string): void => {
    searchTrackingEvent(value, 'keyword_search');
    if (process.env.NEXT_PUBLIC_ALGOLIA_FLAG === 'true') {
      window.location.assign(
        `${process.env.DOMAIN}/shop?search=${value}`,
      );
    } else {
      router.push({
        pathname: '/shop',
        query: {
          search: value,
        },
      });
    }
  };

  const rotatorContent =
    rotatorData &&
    rotatorData.map((rotator, key) => (
      <div key={`option-${key}`}>
        {rotator
          ? documentToReactComponents(
              rotator as Document,
              options as Options,
            )
          : null}
      </div>
    ));

  const searchResults =
    (listingData.results?.length || 0) > 0
      ? listingData.search
      : null;

  const { recentProductsData, clearRecentProducts } =
    useRecentProducts();

  return (
    <header className="header">
      {myAccountData &&
        desktopLinks &&
        navigation &&
        router &&
        rotatorContent &&
        sellDropDownLinks && (
          <HeaderNav
            accountData={myAccountData}
            ariaLabel="Header"
            cartCount={productsInCart}
            desktopLinks={desktopLinks}
            handleSearch={handleSearch}
            loggedIn={!!user?.id}
            mobileNavClassName="mobileHeaderFix"
            navData={navigation}
            nextRouter={router as any}
            rotatorClassName="rotatorSvgFix"
            rotatorData={rotatorContent}
            searchClassName="searchSvgFix"
            searchResults={searchResults}
            searchTrackingEvent={searchTrackingEvent}
            secureDomain={process.env.CHECKOUT_APP}
            domain={process.env.DOMAIN}
            sellDomain={process.env.SELLER_APP}
            sellDropDownLinks={sellDropDownLinks}
            stickyHeader
            suggestions={suggestions}
            recentProductsList={recentProductsData}
            clearRecentProducts={clearRecentProducts}
            isAlgolia={false}
            algoliaAutoCompleteIndex={algoliaAutocompleteIndex}
            algoliaQuerySuggestionsIndex={`${algoliaIndex}_query_suggestions`}
            algoliaSearchAPIKey={
              process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || ''
            }
            algoliaAppId={
              process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || ''
            }
          />
        )}
    </header>
  );
};

export default headerComponent;
