import { validateEventSchema } from '../validateSchema';
import promoSchema from '../schema/promo.json';
/**
 * @param {string} creative
 * @param {string} name
 * @param {string} position
 * @param {number} promotion_id
 */
export const promoMapper = (data) => {
  const validData = validateEventSchema(
    {
      ...data,
      promotion_id: data?.promotionId,
    },
    promoSchema,
  );
  return validData || {};
};
