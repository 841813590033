import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { HeaderFooterReducer } from '../types/Store';
import { actionTypes } from '../actions';

type HeaderFooterSuccessResponse = {
  data: HeaderFooterReducer;
  message: string;
  status: string;
};

type HeaderFooterSuccessOrErrorResponse =
  HeaderFooterSuccessResponse & AxiosError;

const options: AxiosRequestConfig = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json, text/plain, */*',
  },
  timeout: 10000,
};

function* loadFooterSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<HeaderFooterSuccessOrErrorResponse>
> {
  const url = `${process.env.NEXT_PUBLIC_CONTENT_SERVICE_URL}/footer`;
  try {
    options.url = url;
    const res = yield call(axios.get, url, options);

    if (res.data?.status === 'success') {
      yield put({
        type: actionTypes.LOAD_COLUMN_DATA_SUCCESS,
        data: res.data.data,
        url,
      });
    } else {
      yield put({
        type: actionTypes.LOAD_COLUMN_DATA_ERROR,
        error:
          res.data?.message ||
          'There was an error with the footer data',
        url,
      });
    }
  } catch (error) {
    const message =
      error instanceof Error ? error?.message : 'There was an error.';
    yield put({
      type: actionTypes.LOAD_COLUMN_DATA_ERROR,
      error: message,
    });
  }
}

function* loadHeaderRotatorSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<HeaderFooterSuccessOrErrorResponse>
> {
  const url = `${process.env.NEXT_PUBLIC_CONTENT_SERVICE_URL}/navigationRotator`;
  try {
    options.url = url;
    const res = yield call(axios.get, url, options);

    if (res.data?.status === 'success') {
      yield put({
        type: actionTypes.LOAD_ROTATOR_DATA_SUCCESS,
        data: res.data.data,
      });
    }
  } catch (error) {
    const message =
      error instanceof Error ? error?.message : 'There was an error.';
    yield put({
      type: actionTypes.LOAD_ROTATOR_DATA_ERROR,
      error: message,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(actionTypes.LOAD_COLUMN_DATA, loadFooterSaga);
  yield takeEvery(
    actionTypes.LOAD_ROTATOR_DATA,
    loadHeaderRotatorSaga,
  );
}

export default loadSagaAll;
