/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { NavigationReducer } from '../types/Store';
import { actionTypes } from '../actions/navigationActions';
import {
  reducerErrorMessage,
  reducerHelper,
} from '../utilities/helpers';

const initialState = {
  navigation: [],
  error: null,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: NavigationReducer = initialState,
  action: any,
): NavigationReducer => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.navigationReducer };
    }
    case actionTypes.LOAD_NAVIGATION_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_NAVIGATION_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      newState.navigation = action.data;
      return {
        ...newState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
