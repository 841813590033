export const socialFooterTileActionTypes = {
  LOAD_SOCIAL_FOOTER_TILE_DATA: 'LOAD_SOCIAL_FOOTER_TILE_DATA',
  LOAD_SOCIAL_FOOTER_TILE_DATA_ERROR:
    'LOAD_SOCIAL_FOOTER_TILE_DATA_ERROR',
  LOAD_SOCIAL_FOOTER_TILE_DATA_SUCCESS:
    'LOAD_SOCIAL_FOOTER_TILE_DATA_SUCCESS',
};

export function loadSocialFooterTile() {
  return {
    type: socialFooterTileActionTypes.LOAD_SOCIAL_FOOTER_TILE_DATA,
  };
}
