import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { serializeError } from 'serialize-error';
import { NoSearchResultsContentfulData } from '../types/Sagas';
import { noSearchResultsActionTypes as actionTypes } from '../actions/noSearchResultsActions';
import { getClient } from '../utilities/helpers';
import { NO_SEARCH_RESULTS_SLUG } from '../utilities/constants';

function* loadNoSearchResultsSaga(): Generator<
  CallEffect | PutEffect,
  void,
  NoSearchResultsContentfulData
> {
  try {
    const client = getClient();
    const data = yield call(client.getEntries, {
      content_type: 'shoppableCollectionTilesBlock',
      'fields.slug[in]': NO_SEARCH_RESULTS_SLUG,
      include: 2,
    });

    yield put({
      type: actionTypes.LOAD_NO_SEARCH_RESULTS_DATA_SUCCESS,
      data,
    });
  } catch (error) {
    const errorObj = serializeError(error);
    const message =
      typeof errorObj.message !== 'undefined'
        ? errorObj.message
        : 'There was an error.';
    const stack =
      typeof errorObj.stack !== 'undefined' ? errorObj.stack : '';
    yield put({
      type: actionTypes.LOAD_NO_SEARCH_RESULTS_DATA_ERROR,
      error: message,
      stack,
    });
  }
}

// loadSagaAll is a watcher saga that detects action type for loading about page data
// and will fire off the loadAboutPageSaga saga function
function* loadSagaAll(): Generator {
  yield takeEvery(
    actionTypes.LOAD_NO_SEARCH_RESULTS_DATA,
    loadNoSearchResultsSaga,
  );
}

export default loadSagaAll;
