import {
  PutEffect,
  put,
  takeEvery,
  CallEffect,
  call,
} from 'redux-saga/effects';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { CartData } from '../types/Store';
import { actionTypes } from '../actions/cartActions';
import { API_GATEWAY_HEADERS } from '../utilities/constants';
import { errorHelper, cartHeaderHelper } from '../utilities/helpers';

const CART_CALL_URL = `${process.env.API_GATEWAY}/cart`;

type SetCartActionPayload = {
  cartData: CartData;
  type: string;
};

type CartResponseDataSuccessResponse = {
  data: CartData;
};

type CartResponseDataSuccessOrErrorResponse =
  CartResponseDataSuccessResponse & AxiosError;

function* getCartApi(
  setCartActionPayload: SetCartActionPayload,
): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<CartResponseDataSuccessOrErrorResponse>
> {
  try {
    const cartData = { ...(setCartActionPayload?.cartData ?? {}) };
    const { identityId, portKey } = cartData ?? {};
    const options: AxiosRequestConfig = {
      headers: cartHeaderHelper(identityId || '', portKey || ''),
      timeout: API_GATEWAY_HEADERS.TIMEOUT,
    };
    const res = yield call(axios.get, CART_CALL_URL, options);
    const allCartData = res?.data?.data ?? {};

    yield put({
      allCartData,
      type: actionTypes.GET_CART_DATA_SUCCESS,
      status: res.status,
    });
  } catch (error) {
    const { stack, message, status, customMessage } =
      errorHelper(error);
    yield put({
      type: actionTypes.GET_CART_DATA_ERROR,
      error: message,
      stack,
      status,
      customMessage,
    });
  }
}

function* setCartApi(
  setCartActionPayload: SetCartActionPayload,
): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<CartResponseDataSuccessOrErrorResponse>
> {
  try {
    const cartData = { ...(setCartActionPayload?.cartData ?? {}) };
    const {
      productId,
      quantity = 1,
      color,
      size,
      identityId,
      portKey,
      giftAmount,
      giftSendToName,
      giftSendToEmail,
      giftSendAt,
      giftMessage,
    } = cartData ?? {};

    const params = {
      product_id: productId,
      quantity,
      color,
      size,
      gift_amount: giftAmount,
      gift_send_to_name: giftSendToName,
      gift_send_to_email: giftSendToEmail,
      gift_send_at: giftSendAt,
      gift_message: giftMessage,
    };

    const options = {
      headers: cartHeaderHelper(identityId || '', portKey || ''),
      timeout: API_GATEWAY_HEADERS.TIMEOUT,
    };

    const res = yield call(
      axios.post,
      CART_CALL_URL,
      params,
      options,
    );

    const data = res?.data?.data ?? {};
    const { user = null } = data;

    yield put({
      type: actionTypes.SET_CART_DATA_SUCCESS,
      data,
      user,
      status: res.status,
    });
  } catch (error) {
    const { stack, message, status, customMessage } =
      errorHelper(error);
    yield put({
      type: actionTypes.SET_CART_DATA_ERROR,
      error: message,
      stack,
      status,
      customMessage,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(actionTypes.SET_CART_DATA, setCartApi);
  yield takeEvery(actionTypes.GET_CART_DATA, getCartApi);
}

export default loadSagaAll;
