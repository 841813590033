import { validateEventSchema } from '../validateSchema';
import { productMapper } from './productMapper';
import productClickedSchema from '../schema/productClicked.json';

/**
 * @param {object} product
 * @returns see schema/productClicked
 */
export const productClickedMapper = (product) => {
  const validData = validateEventSchema(
    productMapper({
      ...product,
    }),
    productClickedSchema,
  );
  return validData || {};
};
