import React, { useState } from 'react';
import Link from 'next/link';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { contentfulSrcSet } from '../../utilities/helpers';

import type { ShopState } from '../../types/Store';

const SocialFooter = (): React.ReactElement => {
  const initialHoverState = { glowHeader: false };
  const [inHover, setHover] = useState({ glowHeader: false });
  const { socialFooterTileData: data, error } = useSelector(
    (state: ShopState) => state.socialFooterTileReducer,
  );

  if (!isEmpty(error)) {
    return <></>;
  }
  return (
    <Link className="socialLink" href="/social" as="/social" passHref>
      <div className="hashtagFashionphile">
        <p className={`${inHover.glowHeader && 'glowHeader'}`}>
          #FASHIONPHILE
        </p>
      </div>
      <div className="mb6-sections">
        {data &&
          data.map((ele, i) => {
            return (
              <section
                className="socialFooterContainer"
                key={`${ele}${i}`}
                onMouseEnter={(): void =>
                  setHover({
                    ...inHover,
                    [`tile${i}`]: true,
                    glowHeader: true,
                  })
                }
                onMouseLeave={(): void => setHover(initialHoverState)}
              >
                <div>
                  <img
                    src={ele?.imageUrl}
                    alt={ele?.imageAlt}
                    loading="lazy"
                    srcSet={contentfulSrcSet(ele?.imageUrl, {
                      sm: 96,
                      md: 128,
                      lg: 166,
                      xl: 214,
                      xxl: 267,
                    })}
                    sizes="100vw"
                  />
                  <p>{ele.igHandle}</p>
                </div>
              </section>
            );
          })}
      </div>
    </Link>
  );
};

export default SocialFooter;
