import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { serializeError } from 'serialize-error';
import { SocialFooterTileContentfulData } from '../types/Sagas';
import { socialFooterTileActionTypes as actionTypes } from '../actions/socialFooterTileActions';
import { getClient } from '../utilities/helpers';

function* loadSocialFooterTileSaga(): Generator<
  CallEffect | PutEffect,
  void,
  SocialFooterTileContentfulData
> {
  try {
    const client = getClient();
    const data = yield call(client.getEntries, {
      content_type: 'socialFooter',
      include: 3,
      limit: 12,
    });

    yield put({
      type: actionTypes.LOAD_SOCIAL_FOOTER_TILE_DATA_SUCCESS,
      data,
    });
  } catch (error) {
    const errorObj = serializeError(error);
    const message =
      typeof errorObj.message !== 'undefined'
        ? errorObj.message
        : 'There was an error.';
    const stack =
      typeof errorObj.stack !== 'undefined' ? errorObj.stack : '';

    yield put({
      type: actionTypes.LOAD_SOCIAL_FOOTER_TILE_DATA_ERROR,
      error: message,
      stack,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(
    actionTypes.LOAD_SOCIAL_FOOTER_TILE_DATA,
    loadSocialFooterTileSaga,
  );
}

export default loadSagaAll;
