/* eslint-disable quote-props */
import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { serializeError } from 'serialize-error';
import { MetadataFromContentful } from '../types/Sagas';
import { actionTypes } from '../actions/metaDataActions';
import { getClient } from '../utilities/helpers';

function* metadataSaga(): Generator<
  CallEffect | PutEffect,
  void,
  MetadataFromContentful
> {
  try {
    const client = getClient();
    const data = yield call(client.getEntries, {
      content_type: 'metaInformation',
    });

    yield put({
      type: actionTypes.METADATA_LOAD_SUCCESS,
      data,
    });
  } catch (error) {
    const errorObj = serializeError(error);
    const message =
      typeof errorObj.message !== 'undefined'
        ? errorObj.message
        : 'There was an error.';
    const stack =
      typeof errorObj.stack !== 'undefined' ? errorObj.stack : '';
    yield put({
      type: actionTypes.METADATA_LOAD_ERROR,
      error: message,
      stack,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(actionTypes.METADATA_LOAD, metadataSaga);
}

export default loadSagaAll;
