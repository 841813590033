/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { get } from 'lodash';
import { HeaderFooterReducer } from '../types/Store';
import { actionTypes } from '../actions';
import {
  reducerErrorMessage,
  reducerHelper,
} from '../utilities/helpers';
import {
  desktopLinks,
  myAccountData,
  sellDropDownLinks,
} from '../utilities/myAccountData';

const initialState: HeaderFooterReducer = {
  rotatorData: [],
  footerData: [],
  isMobile: true,
  isOldMobile: false,
  isTablet: false,
  width: null,
  showLoader: false,
  desktopLinks,
  myAccountData,
  sellDropDownLinks,
  isMobileApp: false,
  mobileAppVersion: '',
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: HeaderFooterReducer = initialState,
  action: any,
): HeaderFooterReducer => {
  switch (action.type) {
    case HYDRATE: {
      // Note the HYDRATE action is called at the moment of static generation or server side rendering.
      // Our reducer must merge incoming state with existing client state properly as to not erase
      // existing cart data on page changes.

      // Laravel will set a JWT on the client as a cookie
      // If this value exists, we know the user has logged in

      const newState = {
        ...state,
        ...action.payload.headerFooterReducer,
      };

      newState.isTablet = state.isTablet;
      newState.isMobile = state.isMobile;
      newState.isOldMobile = state.isOldMobile;
      newState.showLoader = state.showLoader;
      newState.width = state.width;
      newState.isMobileApp = state.isMobileApp;
      newState.mobileAppVersion = state.mobileAppVersion;
      return newState;
    }
    case actionTypes.LOAD_COLUMN_DATA_ERROR:
    case actionTypes.LOAD_ROTATOR_DATA_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_COLUMN_DATA_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      newState.footerData = action.data;
      return {
        ...newState,
      };
    }
    case actionTypes.LOAD_ROTATOR_DATA_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      const rotatorData = get(action, 'data', []);
      return {
        ...newState,
        rotatorData,
      };
    }
    case actionTypes.IS_MOBILE: {
      return {
        ...state,
        ...{
          isMobile: action.isMobile,
          isTablet: action.isTablet,
          width: action.width,
          isOldMobile: action.isOldMobile,
          isMobileApp: action.isMobileApp,
          mobileAppVersion: action.mobileAppVersion,
        },
      };
    }
    case actionTypes.SET_LOADER: {
      return {
        ...state,
        showLoader: action.showLoader,
      };
    }
    default:
      return state;
  }
};

export default reducer;
