import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { InfluencerPageReducer } from '../types/Store';
import { influencerPageActionTypes as actionTypes } from '../actions/influencerPageActions';
import { getClient } from '../utilities/helpers';
import { INFLUENCER_SLUG } from '../utilities/constants';

type InfluencerData = {
  data: InfluencerPageReducer;
};

function* loadInfluencerPageSaga(): Generator<
  CallEffect | PutEffect,
  void,
  InfluencerData
> {
  try {
    const client = getClient();
    const data = yield call(client.getEntries, {
      content_type: 'post',
      limit: 1,
      'fields.slug[in]': INFLUENCER_SLUG,
    });

    yield put({
      type: actionTypes.LOAD_INFLUENCER_PAGE_DATA_SUCCESS,
      data,
    });
  } catch (error) {
    const message =
      error instanceof Error ? error?.message : 'There was an error.';
    yield put({
      type: actionTypes.LOAD_INFLUENCER_PAGE_DATA_ERROR,
      error: message,
    });
  }
}

// loadSagaAll is a watcher saga that detects action type for loading influencer page data
// and will fire off the loadinfluencerPageSaga saga function
function* loadSagaAll(): Generator {
  yield takeEvery(
    actionTypes.LOAD_INFLUENCER_PAGE_DATA,
    loadInfluencerPageSaga,
  );
}

export default loadSagaAll;
