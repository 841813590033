/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { AppMetaReducer } from '../types/Store';
import { appMetaActionTypes as actionTypes } from '../actions/appMetaActions';

const initialState = {
  initiatedListeners: false,
};

function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AppMetaReducer = initialState,
  action: any,
): AppMetaReducer {
  switch (action.type) {
    case HYDRATE: {
      return state;
    }
    case actionTypes.SET_LISTENING: {
      return {
        ...state,
        initiatedListeners: action.isListening,
      };
    }
    default:
      return state;
  }
}

export default reducer;
