import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { Loading as Loader } from '@fashionphile/component-library';
import { Cookies } from 'react-cookie';
import { setLoader } from '../../actions';
import { fpEvent } from '../../utilities/fpEvents';

import type { ShopState } from '../../types/Store';

const cookies = new Cookies();

export default function Loading(): React.ReactElement {
  const router = useRouter();
  const dispatch = useDispatch();
  const showLoader = useSelector(
    (state: ShopState) => state.headerFooterReducer.showLoader,
  );

  useEffect(() => {
    // Set redirect_url
    if (typeof window !== 'undefined') {
      const parts = window.location.hostname.split('.');
      parts.shift();
      const domain = parts.join('.');
      cookies.set('redirect_url', window.location.href, {
        domain,
        path: '/',
      });
    }
    const handleStart = (url: string): boolean => {
      if (
        router.pathname === '/shop/[[...listingPageSlug]]' &&
        url.indexOf('/shop') === 0
      ) {
        // From PLP to PLP
        // Do not enable loader
        return true;
      }
      if (url !== router.asPath) {
        fpEvent('fpLoadingStart');
        dispatch(setLoader(true));
      }
      return true;
    };

    const handleComplete = (): void => {
      // Record current page. Used for redirects after login/logout
      if (typeof window !== 'undefined') {
        const parts = window.location.hostname.split('.');
        parts.shift();
        const domain = parts.join('.');
        cookies.set('redirect_url', window.location.href, {
          domain,
          path: '/',
        });
      }
      fpEvent('fpLoadingFinish');
      dispatch(setLoader(false));
    };

    const handleError = (): void => {
      fpEvent('fpLoadingFinish');
      dispatch(setLoader(false));
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleError);

    return (): void => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleError);
    };
  }, []);

  if (!showLoader) {
    return <></>;
  }

  return <Loader show />;
}
