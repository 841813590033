/* eslint-disable no-param-reassign */
import Head from 'next/head';
import Script from 'next/script';
import localFont from 'next/font/local';
import React from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { Cookies, useCookies } from 'react-cookie';
import { QueryClientProvider } from '@tanstack/react-query';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
  createInstance,
  OptimizelyProvider,
  enums,
} from '@optimizely/react-sdk';
import optimizely from '@optimizely/optimizely-sdk';
import { v4 as uuidV4 } from 'uuid';
import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
import { onDecision } from '../utilities/optimizely/optimizelyFeatureExperimentationSegmentIntegration';
import {
  loadSegment,
  resetSegment,
} from '../utilities/segment/segment';

import { wrapper } from '../store';
import Frame from '../components/frame';
import ErrorBoundaryComponent from '../components/errorBoundary';
import Loading from '../components/pageLoading';
import LiveChatEmbedded from '../components/liveChat';
import {
  DEFAULT_COOKIE_OPTIONS,
  REFERRER_COOKIE_OPTIONS,
  queryClient,
} from '../utilities/helpers';
import { COOKIES as COOKIE_NAMES } from '../utilities/constants';
import { getAllCartData } from '../actions/cartActions';
import { UserContext } from '../contexts/UserContext';

import '../styles/main.scss';
import { ShopState } from '../types/Store';

const myFont = localFont({
  src: [
    {
      style: 'italic',
      weight: '200',
      path: '../styles/fonts/metropolis-all-200-italic.woff2',
    },
    {
      style: 'normal',
      weight: '200',
      path: '../styles/fonts/metropolis-all-200-normal.woff2',
    },
    {
      style: 'italic',
      weight: '400',
      path: '../styles/fonts/metropolis-all-400-italic.woff2',
    },
    {
      style: 'normal',
      weight: '400',
      path: '../styles/fonts/metropolis-all-400-normal.woff2',
    },
    {
      style: 'italic',
      weight: '500',
      path: '../styles/fonts/metropolis-all-500-italic.woff2',
    },
    {
      style: 'normal',
      weight: '500',
      path: '../styles/fonts/metropolis-all-500-normal.woff2',
    },
    {
      style: 'italic',
      weight: '600',
      path: '../styles/fonts/metropolis-all-600-italic.woff2',
    },
    {
      style: 'normal',
      weight: '600',
      path: '../styles/fonts/metropolis-all-600-normal.woff2',
    },
  ],
});

// Default axios user-agent globally for this app
axios.defaults.headers.common['x-fp-user-agent'] =
  process.env.NEXT_PUBLIC_USER_AGENT ||
  'Fashionphile-SD-NextJS/1.0.0';

// Pass users GA to backend using custom header
axios.defaults.headers.common['x-ga'] =
  (typeof window !== 'undefined' &&
    `; ${document.cookie}`
      .split('; _ga=')
      .pop()
      ?.split(';')
      .shift()) ||
  '';

// Used to for feature flagging features
if (typeof window !== 'undefined') {
  const cookiesSegment = new Cookies();
  let identityId = cookiesSegment.get(COOKIE_NAMES.IDENTITY_ID);

  if (!identityId) {
    identityId = uuidV4();
    cookiesSegment.set(
      COOKIE_NAMES.IDENTITY_ID,
      identityId,
      DEFAULT_COOKIE_OPTIONS,
    );
    window.localStorage.setItem(
      'ajs_anonymous_id',
      JSON.stringify(identityId),
    );
    cookiesSegment.set(COOKIE_NAMES.SEGMENT_ID, identityId, {
      ...DEFAULT_COOKIE_OPTIONS,
      sameSite: 'lax',
    });
  }

  let segmentId;
  try {
    segmentId = window.localStorage?.[COOKIE_NAMES.SEGMENT_ID]
      ? JSON.parse(window.localStorage?.[COOKIE_NAMES.SEGMENT_ID])
      : undefined;
  } catch (_e) {
    segmentId = undefined;
  }

  if (segmentId !== identityId) {
    resetSegment(identityId);
  }
}

// Instagram and Facebook error that Meta isn't fixing but is not impacting users
// See https://developers.facebook.com/community/threads/320013549791141/
if (typeof window !== 'undefined') {
  // eslint-disable-next-line no-underscore-dangle
  (window as any)._AutofillCallbackHandler =
    // eslint-disable-next-line no-underscore-dangle
    (window as any)._AutofillCallbackHandler ||
    function emptyFunction(): void {};
}

const optimizelyReactInstance = createInstance({
  sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
});
if (typeof window !== 'undefined') {
  (window as any).optimizelyReactInstance = optimizelyReactInstance;
  (window as any).optimizelyClientInstance =
    optimizely.createInstance({
      sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
    });

  // Add optimizely event listener
  const notificationId =
    optimizelyReactInstance.notificationCenter.addNotificationListener(
      enums.NOTIFICATION_TYPES.DECISION,
      onDecision,
    );
  if (process.env.ENVIRONMENT !== 'production') {
    console.log('notification Listener setup id', notificationId);
  }
}

const sessionReplayRate = parseInt(
  process.env.NEXT_PUBLIC_DATADOG_REPLAY_SAMPLE_RATE ?? '',
  10,
);
datadogRum.init({
  version: `shop-app@${process.env.NEXT_PUBLIC_SHOPAPP_VERSION}`,
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ?? '',
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? '',
  site: process.env.NEXT_PUBLIC_DATADOG_SITE,
  service: 'shop-app',
  sessionSampleRate: parseInt(
    process.env.NEXT_PUBLIC_DATADOG_SAMPLE_RATE || '',
    10,
  ),
  sessionReplaySampleRate: sessionReplayRate,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

// Stripe.js will not be loaded until `loadStripe` is called
const stripePromise = process.env.NEXT_PUBLIC_STRIPE_KEY
  ? loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY)
  : null;

function App({
  Component,
  pageProps,
}: {
  Component: any;
  pageProps: any;
}): React.ReactElement {
  const [cookies, setCookie, removeCookie] = useCookies([
    COOKIE_NAMES.IDENTITY_ID,
    COOKIE_NAMES.PORT_KEY,
  ]);
  const user = useSelector(
    (state: ShopState) => state.cartReducer.user,
  );
  const userCheck = useSelector(
    (state: ShopState) => state.cartReducer.userCheck,
  );

  const isMobileApp = useSelector(
    (state: ShopState) => state.headerFooterReducer.isMobileApp,
  );

  const dispatch = useDispatch();
  const router = useRouter();

  // initial cart setup, run once
  React.useEffect(() => {
    const identityId = cookies?.[COOKIE_NAMES.IDENTITY_ID];
    const portKey = cookies?.[COOKIE_NAMES.PORT_KEY] ?? null;

    if (user === null) {
      dispatch(
        getAllCartData({
          identityId,
          portKey,
        }),
      );
    }
  }, []);

  React.useEffect(() => {
    if (router.query?.referrer) {
      if (cookies.referrer) {
        removeCookie(cookies.referrer);
      }

      setCookie(
        COOKIE_NAMES.REFERRER,
        router.query.referrer,
        REFERRER_COOKIE_OPTIONS,
      );
    }
  }, [router.query.referrer]);

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/icons/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/icons/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/icons/apple-touch-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/icons/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/icons/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/icons/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/icons/apple-touch-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icons/apple-touch-icon-180x180.png"
        />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=2"
        />
        {process.env.ENVIRONMENT !== 'production' && (
          <script
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
            (function(u,t,d){
          var i=d.createElement(t);i.type='text/javascript';i.async=true;i.src='//'+u;
          var s=d.getElementsByTagName(t)[0]; s.parentNode.insertBefore(i,s);
      })('us-sandbox-track.inside-graph.com/gtm/IN-1001179/include.js','script',document);
      `,
            }}
          />
        )}
      </Head>
      <Script
        strategy="beforeInteractive"
        src={process.env.NEXT_PUBLIC_OPTIMIZELY_URL}
      />
      {/*
          The userCheck variable comes from the cart reducer.
          It’s always initialized as false and turns true
          after the initial get card call. It’s needed here for
          we wait to know if we have an user that requested ccpa
          do not sell, so we can load segment with the correct 
          privacy settings.
      */}
      {userCheck && !isMobileApp && (
        <Script
          dangerouslySetInnerHTML={{
            __html: loadSegment(user?.dns || false),
          }}
          id="segment-script"
          onError={() => {
            console.error('Segment script failed to load.');
          }}
        />
      )}
      <Script
        src={process.env.NEXT_PUBLIC_STRIPE_SCRIPT_URL}
        async
        onLoad={() => {
          console.log('Stripe script loaded');
        }}
        onError={() => {
          console.error('Stripe script failed to load.');
        }}
      />
      <Elements stripe={stripePromise}>
        <OptimizelyProvider
          optimizely={optimizelyReactInstance}
          user={{ id: cookies?.[COOKIE_NAMES.IDENTITY_ID] }}
          timeout={5}
        >
          <QueryClientProvider client={queryClient}>
            <UserContext>
              <main className={myFont.className}>
                <Frame>
                  <ErrorBoundaryComponent>
                    <Component {...pageProps} />
                  </ErrorBoundaryComponent>
                </Frame>
              </main>
            </UserContext>
          </QueryClientProvider>
          <Loading />
        </OptimizelyProvider>
      </Elements>
      <LiveChatEmbedded />
    </>
  );
}

export default wrapper.withRedux(App);
