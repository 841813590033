export const newsletterActionTypes = {
  ADD_NEWSLETTER: 'ADD_NEWSLETTER',
  ADD_NEWSLETTER_SUCCESS: 'ADD_NEWSLETTER_SUCCESS',
  ADD_NEWSLETTER_ERROR: 'ADD_NEWSLETTER_ERROR',
  LOADING_NEWSLETTER: 'LOADING_NEWSLETTER',
};

export function newsletter(email) {
  return {
    type: newsletterActionTypes.ADD_NEWSLETTER,
    email,
  };
}
