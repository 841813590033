import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { LoadPartnersContentfulData } from '../types/Sagas';
import { partnersProgramActionTypes as actionTypes } from '../actions/partnersProgramActions';
import { getClient } from '../utilities/helpers';
import { PARTNERS_SLUG } from '../utilities/constants';

function* loadPartnersProgramSaga(): Generator<
  CallEffect | PutEffect,
  void,
  LoadPartnersContentfulData
> {
  try {
    const client = getClient();
    const data = yield call(client.getEntries, {
      content_type: 'post',
      limit: 1,
      'fields.slug[in]': PARTNERS_SLUG,
    });
    yield put({
      type: actionTypes.LOAD_PARTNERS_PROGRAM_DATA_SUCCESS,
      data,
    });
  } catch (error) {
    const message =
      error instanceof Error ? error?.message : 'There was an error.';
    yield put({
      type: actionTypes.LOAD_PARTNERS_PROGRAM_DATA_ERROR,
      error: message,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(
    actionTypes.LOAD_PARTNERS_PROGRAM_DATA,
    loadPartnersProgramSaga,
  );
}

export default loadSagaAll;
