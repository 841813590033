import axios from 'axios';

const webhookURL: string | undefined = process.env.SLACK_WEBHOOK_URL;

export const sendSlackMessage = async (
  message: any,
  slackURL = webhookURL,
): Promise<void> => {
  if (!slackURL) {
    return;
  }
  try {
    await axios.post(slackURL, {
      username: 'SDShopApp',
      icon_emoji: ':boom:',
      text: `*${process.env.ENVIRONMENT} » ${message}*`,
    });
  } catch (_e) {
    throw new Error('Failed to post request to slack');
  }
};
