import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import events, { EVENTS } from '../utilities/segment/events';
import { COOKIES as COOKIE_NAMES } from '../utilities/constants';
import { mapSegmentUser } from '../utilities/segment/helpers';
import type { ShopState } from '../types/Store';
import { useAddFavoriteMutationAsync } from '../mutations/useFavoritesAPI';

export const UserContext = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const [cookies, , removeCookie] = useCookies([
    COOKIE_NAMES.FAVORITE_PRODUCTS,
  ]);

  const user = useSelector(
    (state: ShopState) => state.cartReducer.user,
  );

  const addFavoriteMutationAsync = useAddFavoriteMutationAsync();

  useEffect(() => {
    if (user?.id) {
      events.emit(EVENTS.identify, mapSegmentUser(user));

      const mergeIfFavoriteProductsCookieExist =
        async (): Promise<void> => {
          // check cookie for favorites, run once
          if (cookies?.[COOKIE_NAMES.FAVORITE_PRODUCTS]) {
            const favoriteProductsCookie = cookies[
              COOKIE_NAMES.FAVORITE_PRODUCTS
            ]
              .toString()
              .split('-');
            const favoriteWithMutation = favoriteProductsCookie.map(
              (productId: number) =>
                addFavoriteMutationAsync.mutateAsync({
                  productId,
                }),
            );

            await Promise.allSettled(favoriteWithMutation);
          }
        };

      mergeIfFavoriteProductsCookieExist();

      removeCookie(COOKIE_NAMES.FAVORITE_PRODUCTS, {
        path: '/',
      });
    }
  }, [user]);

  return children;
};
