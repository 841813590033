export const homepagePromoActionTypes = {
  LOAD_HERO_DATA: 'LOAD_HERO_DATA',
  LOAD_CONTENT_TILES_DATA: 'LOAD_CONTENT_TILES_DATA',
  LOAD_HOME_PAGE_DATA: 'LOAD_HOME_PAGE_DATA',
  LOAD_HOME_PAGE_DATA_SUCCESS: 'LOAD_HOME_PAGE_DATA_SUCCESS',
  LOAD_HOME_PAGE_DATA_ERROR: 'LOAD_HOME_PAGE_DATA_ERROR',
  LOAD_CAROUSEL_DATA: 'LOAD_CAROUSEL_DATA',
  LOAD_CAROUSEL_DATA_SUCCESS: 'LOAD_CAROUSEL_DATA_SUCCESS',
  LOAD_CAROUSEL_DATA_ERROR: 'LOAD_CAROUSEL_DATA_ERROR',
  LOAD_HOME_PAGE_DLP_DATA: 'LOAD_HOME_PAGE_DLP_DATA',
  LOAD_HOME_PAGE_DLP_DATA_SUCCESS: 'LOAD_HOME_PAGE_DLP_DATA_SUCCESS',
  LOAD_HOME_PAGE_DLP_DATA_ERROR: 'LOAD_HOME_PAGE_DLP_DATA_ERROR',
};

export function loadHomePageData() {
  return { type: homepagePromoActionTypes.LOAD_HOME_PAGE_DATA };
}

export function loadCarouselData() {
  return { type: homepagePromoActionTypes.LOAD_CAROUSEL_DATA };
}

export function loadHomePageDLPData() {
  return { type: homepagePromoActionTypes.LOAD_HOME_PAGE_DLP_DATA };
}
