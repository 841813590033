/**
 * dynamicLandingPageSaga.js
 * Fetches data from contentful, which will interact with Redux to store data
 */
import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { DynamicLandingPageReducer } from '../types/Store';
import { dynamicLandingPageActionTypes as actionTypes } from '../actions/dynamicLandingPageActions';
import { productApiHelper } from '../utilities/helpers';
import { sendSlackMessage } from '../utilities/slackAlert';
import { HttpMethod } from '../types/Helpers';

const API_FAILURE_MESSAGE = 'Failed to populate DLP Data';

type ParamsData = {
  params: {
    slug: string;
  };
  type: string;
};

type DynamicLandingPageData = {
  data: DynamicLandingPageReducer;
  json: () => Promise<Response>;
  status?: string;
};

function* loadDynamicPageSaga({
  params,
}: ParamsData): Generator<
  CallEffect | PutEffect,
  void,
  DynamicLandingPageData
> {
  const domain = productApiHelper();
  const url = `${domain}/content/dlp/${params?.slug}`;
  try {
    const options = {
      headers: {
        Accept: 'application/json, text/plain, */*',
      },
      method: HttpMethod.GET,
      url,
      timeout: 15000,
    };
    const res = yield call(fetch, url, options);
    const resData = yield call([res, 'json']);

    if (resData?.status !== 'success') {
      throw new Error(API_FAILURE_MESSAGE);
    }
    const { data } = resData;
    yield put({
      type: actionTypes.LOAD_DYNAMIC_LANDING_DATA_SUCCESS,
      data,
    });
  } catch (error) {
    const message =
      error instanceof Error ? error?.message : 'There was an error.';
    yield put({
      type: actionTypes.LOAD_DYNAMIC_LANDING_DATA_ERROR,
      error: message,
    });
    // Only trigger slack alerts on production
    if (['production'].includes(process.env.NODE_ENV)) {
      yield call(sendSlackMessage, `${API_FAILURE_MESSAGE}: ${url}`);
    }
    throw new Error(API_FAILURE_MESSAGE);
  }
}

// loadSagaAll is a watcher saga that detects action type for loading dynamicLandingPage data
// and will fire off the loadDynamicPageSaga saga function
function* loadSagaAll(): Generator {
  yield takeEvery(
    actionTypes.LOAD_DYNAMIC_LANDING_PAGE_DATA,
    loadDynamicPageSaga,
  );
}

export default loadSagaAll;
