export const influencerPageActionTypes = {
  LOAD_INFLUENCER_PAGE_DATA: 'LOAD_INFLUENCER_PAGE_DATA',
  LOAD_INFLUENCER_PAGE_DATA_ERROR: 'LOAD_INFLUENCER_PAGE_DATA_ERROR',
  LOAD_INFLUENCER_PAGE_DATA_SUCCESS:
    'LOAD_INFLUENCER_PAGE_DATA_SUCCESS',
};

export function loadInfluencerPage() {
  return {
    type: influencerPageActionTypes.LOAD_INFLUENCER_PAGE_DATA,
  };
}
