/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { cloneDeep, get } from 'lodash';
import { socialFooterTileActionTypes as actionTypes } from '../actions/socialFooterTileActions';
import {
  reducerErrorMessage,
  reducerHelper,
  massageSocialData,
} from '../utilities/helpers';
import { SocialFooterTileReducer } from '../types/Store';

const initialState = {
  socialFooterTileData: null,
  socialPageData: null,
  error: null,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: SocialFooterTileReducer = initialState,
  action: any,
): SocialFooterTileReducer => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.socialFooterTileReducer };
    }
    case actionTypes.LOAD_SOCIAL_FOOTER_TILE_DATA_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_SOCIAL_FOOTER_TILE_DATA_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      const data = cloneDeep(action.data);
      const socialFooterTileData = get(data, 'items.0.fields', {});
      return {
        ...newState,
        socialFooterTileData: socialFooterTileData
          ? massageSocialData(socialFooterTileData, 'footer')
          : socialFooterTileData,
        socialPageData: socialFooterTileData
          ? massageSocialData(socialFooterTileData, 'page')
          : socialFooterTileData,
      };
    }
    default:
      return state;
  }
};

export default reducer;
