import {
  PERCENTAGE_MULTIPLIER,
  PRODUCT_TYPES,
} from '../../constants';
import { undefToNull } from '../../helpers';
import {
  brandMapper,
  brandsReducer,
  getCategory,
  getColor,
  getCondition,
  getDaysOnSale,
  materialMapper,
} from './productMapperHelper';

/** NB(s)
 * See misc notes in-line
 * 
 * product.dos
 - is a decimal-as-integer (e.g. 0.20 -> 20) representation of discount percent
 - is NOT "days on sale"
*/

export const productMapper = (product: any): any => {
  const MAX_DISCOUNT_PERCENTAGE = 30;
  const isGiftCard =
    product?.isGiftCard ||
    product?.productType === PRODUCT_TYPES.GIFT_CARD;
  const isSoldOut = product?.productType === PRODUCT_TYPES.SOLD_OUT;
  const url = /https?/.test(product?.slug)
    ? product.slug
    : process.env.DOMAIN + (product?.slug || '');
  const value = isGiftCard ? 0 : +product?.value || +product?.price;
  const discountTier =
    product?.price > 0
      ? PERCENTAGE_MULTIPLIER -
        Math.round(
          ((product?.discountedPrice || product?.discounted_price) /
            product?.price) *
            PERCENTAGE_MULTIPLIER,
        )
      : 0;

  return undefToNull({
    additional_tags: product?.additionalTags,
    brand: brandMapper(product?.brand || product?.brandName),
    brands: brandsReducer(product?.brands),
    categories: null,
    category: getCategory(product?.categories, isGiftCard),
    color: getColor(product, isGiftCard),
    condition: getCondition(product, isGiftCard),
    currency: product?.currency || 'USD',
    days_on_sale: getDaysOnSale(product, isGiftCard),
    discount_tier: discountTier,
    is_sold_out: Number(isSoldOut),
    list_category: product?.listType,
    list_id: product?.listValue,
    last_call: discountTier > MAX_DISCOUNT_PERCENTAGE ? 1 : 0,
    material:
      product?.material || materialMapper(product?.categories),
    name: (product?.name || product?.title)?.trim(),
    position: product?.position,
    placement: product?.placement,
    placement_value: product?.placementValue,
    price: isGiftCard
      ? 0
      : +(product?.discountedPrice || product?.discounted_price),
    product_age: product?.productAge,
    product_discount: discountTier,
    product_discount_amount:
      product?.price -
      (product?.discountedPrice || product?.discounted_price),
    product_favorited:
      Number(product?.favorite || product?.favorited) || 0,
    product_id: product?.id || product?.productId,
    product_recommended: 0, // "there are no on-site recommendations right now" - Oct'22
    product_subcategory: null, // null due to inconsistencies
    quantity: product?.quantity ?? 1,
    sku: null, // reserved
    style: product?.style,
    url,
    value,
    ...(product?.search_index
      ? {
          search_index: product?.search_index,
          query_id: product?.query_id || null,
        }
      : {}),
  });
};
