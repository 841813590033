import { validateEventSchema } from '../validateSchema';
import filterSchema from '../schema/filter.json';

/**
 * @param {{
 *  filters: Array<{
 *   type: string;
 *   value: string;
 *  }>
 *  listType: string;
 *  listValue: string;
 *  sorts: Array<{
 *   type: string;
 *  value: string;
 *  }>
 * }} filter
 * @returns see schema/filter
 */
export const filterMapper = (filter) => {
  const validData = validateEventSchema(
    {
      filters: filter?.filters,
      list_id: filter?.listType,
      category: filter?.listValue,
      sorts: filter?.sorts,
    },
    filterSchema,
  );
  return validData || {};
};
