/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { Cookies } from 'react-cookie';
import { cloneDeep } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import type { CartItem, CartReducer } from '../types/Store';
import { actionTypes } from '../actions/cartActions';
import {
  reducerErrorMessage,
  DEFAULT_COOKIE_OPTIONS,
} from '../utilities/helpers';
import { COOKIES } from '../utilities/constants';

const initialState: CartReducer = {
  cartData: {
    userCredits: null,
    cartCount: null,
    cartItems: null,
    id: null,
    isSignedIn: null,
    isOnVpn: null,
    isDeleted: null,
    orderSummary: null,
    user: null,
  },
  showSpinner: false,
  productsInCart: 0,
  user: null,
  userCheck: false, // check to see if user call has been made
  loggedIn: false,
  error: null,
};

const cookies = new Cookies();
const deleteCookieData = (): void => {
  cookies.remove(COOKIES.PORT_KEY, { path: '/' });
  cookies.set(COOKIES.IDENTITY_ID, uuidV4(), DEFAULT_COOKIE_OPTIONS);
};
const createCookieData = (
  cartData: any,
  productsInCart: CartItem[],
): any => {
  const date = new Date();
  const expiredDate = new Date(date.setDate(date.getDate() + 1));
  const truncatedCartData: CartItem[] = [];
  const updatedCartData = cloneDeep(cartData);
  updatedCartData.cartItems = updatedCartData.cartItems.map(
    (item: CartItem) => {
      const newItem = {
        ...item,
        sku: `${item.sku}${item.productId}`,
      };
      truncatedCartData.push({
        productId: newItem.productId,
        discountedPrice: newItem.discountedPrice,
        price: newItem.discountedPrice,
        quantity: newItem.quantity,
        sku: newItem.sku,
      });
      return newItem;
    },
  );
  const cookieData = {
    cartItems: truncatedCartData,
    productsInCart,
    total:
      updatedCartData?.orderSummary?.subtotalTaxableDiscountedPrice ??
      0,
    revalidate: false,
  };
  cookies.set('cartData', JSON.stringify(cookieData), {
    expires: expiredDate,
    path: '/',
  });
  return updatedCartData;
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: CartReducer = initialState,
  action: any,
): CartReducer => {
  switch (action.type) {
    case HYDRATE: {
      // Note the HYDRATE action is called at the moment of static generation or server side rendering.
      // Our reducer must merge incoming state with existing client state properly as to not erase
      // existing cart data on page changes.

      // Laravel will set a JWT on the client as a cookie
      // If this value exists, we know the user has logged in
      const loggedIn = cookies.get(COOKIES.PORT_KEY);

      const newState = {
        ...state,
        ...action.payload.cartReducer,
        cartData:
          state.cartData ?? action.payload.cartReducer.cartData,
        productsInCart:
          state.productsInCart ??
          action.payload.cartReducer.productsInCart,
        userCheck:
          state.userCheck ?? action.payload.cartReducer.userCheck,
        loggedIn: !!loggedIn,
      };

      newState.user = state.user;
      return newState;
    }
    case actionTypes.GET_CART_DATA_ERROR:
    case actionTypes.SET_CART_DATA_ERROR: {
      const initStatue = {
        userCheck: true,
      };
      const newState = reducerErrorMessage(
        state,
        action,
        initStatue,
        'userCheck',
      ) as CartReducer;
      if (newState.error?.status === '401') {
        deleteCookieData();
      }
      return newState;
    }
    case actionTypes.GET_CART_DATA_SUCCESS: {
      const { allCartData } = action;
      const productsInCart =
        allCartData?.orderSummary?.numberOfProducts ??
        allCartData?.cartItems?.length ??
        state.productsInCart ??
        0;
      const updatedCartData = createCookieData(
        allCartData,
        productsInCart,
      );
      const loggedIn = cookies.get(COOKIES.PORT_KEY);
      const user = allCartData?.user ?? null;

      return {
        ...state,
        cartData: {
          ...updatedCartData,
        },
        user,
        productsInCart,
        loggedIn: !!loggedIn,
        userCheck: true,
        error: null,
      };
    }
    case actionTypes.SET_CART_DATA_SUCCESS: {
      const cart = action?.data ?? state.cartData;
      Object.assign(state.cartData, cart);
      const productsInCart =
        cart?.orderSummary?.numberOfProducts ??
        cart?.cartItems?.length ??
        state.productsInCart ??
        0;
      const updatedCartData = createCookieData(cart, productsInCart);

      return {
        ...state,
        cartData: {
          ...updatedCartData,
        },
        error: null,
        productsInCart,
      };
    }
    default:
      return state;
  }
};

export default reducer;
