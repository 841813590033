import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { serializeError } from 'serialize-error';
import { HomepagePromoReducer } from '../types/Store';
import { homepagePromoActionTypes as actionTypes } from '../actions/homepagePromoActions';
import { productApiHelper, getCanonical } from '../utilities/helpers';

type HomepagePromoSuccessResponse = {
  data: HomepagePromoReducer;
  message: string;
  status: string;
};

type HomepagePromoSuccessOrErrorResponse =
  HomepagePromoSuccessResponse & AxiosError;

const options: AxiosRequestConfig = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json, text/plain, */*',
  },
  timeout: 10000,
};

function* loadHomePageSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<HomepagePromoSuccessOrErrorResponse>
> {
  const url = `${process.env.NEXT_PUBLIC_CONTENT_SERVICE_URL}/homepageLayout`;
  try {
    options.url = url;
    const res = yield call(axios.get, url, options);

    if (res.data?.status === 'success') {
      const homepageData = res.data?.data?.homepageData || null;
      const { heroData } = res.data?.data || null;
      const contentTilesData = res.data.data.contentTilesData || [];
      yield put({
        type: actionTypes.LOAD_HOME_PAGE_DATA_SUCCESS,
        homepageData,
      });
      yield put({
        type: actionTypes.LOAD_HERO_DATA,
        heroData,
      });
      yield put({
        type: actionTypes.LOAD_CONTENT_TILES_DATA,
        contentTilesData,
      });
    }
  } catch (error) {
    const message =
      error instanceof Error ? error?.message : 'There was an error.';
    yield put({
      type: actionTypes.LOAD_HOME_PAGE_DATA_ERROR,
      error: message,
    });
  }
}

function* loadCarouselSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<HomepagePromoSuccessOrErrorResponse>
> {
  const url = `${process.env.NEXT_PUBLIC_CONTENT_SERVICE_URL}/homeCarousel`;
  try {
    options.url = url;
    const res = yield call(axios.get, url, options);

    if (res.data?.status === 'success') {
      yield put({
        type: actionTypes.LOAD_CAROUSEL_DATA_SUCCESS,
        carouselData: res.data.data,
        url,
      });
    } else {
      yield put({
        type: actionTypes.LOAD_CAROUSEL_DATA_ERROR,
        error:
          res.data?.message ||
          'There was an error with the navigation data',
        url,
      });
    }
  } catch (error) {
    const errorObj = serializeError(error);
    const message =
      typeof errorObj.message !== 'undefined'
        ? errorObj.message
        : 'There was an error.';
    const stack =
      typeof errorObj.stack !== 'undefined' ? errorObj.stack : '';
    yield put({
      type: actionTypes.LOAD_CAROUSEL_DATA_ERROR,
      error: message,
      stack,
      url,
    });
  }
}

function* loadHomePageDLPSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<HomepagePromoSuccessOrErrorResponse>
> {
  try {
    const domain = productApiHelper();
    const url = `${domain}/content/dlp/homepage`;
    options.url = url;
    options.timeout = 15000;
    const res = yield call(axios.get, url, options);

    const { data } = res?.data || {};

    const allFields = data?.fields;
    const metaData = {
      title: allFields?.metaTitle || '',
      description: allFields?.metaDescription || '',
      keywords: allFields?.metaKeywords,
      ogTitle: allFields?.ogTitle || '',
      ogImage: allFields?.ogImage?.fields?.file?.url || '',
      ogImageWidth:
        allFields?.ogImage?.fields?.file?.details?.image?.width || 0,
      ogImageHeight:
        allFields?.ogImage?.fields?.file?.details?.image?.height || 0,
      ogDescription: allFields?.ogDescription || '',
      canonical: getCanonical(''),
    };

    const contentBlocks = data.fields?.contentBlocks || [];
    const contentBlockIdArr: string[] = contentBlocks.map(
      (cblock) => {
        return cblock.sys.contentType.sys.id;
      },
    );
    const homepageDLPData = {
      contentBlocks,
      contentBlockIdArr,
      metaData,
    };
    yield put({
      type: actionTypes.LOAD_HOME_PAGE_DLP_DATA_SUCCESS,
      homepageDLPData,
    });
  } catch (error) {
    const message =
      error instanceof Error ? error?.message : 'There was an error.';
    yield put({
      type: actionTypes.LOAD_HOME_PAGE_DLP_DATA_ERROR,
      error: message,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(actionTypes.LOAD_HOME_PAGE_DATA, loadHomePageSaga);
  yield takeEvery(actionTypes.LOAD_CAROUSEL_DATA, loadCarouselSaga);
  yield takeEvery(
    actionTypes.LOAD_HOME_PAGE_DLP_DATA,
    loadHomePageDLPSaga,
  );
}

export default loadSagaAll;
