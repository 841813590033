import { all } from 'redux-saga/effects';
import homepagePromoSaga from './homepagePromoSaga';
import headerFooterSaga from './headerFooterSaga';
import aboutPageSaga from './aboutPageSaga';
import partnersProgramSaga from './partnersProgramSaga';
import productPageSaga from './productPageSaga';
import listingPageSaga from './listingPageSaga';
import influencerPageSaga from './influencerPageSaga';
import personalShopperPageSaga from './personalShopperPageSaga';
import navigationSaga from './navigationSaga';
import socialFooterTileSaga from './socialFooterTileSaga';
import noSearchResultsSaga from './noSearchResultsSaga';
import metadataSaga from './metadataSaga';
import newsletterSaga from './newsletterSaga';
import cartSaga from './cartSaga';
import dynamicLandingPageSaga from './dynamicLandingPageSaga';
import locationPageSaga from './locationPageSaga';
import algoliaListingPageSaga from './algoliaListingPageSaga';

export default function* rootSaga(): Generator {
  yield all([
    homepagePromoSaga(),
    headerFooterSaga(),
    aboutPageSaga(),
    partnersProgramSaga(),
    productPageSaga(),
    listingPageSaga(),
    influencerPageSaga(),
    navigationSaga(),
    socialFooterTileSaga(),
    personalShopperPageSaga(),
    noSearchResultsSaga(),
    metadataSaga(),
    newsletterSaga(),
    cartSaga(),
    dynamicLandingPageSaga(),
    locationPageSaga(),
    algoliaListingPageSaga(),
  ]);
}
