import { HYDRATE } from 'next-redux-wrapper';
import { cloneDeep, get } from 'lodash';
import { personalShopperActionTypes as actionTypes } from '../actions/personalShopperPageActions';
import {
  reducerErrorMessage,
  reducerHelper,
} from '../utilities/helpers';
import { PersonalShopperPageReducer } from '../types/Store';

const initialState = {
  personalShopperData: {},
  error: null,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: PersonalShopperPageReducer = initialState,
  action: any,
): PersonalShopperPageReducer => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...action.payload.personalShopperPageReducer,
      };
    }
    case actionTypes.LOAD_PERSONAL_SHOPPER_DATA_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_PERSONAL_SHOPPER_DATA_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      const data = cloneDeep(action.data);
      const personalShopperData = get(data, 'items.0.fields', {});
      return {
        ...newState,
        personalShopperData,
      };
    }
    default:
      return state;
  }
};

export default reducer;
