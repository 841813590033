import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useSelector, useDispatch } from 'react-redux';
import { throttle } from 'lodash';
import { CookieFooter } from '@fashionphile/component-library';
import { useCookies } from 'react-cookie';
import EmailPopup from '../emailPopup';
import Header from '../header';
import Footer from '../footer';
import { MobileAppHeader } from '../mobileAppHeader';
import SocialFooter from '../socialFooter';
import { COOKIES } from '../../utilities/constants';
import { deviceHelper } from '../../utilities/helpers';
import { setListening } from '../../actions/appMetaActions';
import { setMobileApp } from '../../actions';
import {
  mobileAppMountEvents,
  mobileAppDismountEvents,
} from '../../utilities/mobileApp';
import type { ShopState } from '../../types/Store';
import useEventListener from '../useEventListener';

const target = typeof window === 'undefined' ? {} : window;

const Frame = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  // eslint-disable-next-line max-len
  const dispatch = useDispatch();
  const showLoader = useSelector(
    (state: ShopState) => state.headerFooterReducer.showLoader,
  );
  const isMobileApp = useSelector(
    (state: ShopState) => state.headerFooterReducer.isMobileApp,
  );
  const CLICKS_ALLOWED = 3;
  const router = useRouter();
  const [cookies, setCookie] = useCookies([
    COOKIES.PORT_KEY,
    COOKIES.EMAIL_POPUP,
  ]);
  const [currentCount, setCurrentCount] = useState(
    parseInt(cookies[COOKIES.EMAIL_POPUP], 10) || 0,
  );

  const handleResize = (): void => {
    dispatch(setMobileApp(deviceHelper()));
  };

  React.useEffect(() => {
    // Bind all 3rd party listeners here
    const isNowMobileApp = cookies[COOKIES.MOBILE_APP] === '1';

    if (isNowMobileApp) {
      mobileAppMountEvents();
      dispatch(setListening(true));
    }

    dispatch(setMobileApp(deviceHelper()));
    return (): void => {
      mobileAppDismountEvents();
      dispatch(setListening(false));
    };
  }, [cookies[COOKIES.MOBILE_APP]]);

  React.useEffect(() => {
    if (isMobileApp) {
      document.body.classList.add('x-fp-app');
    } else {
      document.body.classList.remove('x-fp-app');
    }
  }, [isMobileApp]);

  // Increment both the component state and the cookie. If argument is true, it will max out the value, preventing the pop-up from showing again.
  const incrementCount = (setToMax = false): void => {
    setCurrentCount((inCount) => {
      const newCount = setToMax ? CLICKS_ALLOWED + 1 : inCount + 1;
      setCookie(COOKIES.EMAIL_POPUP, newCount, {
        path: '/',
      });
      return newCount;
    });
  };

  useEffect(() => {
    const handleRouteChange = (): boolean => {
      if (isMobileApp) {
        return true;
      }
      if (!cookies[COOKIES.PORT_KEY]) {
        // User is not logged in
        if (currentCount < CLICKS_ALLOWED) {
          incrementCount();
        }
      } else if (cookies[COOKIES.EMAIL_POPUP]) {
        // User is now logged in, we can force the count to stop
        incrementCount(true);
      }
      return true;
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return (): void => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, []);
  const MILLISECONDS_WAIT = 50;
  useEventListener(
    target,
    'resize',
    throttle(handleResize, MILLISECONDS_WAIT),
  );

  let isSocialFooter = true;
  let isNoHeaderPages = false;

  if (router?.pathname) {
    const { pathname } = router;
    isSocialFooter =
      pathname === '/shop/[[...listingPageSlug]]' ||
      pathname === '/p/[productSlug]';
    isNoHeaderPages = pathname === '/chat' || pathname === '/search';
  }

  if (isNoHeaderPages) {
    return <>{children}</>;
  }

  return (
    <>
      {!isMobileApp ? <Header /> : <MobileAppHeader />}
      {children}
      {!isMobileApp && (
        <>
          {!isSocialFooter && <SocialFooter />}
          <Footer />
        </>
      )}
      <Link
        className="sellNowButton"
        href={`${process.env.SELLER_APP}/sell-your-bag`}
      >
        Sell Now
      </Link>
      {!isMobileApp && (
        <CookieFooter
          ariaLabel="Cookie Footer"
          buttonText="I accept"
          className="cookie"
          descriptionTextToDisplay="FASHIONPHILE uses cookies and may monitor and record your interactions on our site for quality assurance, to improve your website experience and provide personalized advertising. For more information, please see our"
          headerToDisplay="We value your privacy"
          link="https://help.fashionphile.com/s/privacy-policy"
          linkTextToDisplay="Privacy Policy"
          tabIndex={0}
        />
      )}
      {currentCount === CLICKS_ALLOWED && !showLoader && (
        <EmailPopup
          incrementCount={incrementCount}
          currentCount={currentCount}
        />
      )}
    </>
  );
};

export default Frame;
