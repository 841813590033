import EventEmitter from 'eventemitter3';
import { detailClickMapper } from './mapper/detailClickMapper';
import { filterMapper } from './mapper/filterMapper';
import { productListViewedMapper } from './mapper/productListViewed';
import { promoMapper } from './mapper/promoMapper';
import { setSegmentId, trackEvent, trackPage } from './segment';
import EventWhitelist from './eventWhitelist.json';

/**
 * For the time being,
 * when adding a new Event,
 * wrap it in the whitelistFilter fn
 * November '22
 */
export const EVENTS = {
  detailClick: 'detail_click',
  identify: 'identify',
  identifyAnonymous: 'identify_anonymous',
  favorite: 'favorite',
  filter: 'filter',
  modalClose: 'modal_close',
  modalOpen: 'modal_open',
  pageViewed: 'page_viewed',
  productClicked: 'product_clicked',
  productListViewed: 'product_list_viewed',
  productAddedToCart: 'product_added_to_cart',
  productSearch: 'product_search',
  productViewed: 'product_viewed',
  promoClicked: 'promo_clicked',
  promoViewed: 'promo_viewed',
};

const events = new EventEmitter();

/**
 * Checks for env var NEXT_PUBLIC_WHITELIST_EVENTS
 *
 * If `true`, checks against `EventWhitelist`
 *
 * use alike
 * ```
 * events.on(
 *  ...whitelistFilter(EVENT.foo, () => { ... })
 * )
 * ```
 *
 * @param {keyof EVENTS} event
 * @param {() => void | Promise<void>} eventCB
 */
const whitelistFilter = (event, eventCB) => {
  const shouldSendFavoriteEvent =
    event === 'favorite' &&
    process.env.NEXT_PUBLIC_WHITELIST_FAVORITE_EVENT === 'true';
  const shouldSendProductClickedEvent =
    event === 'product_clicked' &&
    process.env.NEXT_PUBLIC_WHITELIST_PRODUCT_CLICKED_EVENT ===
      'true';
  if (
    !EventWhitelist[event] &&
    !shouldSendFavoriteEvent &&
    !shouldSendProductClickedEvent
  ) {
    // must return a fn
    return [event, () => null];
  }
  return [event, eventCB];
};

// meta
events.on(
  ...whitelistFilter(EVENTS.identify, async (data) => {
    const userId = data.user_id;
    setSegmentId(
      typeof userId === 'number' ? `${userId}` : userId,
      data,
    );
  }),
);

// page tracking
/** data should be of type schema/pageViewed */
events.on(
  ...whitelistFilter(EVENTS.pageViewed, async (data) => {
    trackPage(data?.name, data);
  }),
);

// event tracking
/**
 * @type {object}
 * @property {number} user_id
 * @property {string} type - unique modal ref
 * @property {string} url - reference URL
 */
events.on(
  ...whitelistFilter(EVENTS.modalOpen, async (data) => {
    trackEvent('Modal Opened', data);
  }),
);

/**
 * @type {object}
 * @property {number} user_id
 * @property {string} type - unique modal ref
 * @property {string} url - reference URL
 */
events.on(
  ...whitelistFilter(EVENTS.modalClose, async (data) => {
    trackEvent('Modal Closed', data);
  }),
);

/** data should be of type schema/productClicked */
events.on(
  ...whitelistFilter(EVENTS.productClicked, async (data) => {
    trackEvent('Product Clicked', data);
  }),
);

/** data should be of type schema/productViewed */
events.on(
  ...whitelistFilter(EVENTS.productViewed, async (data) => {
    trackEvent('Product Viewed', data);
  }),
);

/** see productListViewedMapper */
events.on(
  ...whitelistFilter(
    EVENTS.productListViewed,
    async (data, favoriteIds) => {
      const formatted = productListViewedMapper(data, favoriteIds);
      trackEvent('Product List Viewed', formatted);
    },
  ),
);

/**
 * @type {object}
 * @property {string} Query - user-entered search string
 * @property {string} search_type - new vs recent (from dropdown)
 */
events.on(
  ...whitelistFilter(EVENTS.productSearch, async (data) => {
    trackEvent('Products Searched', data);
  }),
);

/**
 * @param {'Filtered' | 'Unfiltered'} filterType
 * @param {object} data see filterMapper
 */
events.on(
  ...whitelistFilter(EVENTS.filter, async (filterType, data) => {
    if (!filterType) {
      console.warn('Must set filter type');
      return;
    }

    // turn off per SEL-1771
    if (filterType === 'Unfiltered') {
      return;
    }

    const formatted = filterMapper(data);
    trackEvent(`Product List ${filterType}`, formatted);
  }),
);

events.on(
  ...whitelistFilter(EVENTS.promoClicked, async (data) => {
    const formatted = promoMapper(data);
    trackEvent('Promotion Clicked', formatted);
  }),
);
events.on(
  ...whitelistFilter(EVENTS.promoViewed, async (data) => {
    const formatted = promoMapper(data);
    trackEvent('Promotion Viewed', formatted);
  }),
);

events.on(
  ...whitelistFilter(EVENTS.detailClick, async (data) => {
    const formatted = detailClickMapper(data);
    trackEvent('Detail Click', formatted);
  }),
);

events.on(
  ...whitelistFilter(EVENTS.productAddedToCart, async (data) => {
    trackEvent('Product Added', data);
  }),
);

events.on(
  ...whitelistFilter(EVENTS.favorite, async (data, label) => {
    trackEvent(label, data);
  }),
);

events.on('experiment_viewed', async (optimizelyData) => {
  try {
    trackEvent('Experiment Viewed', optimizelyData);
    if (process.env.ENVIRONMENT !== 'production') {
      console.log('experiment viewed track event');
    }
  } catch (e) {
    if (process.env.ENVIRONMENT !== 'production') {
      console.log('sorry nigel', e);
    }
  }
});

export default events;
