import React from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { CUSTOM404_CONSTANTS as images } from '../../utilities/constants';
import type { ShopState } from '../../types/Store';

const Custom404 = (props: {
  error: Error;
  componentStack: string;
  errorText: string;
  is500: boolean;
}): React.ReactElement => {
  const {
    error = {
      name: '',
      message: '',
    },
    componentStack = '',
    errorText = '',
    is500 = false,
  } = props;
  const isTablet = useSelector(
    (state: ShopState) => state.headerFooterReducer.isTablet,
  );
  const isMobile = useSelector(
    (state: ShopState) => state.headerFooterReducer.isMobile,
  );
  const loggedIn = useSelector(
    (state: ShopState) => state.cartReducer.loggedIn,
  );

  const errorMessage = get(error, 'message', errorText);
  const largeImage = isTablet ? images.tablet : images.desktop;
  const breakpointSizedImage = isMobile ? images.mobile : largeImage;
  const production = process.env.NODE_ENV === 'production';

  return (
    <div className="customized404">
      <div className="imgContainer">
        {!is500 ? (
          <img src={breakpointSizedImage} alt="404 desktop" />
        ) : null}
      </div>

      <div className="container">
        <div className="content">
          <h1>Page not found</h1>
          <p>We can’t find the page you’re looking for. </p>
          <p>The URL might be an old link or it was moved. </p>
          <h2>Helpful Links</h2>
          <div className="grid">
            <a href="https://help.fashionphile.com/s/ ">Contact Us</a>
            <Link
              href={
                loggedIn
                  ? `${process.env.WEB_APP}/account/dashboard`
                  : `${process.env.WEB_APP}/login`
              }
            >
              My Account
            </Link>
            <Link href="/shop/new-arrivals">Shop New Arrivals</Link>
            <Link href={`${process.env.WEB_APP}/sell-with-us`}>
              Sell with us
            </Link>
          </div>

          {!production ? (
            <>
              <pre>{errorMessage}</pre>
              {componentStack !== '' ? (
                <pre>{componentStack}</pre>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Custom404;

Custom404.defaultProps = {
  error: {
    name: '',
    message: '',
  },
  componentStack: '',
  errorText: '',
  is500: false,
};
