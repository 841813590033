/* eslint-disable quote-props */
import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import { serializeError } from 'serialize-error';
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';
import { NavigationReducer } from '../types/Store';
import { actionTypes } from '../actions/navigationActions';

type NavigationSuccessResponse = {
  data: NavigationReducer;
  message: string;
  status: string;
};

type NavigationSuccessOrErrorResponse = NavigationSuccessResponse &
  AxiosError;

function* loadNavigationSaga(): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<NavigationSuccessOrErrorResponse>
> {
  const url = `${process.env.NEXT_PUBLIC_CONTENT_SERVICE_URL}/navigation`;
  try {
    const options: AxiosRequestConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json, text/plain, */*',
      },
      timeout: 10000,
    };
    const res = yield call(axios.get, url, options);
    if (res.data?.status === 'success') {
      yield put({
        type: actionTypes.LOAD_NAVIGATION_SUCCESS,
        data: res.data.data,
        url,
      });
    } else {
      yield put({
        type: actionTypes.LOAD_NAVIGATION_ERROR,
        error:
          res.data?.message ||
          'There was an error with the navigation data',
        url,
      });
    }
  } catch (error) {
    const errorObj = serializeError(error);
    const message =
      typeof errorObj.message !== 'undefined'
        ? errorObj.message
        : 'There was an error.';
    const stack =
      typeof errorObj.stack !== 'undefined' ? errorObj.stack : '';
    yield put({
      type: actionTypes.LOAD_NAVIGATION_ERROR,
      error: message,
      stack,
      url,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(actionTypes.LOAD_NAVIGATION, loadNavigationSaga);
}

export default loadSagaAll;
