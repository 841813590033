import { PERCENTAGE_MULTIPLIER } from '../../../utilities/constants';
import { SegmentFavorite } from '../interfaces';
import { brandMapper } from './productMapperHelper';

const allowedCondition = new Set([
  'New',
  'Giftable',
  'Excellent',
  'Very Good',
  'Good',
  'Fair',
  'Flawed',
]);

export const formatCondition = (condition: string): string => {
  const uppercaseCondition =
    condition === 'very_good'
      ? 'Very Good'
      : `${condition?.charAt(0).toUpperCase()}${condition
          ?.slice(1)
          .toLowerCase()}`;
  const formattedCondition = allowedCondition.has(uppercaseCondition)
    ? uppercaseCondition.replace('Giftable', 'New')
    : condition;
  return formattedCondition;
};

// TO DO: clean this type
export const recommendedProductMapper = (
  product: SegmentFavorite,
): SegmentFavorite => {
  return {
    brand: product?.brand
      ? brandMapper(product?.brand) || null
      : null,
    category: product?.category || null,
    color: product?.color || null,
    condition: formatCondition(product?.condition),
    currency: product?.currency || 'USD',
    days_on_sale: product?.daysOnSale || null,
    discountedPrice: product?.discountedPrice || null,
    id: product?.id || 0,
    image: product?.image || null,
    name: product?.title || null,
    price: +(product?.discountedPrice || 0) || null,
    product_discount: Math.round(
      PERCENTAGE_MULTIPLIER *
        (1 - (product?.discountedPrice || 0) / (product?.price || 0)),
    ),
    product_discount_amount:
      +(product?.price || 0) - +(product?.discountedPrice || 0),
    product_id:
      product?.id ||
      product?.productId ||
      product?.product_Id ||
      null,
    quantity: 1,
    sku: null,
    slug: product?.slug || null,
    style: null,
    title: product?.title || null,
    titleWithoutBrand: product?.titleWithoutBrand || null,
    url: /https?/.test(product?.slug || '')
      ? product.slug || ''
      : (process.env.DOMAIN || '') + product?.slug,
    value: +(product?.price || 0),
  };
};
