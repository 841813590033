export const algoliaListingPageActionTypes = {
  LOAD_ALGOLIA_LISTING_PAGE: 'LOAD_ALGOLIA_LISTING_PAGE',
  LOAD_ALGOLIA_LISTING_PAGE_ERROR: 'LOAD_ALGOLIA_LISTING_PAGE_ERROR',
  LOAD_ALGOLIA_LISTING_PAGE_SUCCESS:
    'LOAD_ALGOLIA_LISTING_PAGE_SUCCESS',
  CLEAR_ALGOLIA_LISTING_PAGE: 'CLEAR_ALGOLIA_LISTING_PAGE',
};

export function loadAlgoliaListingPage(params) {
  return {
    params,
    type: algoliaListingPageActionTypes.LOAD_ALGOLIA_LISTING_PAGE,
  };
}

export function clearAlgoliaListingPage() {
  return {
    type: algoliaListingPageActionTypes.CLEAR_ALGOLIA_LISTING_PAGE,
  };
}
