import { PriceRange } from 'src/types/Store';

export const algoliaIndex = `${
  process.env.NEXT_PUBLIC_ALGOLIA_ENVIRONMENT || 'qa'
}_${
  process.env.NEXT_PUBLIC_ALGOLIA_DEFAULT_INDEX || 'ecom_products'
}`;

export const algoliaAutocompleteIndex = `${
  process.env.NEXT_PUBLIC_ALGOLIA_ENVIRONMENT || 'qa'
}_${
  process.env.NEXT_PUBLIC_ALGOLIA_AUTOCOMPLETE_INDEX ||
  'ecom_autocomplete'
}`;

export const getSortSlug = (name: string): string => {
  if (name === algoliaIndex) {
    return 'relevance';
  }

  return name
    .replace(`${algoliaIndex}_pricedat_desc`, 'pricedat-desc')
    .replace(`${algoliaIndex}_price_asc`, 'price-asc')
    .replace(`${algoliaIndex}_price_desc`, 'price-desc')
    .replace(`${algoliaIndex}_title_asc`, 'title-asc')
    .replace(`${algoliaIndex}_title_desc`, 'title-desc')
    .replace(`${algoliaIndex}_date_asc`, 'date-asc')
    .replace(`${algoliaIndex}_date_desc`, 'date-desc')
    .replace(`${algoliaIndex}_bestvalue_desc`, 'bestvalue-desc')
    .replace(`${algoliaIndex}_popularity_desc`, 'popularity-desc')
    .replace(`${algoliaIndex}`, 'relevance');
};

export const getSlug = (names: string[]): string[] => {
  return names.map((name) =>
    name
      .toLocaleLowerCase()
      .replace('5% off', '1')
      .replace('10% off', '2')
      .replace('20% off', '3')
      .replace('30% off', '4')
      .replace('last call', '5')
      .replace('very good', 'very_good')
      .replace('phone/tablet', 'phone-or-tablet')
      .replace('art / scene', 'art-or-scene')
      .replace(`men's bags`, 'manbags')
      .replace(`men's wallets`, 'menswallets')
      .replace('crossbody', 'cross-body')
      .replace('exotic skins & fur', 'exotics')
      .replace('suede leather', 'suede')
      .replace('cell phone pocket', 'cell-pocket')
      .replace('holds ipad or tablet', 'ipad')
      .replace('holds laptop', 'laptop')
      .replace('flats', 'flats-shoes')
      .replace('pumps', 'pumps-shoes')
      .replace('sandals', 'sandals-shoes')
      .replace('sneakers', 'sneakers-shoes')
      .replace('john hardy', 'johnhardy')
      .replace('patek philippe', 'patekphilippe')
      .replace('van cleef & arpels', 'van-cleef')
      .replace('saint laurent', 'ysl')
      .replace('st.', 'st')
      .replace(' schouler', '')
      .replace('christian ', '')
      .replace('david ', '')
      .replace('swag', '')
      .replace(' & sequins', '')
      .replace('/', '-')
      .replace(' & ', '-')
      .replace(`'`, '')
      .replace('3.1 ', '')
      .replace('under $', '')
      .replace('over $', '')
      .replaceAll('$', '')
      .replaceAll(' ', '-'),
  );
};

export const getPriceName = (slugs: string[]): PriceRange => {
  const data: PriceRange = {
    price: [],
    range: null,
  };
  slugs.map((slug) => {
    switch (slug) {
      case '500-1000':
        return data.price.push('$500-$1000');
      case '1000-2000':
        return data.price.push('$1000-$2000');
      case '200-500':
        return data.price.push('$200-$500');
      case '2000':
        return data.price.push('Over $2000');
      case '200':
        return data.price.push('Under $200');
      default:
        return (data.range = slug);
    }
  });
  return data;
};

export const getPriceForRoute = (
  price: string[] | undefined,
  range: string[] | undefined,
): string[] => {
  const data = price ? [...price] : [];
  if (range?.[0]) {
    data.push(range[0]);
  }
  return data;
};

export const getDiscountedTierName = (slugs: string[]): string[] => {
  return slugs.map((slug) =>
    slug
      .replace('5', 'last call')
      .replace('1', '5% off')
      .replace('2', '10% off')
      .replace('3', '20% off')
      .replace('4', '30% off')
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter: string): string =>
        letter.toUpperCase(),
      ),
  );
};

export const getCategoriesName = (
  slugs: string[],
): {
  bags: string[];
  jewelry: string[];
  accessories: string[];
  watches: string[];
  shoes: string[];
  color: string[];
  material: string[];
  bag_feature: string[];
  ring_size: string[];
  watch_size: string[];
  belt_size: string[];
  shoe_size: string[];
} => {
  const allCategoriesOptions = {
    bags: [
      'Handbags',
      'Shoulder Bags',
      'Crossbody',
      'Totes',
      'Clutch & Evening',
      'Wallet Style',
      'Backpacks',
      'Travel & Luggage',
      'Belt Bags',
      `Men's Bags`,
      'Professional',
    ],
    jewelry: [
      'Bracelets',
      'Necklaces',
      'Earrings',
      'Rings',
      'Fine Jewelry',
    ],
    accessories: [
      'Shoes',
      'Wallets',
      'Pouches',
      'Scarves',
      'Sunglasses',
      'Belts',
      'Cosmetic Cases',
      'Extra Straps',
      'Key Rings',
      'Bag Charms',
      `Men's Accessories`,
      'Hats',
      'Household Items',
      'Pins & Brooches',
      'Agendas',
      'Phone/Tablet Case',
      'Hair Accessories',
      `Men's Wallets`,
      'Luggage Tags',
      'Pet Accessories',
      'Gloves',
      'Fashionphile Merchandise',
    ],
    watches: ['Luxury Watches', 'Fashion Watches', 'Womens Watches'],
    shoes: [
      'Boots & Booties',
      'Flats',
      'Pumps',
      'Sandals',
      'Sneakers',
    ],
    color: [
      'Black',
      'Browns',
      'Multicolor',
      'Whites',
      'Beige',
      'Blues',
      'Pink',
      'Reds',
      'Grays',
      'Gold',
      'Greens',
      'Metallic',
      'Silver',
      'Purples',
      'Yellows',
      'Orange',
    ],
    material: [
      'Leather',
      'Solid Color',
      'Coated Canvas',
      'Fabrics',
      'Art / Scene on Exterior',
      'Yellow Gold',
      'Metallic',
      'Patent Leather',
      'Exotic Skins & Fur',
      'Sterling Silver',
      'Plastics',
      'Rose Gold',
      'Suede Leather',
      'Beaded & Sequins',
      'Stainless Steel',
      'Borders have trim detail',
      'White Gold',
      'Platinum',
    ],
    bag_feature: [
      'Structured',
      'Top Handles',
      'Zip Top',
      'Open Top',
      'Mom Bag',
      'Holds iPad or Tablet',
      'Cell Phone Pocket',
      'Holds Laptop',
    ],
    ring_size: [
      '52/6',
      '51/5.75',
      '54/7',
      '55/7.25',
      '54/6.75',
      '53/6.5',
      '48/4.5',
      '50/5.25',
      '53/6.25',
      '49/5',
      '47/4',
      '50/5.5',
      '57/8',
      '49/4.75',
      '56/7.5',
      '58/8.25',
      '60/9',
      '59/8.75',
      '45/3.25',
      '46/3.75',
      '62/10',
      '54.4/7',
      '61/9.5',
      '44/3',
      '63/10.25',
      '64/10.75',
      '64/10.75',
      '65/11',
      '66/11.5',
      '67/11.75',
      '69/12.5',
    ],
    watch_size: ['31 to 40mm', '20 to 30mm', '41 to 50mm'],
    belt_size: [
      '85/34',
      '80/32',
      '90/36',
      '75/30',
      '95/38',
      '100/40',
      '70/28',
      '110/44',
      '105/42',
      '120/48',
      '115/46',
    ],
    shoe_size: [
      '37',
      '38',
      '36',
      '39',
      '36.5',
      '37.5',
      '40',
      '38.5',
      '35',
      '39.5',
      '41',
      '35.5',
      '40.5',
      '9',
      '8',
      '42',
      '10',
      '7',
      '43',
      '44',
      '8.5',
      '11',
      '9.5',
      '6',
      '12',
      '12.5',
      '7.5',
      '45',
      '42.5',
      '11.5',
      '41.5',
      '5',
      '10.5',
      '6.5',
      '13',
      '46',
      '5.5',
      '43.5',
      '45.5',
    ],
  };
  const selectedCategories: {
    bags: string[];
    jewelry: string[];
    accessories: string[];
    watches: string[];
    shoes: string[];
    color: string[];
    material: string[];
    bag_feature: string[];
    ring_size: string[];
    watch_size: string[];
    belt_size: string[];
    shoe_size: string[];
  } = {
    bags: [],
    jewelry: [],
    accessories: [],
    watches: [],
    shoes: [],
    color: [],
    material: [],
    bag_feature: [],
    ring_size: [],
    watch_size: [],
    belt_size: [],
    shoe_size: [],
  };

  slugs.forEach((slug) => {
    const name = slug
      .replace('clutch-evening', 'Clutch & Evening')
      .replace('travel-luggage', 'Travel & Luggage')
      .replace('pins-brooches', 'Pins & Brooches')
      .replace('beaded', 'Beaded & Sequins')
      .replace('boots-booties', 'Boots & Booties')
      .replace('phone-or-tablet', 'phone/tablet')
      .replace('art-or-scene', 'art / scene')
      .replace('manbags', `Men's Bags`)
      .replace('menswallets', `Men's Wallets`)
      .replace('mens-accessories', `Men's Accessories`)
      .replace('cross-body', 'crossbody')
      .replace('exotics', 'exotic skins & fur')
      .replace('materials-metallic', 'metallic')
      .replace('colors-metallic', 'metallic')
      .replace('suede', 'suede leather')
      .replace('cell-pocket', 'cell phone pocket')
      .replace('laptop', 'holds laptop')
      .replace('flats-shoes', 'flats')
      .replace('pumps-shoes', 'pumps')
      .replace('sandals-shoes', 'sandals')
      .replace('sneakers-shoes', 'sneakers')
      .replace(/-(?=\d)/g, '/')
      .replaceAll('-', ' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter: string): string =>
        letter.toUpperCase(),
      )
      .replace(' To ', ' to ')
      .replace(' On ', ' on ')
      .replace('Borders Have Trim Detail', 'Borders have trim detail')
      .replace('Ipad', 'Holds iPad or Tablet')
      .replace('T Shirt', 'T-Shirt');

    if (allCategoriesOptions.bags.includes(name)) {
      selectedCategories.bags.push(name);
    }
    if (allCategoriesOptions.jewelry.includes(name)) {
      selectedCategories.jewelry.push(name);
    }
    if (allCategoriesOptions.accessories.includes(name)) {
      selectedCategories.accessories.push(name);
    }
    if (allCategoriesOptions.watches.includes(name)) {
      selectedCategories.watches.push(name);
    }
    if (allCategoriesOptions.shoes.includes(name)) {
      selectedCategories.shoes.push(name);
    }
    if (
      allCategoriesOptions.color.includes(name) &&
      slug !== 'materials-metallic'
    ) {
      selectedCategories.color.push(name);
    }
    if (
      allCategoriesOptions.material.includes(name) &&
      slug !== 'colors-metallic'
    ) {
      selectedCategories.material.push(name);
    }
    if (allCategoriesOptions.bag_feature.includes(name)) {
      selectedCategories.bag_feature.push(name);
    }
    if (allCategoriesOptions.ring_size.includes(name)) {
      selectedCategories.ring_size.push(name);
    }
    if (allCategoriesOptions.watch_size.includes(name)) {
      selectedCategories.watch_size.push(name);
    }
    if (allCategoriesOptions.belt_size.includes(name)) {
      selectedCategories.belt_size.push(name);
    }
    if (allCategoriesOptions.shoe_size.includes(name)) {
      selectedCategories.shoe_size.push(name);
    }
  });
  return selectedCategories;
};

export const getSortName = (slug: string): string => {
  if (!slug) {
    return `${algoliaIndex}`;
  }

  return slug
    .replace('relevance', `${algoliaIndex}`)
    .replace('price-asc', `${algoliaIndex}_price_asc`)
    .replace('price-desc', `${algoliaIndex}_price_desc`)
    .replace('title-asc', `${algoliaIndex}_title_asc`)
    .replace('title-desc', `${algoliaIndex}_title_desc`)
    .replace('date-asc', `${algoliaIndex}_date_asc`)
    .replace('date-desc', `${algoliaIndex}_date_desc`)
    .replace('bestvalue-desc', `${algoliaIndex}_bestvalue_desc`)
    .replace('popularity-desc', `${algoliaIndex}_popularity_desc`)
    .replace('pricedat-desc', `${algoliaIndex}_pricedat_desc`);
};

export const validateSort = (sort: string): boolean => {
  switch (sort) {
    case 'relevance':
    case 'price-asc':
    case 'price-desc':
    case 'title-asc':
    case 'title-desc':
    case 'date-asc':
    case 'date-desc':
    case 'bestvalue-desc':
    case 'popularity-desc':
    case 'pricedat-desc':
      return true;
    default:
      return false;
  }
};

export const getName = (slugs: string[]): string[] => {
  return slugs.map((slug) =>
    slug
      .replace('very_good', 'very good')
      .replace('johnhardy', 'john hardy')
      .replace('patekphilippe', 'patek philippe')
      .replace('van-cleef', 'van cleef & arpels')
      .replace('ysl', 'saint laurent')
      .replace('st-', 'st. ')
      .replace('proenza', 'Proenza Schouler')
      .replace('dior', 'Christian Dior')
      .replace('louboutin', 'Christian Louboutin')
      .replace('yurman', 'David Yurman')
      .replace('fashionphile', 'FASHIONPHILE swag')
      .replace('phillip-lim', '3.1 Phillip Lim')
      .replace('alexander-mcqueen', 'Alexander McQueen')
      .replace('stella-mccartney', 'Stella McCartney')
      .replaceAll('-', ' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter: string): string =>
        letter.toUpperCase(),
      )
      .replace('Jaeger Lecoultre', 'Jaeger-LeCoultre')
      .replace('Off White', 'Off-White')
      .replace('Mcm', 'MCM')
      .replace('Tudor', 'TUDOR'),
  );
};
