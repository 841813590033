import { FALSY_INTEGER } from '../constants';
import { SegmentSchema } from './schema/schema';

const isFalsey = (val: unknown): boolean => {
  if (
    !val ||
    (typeof val === 'number' && !val) ||
    (typeof val === 'string' && !val) ||
    (Array.isArray(val) && !val.length) ||
    (typeof val === 'object' && !Object.keys(val).length)
  ) {
    return true;
  }
  return false;
};

/**
 * Validates and attempts to fix **null** values to types in Segment event data.
 */
export const validateEventSchema = (
  data: { [key: string]: unknown },
  schema: SegmentSchema,
): { [key: string]: unknown } | false => {
  if (!schema) {
    console.error('no schema provided for validation!');
    return false;
  }
  if (schema.type !== 'object') {
    console.warn(
      `improper schema formatting, expected 'object', got: ${schema.type}`,
    );
    return false;
  }
  if (!schema?.properties?.properties?.properties) {
    console.warn('no schema validators found');
    return false;
  }

  const props = schema.properties.properties.properties;

  const validatedData: { [key: string]: unknown } = {};

  /* eslint-disable no-restricted-syntax, valid-typeof, guard-for-in */
  for (const k in props) {
    if (props.hasOwnProperty(k)) {
      const dataVal: unknown = data[k];
      const dataType: string = typeof dataVal;
      const { type } = props[k];
      if (dataVal === FALSY_INTEGER) {
        validatedData[k] = FALSY_INTEGER;
      } else if (
        isFalsey(dataVal) ||
        (dataVal === null &&
          Array.isArray(type) &&
          type.some((t) => t === 'null'))
      ) {
        validatedData[k] = null;
      } else if (
        dataVal &&
        (dataType === type ||
          (Array.isArray(type) && type.some((t) => t === dataType)) ||
          (type === 'number' && dataType === 'integer') ||
          (type === 'integer' && dataType === 'number') ||
          (type === 'array' && Array.isArray(dataVal)))
      ) {
        validatedData[k] = dataVal;
      } else if (
        (dataType === 'boolean' && type === 'integer') ||
        (dataType === 'boolean' && type === 'number')
      ) {
        validatedData[k] = Number(dataVal);
      } else {
        console.warn(
          `unexpected data type for ${k}. expected: ${type} actual: ${dataType}`,
        );
      }
    }
  }
  /* eslint-enable no-restricted-syntax, valid-typeof, guard-for-in */
  return validatedData;
};
