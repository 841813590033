import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { Cookies } from 'react-cookie';
import { COOKIES, API_GATEWAY_HEADERS } from '../utilities/constants';

export type FavoritesAPIData = {
  data: number[];
  count?: number;
};

export type ProductAndUserData = {
  productId: number;
};

const cookieForUserCredential = new Cookies();
const token = cookieForUserCredential.get(COOKIES.PORT_KEY);
const options: AxiosRequestConfig = {
  headers: {
    Accept: 'application/x.fashionphile.v1+json',
    Authorization: `Bearer ${token}`,
  },
  timeout: API_GATEWAY_HEADERS.TIMEOUT,
};
const baseURL = `${process.env.NEXT_PUBLIC_WEB_APP_API}/api`;

export const getFavoritesQueryFn =
  async (): Promise<FavoritesAPIData> => {
    try {
      options.method = 'GET';
      options.url = `${baseURL}/v2/followed-products`;
      const result = await axios(options);
      return result?.data;
    } catch (error) {
      console.error('Failed to get favorites: ', error);
      return {
        data: [],
      };
    }
  };

export const addFavoriteMutateFn = async (
  productId: number,
): Promise<FavoritesAPIData> => {
  try {
    options.method = 'POST';
    options.url = `${baseURL}/v2/followed-products`;
    options.data = {
      id: productId,
    };
    const res = await axios(options);
    return res?.data;
  } catch (error) {
    console.error('Error adding to favorite: ', error);
    return {
      data: [],
    };
  }
};

export const deleteFavoriteMutateFn = async (
  productId: number,
): Promise<FavoritesAPIData> => {
  try {
    options.method = 'DELETE';
    options.url = `${baseURL}/v2/followed-products/${productId}`;
    const res = await axios(options);
    return res?.data;
  } catch (error) {
    console.error('Error removing favorite :', error);
    return {
      data: [],
    };
  }
};

export const useGetFavoritesQuery = (
  userId: number,
): UseQueryResult<FavoritesAPIData> => {
  return useQuery({
    queryKey: ['favorites'],
    queryFn: getFavoritesQueryFn,
    enabled: !!userId,
    staleTime: 0,
  });
};

export const useAddFavoriteMutation = ({
  productId,
}: ProductAndUserData): UseMutationResult<
  FavoritesAPIData,
  unknown,
  void
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => addFavoriteMutateFn(productId),
    onMutate: () => {
      const data: number[] =
        queryClient.getQueryData<{ data: number[] }>(['favorites'])
          ?.data || [];
      queryClient.setQueryData(['favorites'], () => {
        const filtered = data.filter(
          (id: number) => id !== productId,
        );
        return { data: filtered };
      });
    },
    onError: () => {
      const data =
        queryClient.getQueryData<{ data: number[] }>(['favorites'])
          ?.data || [];
      queryClient.setQueryData(['favorites'], () => {
        const filtered = data.filter(
          (id: number) => id !== productId,
        );
        return { data: filtered };
      });
    },
  });
};

export const useDeleteFavoriteMutation = ({
  productId,
}: ProductAndUserData): UseMutationResult<
  FavoritesAPIData,
  unknown,
  void
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => deleteFavoriteMutateFn(productId),
    onError: () => {
      const data =
        queryClient.getQueryData<{ data: number[] }>(['favorites'])
          ?.data || [];
      queryClient.setQueryData(['favorites'], () => {
        return { data: [...data, productId] };
      });
    },
    onMutate: () => {
      const data =
        queryClient.getQueryData<{ data: number[] }>(['favorites'])
          ?.data || [];
      queryClient.setQueryData(['favorites'], () => {
        const filtered = data.filter(
          (id: number) => id !== productId,
        );
        return { data: filtered };
      });
    },
  });
};

export const useAddFavoriteMutationAsync = (): UseMutationResult<
  FavoritesAPIData,
  unknown,
  ProductAndUserData
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ productId }) => addFavoriteMutateFn(productId),
    onSuccess: (response) => {
      queryClient.setQueryData(['favorites'], () => {
        return response.data;
      });
    },
  });
};
