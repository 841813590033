import {
  SORT_VALUES,
  NOT_SEARCHABLE_INDEX,
  MAX_PATHS,
} from '../utilities/constants';

export const listingPageActionTypes = {
  LOAD_PRODUCT_LISTING_PAGE: 'LOAD_PRODUCT_LISTING_PAGE',
  LOAD_PRODUCT_LISTING_PAGE_ERROR: 'LOAD_PRODUCT_LISTING_PAGE_ERROR',
  LOAD_PRODUCT_LISTING_PAGE_SUCCESS:
    'LOAD_PRODUCT_LISTING_PAGE_SUCCESS',
  CLEAR_LISTING_PAGE: 'CLEAR_FILTERS',
};

/**
 * return sort string
 * @param {string} slug
 * @param {boolean} isSearch
 * @returns string
 */
const sortVal = (slug, isSearch = false) => {
  if (isSearch) {
    return 'relevance';
  }
  if (slug === 'new-arrivals') {
    if (
      !!process.env.NEXT_PUBLIC_CUSTOM_SORT_NEW_ARRIVALS &&
      SORT_VALUES.find(
        ({ key }) =>
          key === process.env.NEXT_PUBLIC_CUSTOM_SORT_NEW_ARRIVALS,
      )
    ) {
      return process.env.NEXT_PUBLIC_CUSTOM_SORT_NEW_ARRIVALS;
    }
    return 'date-desc';
  }

  // if secrets manager override is entered for other custom sort conditions
  if (
    !!process.env.NEXT_PUBLIC_CUSTOM_SORT &&
    SORT_VALUES.find(
      ({ key }) => key === process.env.NEXT_PUBLIC_CUSTOM_SORT,
    )
  ) {
    return process.env.NEXT_PUBLIC_CUSTOM_SORT;
  }

  return 'date-desc';
};

const FIRST_PAGE = 1;

export function loadProductListingPage(
  slug,
  page = FIRST_PAGE,
  sort = '',
  filters = '',
  search = '',
  query = {},
  discountedtier = '',
  isCPLP = false,
) {
  let updatedFilters = '';
  let sortBy = sort;
  if (slug.length > 0) {
    if (
      slug[0] === 'brands' &&
      filters.indexOf(slug[1]) === NOT_SEARCHABLE_INDEX
    ) {
      updatedFilters += `&brands=${slug[1]}`;
    } else if (slug[0] === 'new-arrivals') {
      updatedFilters += '&newarrivals=true';
    } else if (slug[0] === 'discounted') {
      updatedFilters += `&discountedtier=${slug[1]}`;
    } else if (
      slug.length === MAX_PATHS &&
      (filters === '' ||
        (filters.indexOf(slug[1]) === NOT_SEARCHABLE_INDEX &&
          filters.indexOf(slug[2]) === NOT_SEARCHABLE_INDEX))
    ) {
      const catStr = 'categories=';
      if (filters.indexOf(catStr) > NOT_SEARCHABLE_INDEX) {
        updatedFilters = updatedFilters.replace(
          catStr,
          `${catStr}${slug[1]}+`,
        );
      } else {
        updatedFilters += `&categories=${slug[1]}`;
      }
    }
  }
  updatedFilters += filters;

  if (sortBy === '' && !isCPLP) {
    sortBy = sortVal(slug[0], !!search);
  }

  return {
    type: listingPageActionTypes.LOAD_PRODUCT_LISTING_PAGE,
    search,
    page,
    filters: updatedFilters,
    sortBy,
    slug,
    query,
    discountedtier,
    isCPLP,
  };
}

export function clearListingPage() {
  return {
    type: listingPageActionTypes.CLEAR_LISTING_PAGE,
  };
}
