/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { get } from 'lodash';
import type {
  ContentTilesDataRow,
  HomepagePromoReducer,
  ContentTilesData,
} from '../types/Store';
import { homepagePromoActionTypes as actionTypes } from '../actions/homepagePromoActions';
import {
  reducerErrorMessage,
  reducerHelper,
} from '../utilities/helpers';

/**
 * todo:
 * split reducer file up like in merch-tool
 */
const initialState = {
  error: null,
  heroData: null,
  carouselData: null,
  contentTilesData: {
    row1: [],
    row2: [],
    row3: [],
    row4: [],
  },
  homepageData: {
    align: 'left',
  },
  homepageDLPData: {
    contentBlocks: [],
    contentBlockIdArr: [],
    metaData: {
      canonical: '',
      description: '',
      keywords: '',
      metaDescription: '',
      metaKeywords: '',
      metaTitle: '',
      ogDescription: '',
      ogImage: {
        metadata: {
          tags: null,
        },
        sys: {
          space: {
            sys: {
              type: '',
              linkType: '',
              id: '',
            },
          },

          type: '',
          id: '',
          revision: 0,
          createdAt: '',
          updatedAt: '',
          environment: {
            sys: {
              type: '',
              linkType: '',
              id: '',
            },
          },
          locale: '',
        },
        fields: {
          title: '',
          description: '',
          file: {
            url: '',
            details: {
              size: 0,
              image: {
                width: 0,
                height: 0,
              },
            },
            fileName: '',
            contentType: '',
          },
        },
      },
      ogImageAlt: '',
      ogImageHeight: '',
      ogImageWidth: '',
      ogTitle: '',
      title: '',
    },
  },
};

const mapButtonType = (input: string): string => {
  switch (input) {
    case 'Black':
      return 'primary';
    case 'White':
      return 'secondary';
    case 'Pink':
      return 'pink';
    case 'Ghost-Black':
      return 'ghost-black';
    case 'Ghost-white':
      return 'ghost-white';
    default:
      return input;
  }
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: HomepagePromoReducer = initialState,
  action: any,
): HomepagePromoReducer => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.homepagePromoReducer };
    }
    case actionTypes.LOAD_HOME_PAGE_DLP_DATA_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      return {
        ...newState,
        ...{
          homepageDLPData: action.homepageDLPData,
        },
      };
    }
    case actionTypes.LOAD_HOME_PAGE_DLP_DATA_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_CAROUSEL_DATA_ERROR:
    case actionTypes.LOAD_HOME_PAGE_DATA_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_CAROUSEL_DATA_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      const { carouselData } = action;
      return {
        ...newState,
        carouselData,
      };
    }
    case actionTypes.LOAD_HERO_DATA: {
      const newState = reducerHelper(state, action.type);
      const { heroData } = action;
      heroData.desktopImage = get(
        heroData,
        'heroImage.fields.file.url',
        null,
      );
      heroData.mobileImage = get(
        heroData,
        'mobileHeroImage.fields.file.url',
        null,
      );
      heroData.ctaButtons = get(heroData, 'ctaButtons', []).map(
        (button: any) => {
          const { fields } = button;
          if (fields.ctaColor) {
            fields.ctaColor = mapButtonType(fields.ctaColor) || null;
          }
          return fields;
        },
      );
      heroData.textColor = get(
        heroData,
        'textColor',
        '',
      ).toLowerCase();
      return {
        ...newState,
        ...{ heroData },
      };
    }
    case actionTypes.LOAD_CONTENT_TILES_DATA: {
      const newState = reducerHelper(state, action.type);
      const contentTilesData: ContentTilesData = {
        row1: [],
        row2: [],
        row3: [],
        row4: [],
      };
      action.contentTilesData.forEach((item: any, index: number) => {
        const obj: ContentTilesDataRow = {
          header: item.fields.header,
          subcopy: item.fields.subcopy || null,
          imageAltCopy: item.fields.imageAltCopy,
          link1TextToDisplay: item.fields.link1TextToDisplay,
          link1Url: item.fields.link1Url,
          link2TextToDisplay: item.fields.link2TextToDisplay || null,
          link2Url: item.fields.link2Url || null,
          image: item.fields.tileImage.fields.file.url,
        };
        const FIRST_ROW_START_INDEX = 1;
        const SECOND_ROW_START_INDEX = 4;
        const THIRD_ROW_START_INDEX = 6;
        if (index <= FIRST_ROW_START_INDEX) {
          contentTilesData.row1?.push(obj);
        } else if (
          index > FIRST_ROW_START_INDEX &&
          index <= SECOND_ROW_START_INDEX
        ) {
          contentTilesData.row2?.push(obj);
        } else if (
          index > SECOND_ROW_START_INDEX &&
          index <= THIRD_ROW_START_INDEX
        ) {
          contentTilesData.row3?.push(obj);
        } else {
          contentTilesData.row4?.push(obj);
        }
      });
      return {
        ...newState,
        ...{ contentTilesData },
      };
    }
    case actionTypes.LOAD_HOME_PAGE_DATA_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      return {
        ...newState,
        ...{ homepageData: action.homepageData },
      };
    }
    default:
      return state;
  }
};

export default reducer;
