/* eslint-disable quote-props */
import {
  put,
  takeEvery,
  CallEffect,
  PutEffect,
  call,
} from 'redux-saga/effects';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { serializeError } from 'serialize-error';
import { NewsletterReducer } from '../types/Store';
import { newsletterActionTypes as actionTypes } from '../actions/newsletterActions';

type AddNewsletterActionPayload = {
  email: string;
  type: string;
};

type NewsletterSuccessResponse = {
  data: NewsletterReducer;
};

type ListingPageSuccessOrErrorResponse = NewsletterSuccessResponse &
  AxiosError;

function* addNewsletter(
  params: AddNewsletterActionPayload,
): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<ListingPageSuccessOrErrorResponse>
> {
  const { email } = params;
  const url = '/api/newsletter';

  try {
    yield put({
      type: actionTypes.LOADING_NEWSLETTER,
    });
    const res = yield call(axios.post, url, {
      email,
      blog_subscription: 0,
      newsletter_subscription: 1,
    });

    yield put({
      type: actionTypes.ADD_NEWSLETTER_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    const errorObj = serializeError(error);
    const message = 'Something went wrong. Please try again.';
    const stack =
      typeof errorObj.stack !== 'undefined' ? errorObj.stack : '';
    yield put({
      type: actionTypes.ADD_NEWSLETTER_ERROR,
      error: message,
      stack,
      status: false,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(actionTypes.ADD_NEWSLETTER, addNewsletter);
}

export default loadSagaAll;
