// helper.js -> helper.ts helper types can live here
export type DeviceInfo = {
  width: number | null;
  mobile: boolean;
  oldMobile: boolean;
  tablet: boolean;
  desktop: boolean;
  isMobileApp: boolean;
};

export type CookieType = {
  staging: string;
  production: string;
  local: string;
  development: string;
  unistage: string;
  qa: string;
};

export enum HttpMethod {
  GET = 'get',
  PATCH = 'patch',
  POST = 'post',
  DELETE = 'delete',
  PUT = 'put',
}
