import axios, { AxiosRequestConfig } from 'axios';
import { HttpMethod } from 'src/types/Helpers';
import { API_GATEWAY_HEADERS } from './constants';
import { productApiHelper } from './helpers';

type SearchResult = {
  title: string;
  id: number;
  url: string;
};

type SearchAPI = {
  data: {
    results: SearchResult[];
  };
};

export type SearchProp = {
  setTerm: (term: string) => void;
  results: SearchResult[] | null;
};

export type RecentProduct = {
  brand: string;
  condition: string;
  conditionValue: string;
  discountedPrice: string;
  id: number;
  image: string;
  price: string;
  slug: string;
  title: string;
  titleWithoutBrand: string;
};

export const getSearchSuggestions = async (
  search: string,
): Promise<SearchAPI> => {
  const domain = productApiHelper();
  const url = `${domain}/suggestions?search=${encodeURI(search)}`;
  const options: AxiosRequestConfig = {
    method: HttpMethod.GET,
    url,
    timeout: API_GATEWAY_HEADERS.TIMEOUT,
  };
  const result = await axios(options);
  return result?.data;
};
