import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { serializeError } from 'serialize-error';
import { ListingPageReducer } from '../types/Store';
import { productApiHelper } from '../utilities/helpers';
import { listingPageActionTypes as actionTypes } from '../actions/listingPageActions';
import {
  API_GATEWAY_HEADERS,
  MAX_PATHS,
} from '../utilities/constants';
import { HttpMethod } from '../types/Helpers';

type ListingPageActionPayload = {
  discountedtier: string;
  filters: string;
  isCPLP: boolean;
  page: number;
  query: {};
  search: string;
  slug: string;
  sortBy: string;
  type: string;
};

type ListingPageSuccessResponse = {
  data: ListingPageReducer;
  message: string;
  status: string;
};

type ListingPageSuccessOrErrorResponse = ListingPageSuccessResponse &
  AxiosError;

function* loadListingtPageSaga({
  page,
  sortBy,
  filters,
  slug,
  search,
  query,
  discountedtier,
  isCPLP,
}: ListingPageActionPayload): Generator<
  CallEffect | PutEffect,
  void,
  AxiosResponse<ListingPageSuccessOrErrorResponse>
> {
  const searchParam =
    search !== '' ? `&search=${encodeURI(search)}` : '';
  let categoryTypeQueryParam = '';
  let categoryType = '';
  let categoryName = '';
  if (slug.length > 0) {
    categoryType = slug[0] || '';
    if (slug.length === MAX_PATHS && slug[0] !== 'discounted') {
      categoryTypeQueryParam = `&categoryName=${slug[1]}`;
      categoryName = slug[1] || '';
    }
    if (slug[0] === 'discounted') {
      categoryTypeQueryParam += `&isDiscounted=1`;
    }
  }
  if ((slug[0] || '') !== 'discounted' && discountedtier) {
    categoryTypeQueryParam += `&discountedtier=${discountedtier}`;
  }
  const domain = productApiHelper();
  let options: AxiosRequestConfig;
  let url = '';

  if (slug[0] === 'sold-items') {
    const token = process.env.NEXT_PUBLIC_PRODUCT_API_KEY || '';
    url = `${domain}/soldItems?pagesize=120&page=${page}`;

    options = {
      headers: {
        Accept: 'application/json, text/plain, */*',
        Authorization: `Bearer ${token}`,
      },
      method: HttpMethod.GET,
      url,
      timeout: API_GATEWAY_HEADERS.TIMEOUT,
    };
  } else {
    url = `${domain}/productList?pagesize=120&page=${page}&sort=${sortBy}${searchParam}${filters}${categoryTypeQueryParam}`;
    if (isCPLP) {
      url = `${domain}/productList?slug=${slug[0]}&pagesize=120&page=${page}&sort=${sortBy}${filters}${categoryTypeQueryParam}`;
    }
    options = {
      headers: {
        Accept: 'application/json, text/plain, */*',
      },
      timeout: API_GATEWAY_HEADERS.TIMEOUT,
    };
  }

  // Check if on sold items page
  try {
    const res = yield call(axios.get, url, options);
    if (res.data?.status === 'success') {
      yield put({
        type: actionTypes.LOAD_PRODUCT_LISTING_PAGE_SUCCESS,
        status: res.status,
        message: res.data.message,
        data: res.data.data,
        slug,
        search,
        url,
        query,
        sortBy,
        page,
        categoryType,
        categoryName,
      });
    } else {
      yield put({
        type: actionTypes.LOAD_PRODUCT_LISTING_PAGE_ERROR,
        error: res.data.message || 'There was an error with the data',
        slug,
        url,
        stack: '',
        query,
        sortBy,
        page,
        categoryType,
        categoryName,
      });
    }
  } catch (error) {
    const errorObj = serializeError(error);
    const message =
      typeof errorObj.message !== 'undefined'
        ? errorObj.message
        : 'There was an error.';
    const stack =
      typeof errorObj.stack !== 'undefined' ? errorObj.stack : '';

    yield put({
      type: actionTypes.LOAD_PRODUCT_LISTING_PAGE_ERROR,
      error: message,
      stack,
      slug,
      url,
      query,
      sortBy,
      page,
      categoryType,
      categoryName,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(
    actionTypes.LOAD_PRODUCT_LISTING_PAGE,
    loadListingtPageSaga,
  );
}

export default loadSagaAll;
