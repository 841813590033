export const personalShopperActionTypes = {
  LOAD_PERSONAL_SHOPPER_DATA: 'LOAD_PERSONAL_SHOPPER_DATA',
  LOAD_PERSONAL_SHOPPER_DATA_ERROR:
    'LOAD_PERSONAL_SHOPPER_DATA_ERROR',
  LOAD_PERSONAL_SHOPPER_DATA_SUCCESS:
    'LOAD_PERSONAL_SHOPPER_DATA_SUCCESS',
};

export function loadPersonalShopper() {
  return {
    type: personalShopperActionTypes.LOAD_PERSONAL_SHOPPER_DATA,
  };
}
