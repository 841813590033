/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { cloneDeep } from 'lodash';
import type { LocationPageReducer } from '../types/Store';
import { locationPageActionTypes as actionTypes } from '../actions/locationPageActions';
import {
  reducerErrorMessage,
  reducerHelper,
} from '../utilities/helpers';

const initialState: LocationPageReducer = {
  storeLocationPageData: {
    slug: '',
    stores: [],
    nearStores: [],
    flagshipStores: [],
    serviceDeskStores: [],
    store: {},
    popularProducts: {
      results: [],
      totalResults: 0,
    },
  },
  error: null,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: LocationPageReducer = initialState,
  action: any,
): LocationPageReducer => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...action.payload.locationPageReducer,
      };
    }
    case actionTypes.LOAD_LOCATION_DATA_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_LOCATION_DATA_SUCCESS: {
      const newState = reducerHelper(
        state,
        action.type,
      ) as LocationPageReducer;
      const storeLocationPageData = cloneDeep(action.data);

      return {
        ...newState,
        storeLocationPageData,
      };
    }
    default:
      return state;
  }
};

export default reducer;
