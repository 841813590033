export const productPageActionTypes = {
  LOAD_PRODUCT_PAGE: 'LOAD_PRODUCT_PAGE',
  LOAD_PRODUCT_PAGE_ERROR: 'LOAD_PRODUCT_PAGE_ERROR',
  LOAD_PRODUCT_PAGE_SUCCESS: 'LOAD_PRODUCT_PAGE_SUCCESS',
  CLEAR_PRODUCT_PAGE: 'CLEAR_PRODUCT_PAGE',
  SET_GIFTCARD_PRICE: 'SET_GIFTCARD_PRICE',
};

export function loadProductPage(productSlug) {
  const slugs = productSlug.split('-');
  const productId = slugs[slugs.length - 1];
  return {
    type: productPageActionTypes.LOAD_PRODUCT_PAGE,
    productId,
    productSlug,
    isServer: true,
  };
}

export function clearListingPage() {
  return {
    type: productPageActionTypes.CLEAR_PRODUCT_PAGE,
  };
}
