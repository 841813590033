import React from 'react';
import { useSelector } from 'react-redux';
import { Rotator } from '@fashionphile/component-library';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import {
  Node,
  BLOCKS,
  Document,
  MARKS,
} from '@contentful/rich-text-types';
import { ShopState } from '../../types/Store';
import {
  fpEvent,
  isEventLocationUrl,
  isSellYourBagUrl,
  isSellerUrl,
  isMyAccountUrl,
  isExternalUrl,
  isEventShopUrl,
} from '../../utilities/fpEvents';
import { Bold, Text, SpanText } from '../contentful/text';

const MobileAppHeader = (): React.ReactElement => {
  const rotatorData =
    useSelector(
      (state: ShopState) => state.headerFooterReducer.rotatorData,
    ) || [];
  const user = useSelector(
    (state: ShopState) => state.cartReducer.user,
  );
  const productsInCart = useSelector(
    (state: ShopState) => state.cartReducer.productsInCart,
  );

  React.useEffect(() => {
    fpEvent('fpAddToCart', {
      productsInCart,
    });
  }, [productsInCart]);

  React.useEffect(() => {
    const links = document.querySelectorAll('a');
    links.forEach((link) => {
      if (isEventLocationUrl(link, window.location)) {
        link.addEventListener('click', (e) => {
          e.stopPropagation();
          e.preventDefault();
          fpEvent('fpOpenSellerLocation');
        });
      } else if (isSellYourBagUrl(link)) {
        link.addEventListener('click', (e) => {
          e.stopPropagation();
          e.preventDefault();
          fpEvent('fpOpenSellerPage');
        });
      } else if (isSellerUrl(link)) {
        link.addEventListener('click', (e) => {
          e.stopPropagation();
          e.preventDefault();
          fpEvent('fpOpenSellerURL', { url: link.href });
        });
      } else if (isMyAccountUrl(link)) {
        link.addEventListener('click', (e) => {
          e.stopPropagation();
          e.preventDefault();
          fpEvent('fpOpenAccountURL', { url: link.href });
        });
      } else if (isExternalUrl(link.href)) {
        link.addEventListener('click', (e) => {
          e.stopPropagation();
          e.preventDefault();
          fpEvent('fpOpenURL', { url: link.href });
        });
      } else if (isEventShopUrl(link, window.location)) {
        link.addEventListener('click', (e) => {
          e.stopPropagation();
          e.preventDefault();
          fpEvent('fpOpenShopURL', { url: link.href });
        });
      }
    });
    return (): void => {
      links.forEach((link) => {
        if (isEventLocationUrl(link, window.location)) {
          link.removeEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
            fpEvent('fpOpenSellerLocation');
          });
        } else if (isSellYourBagUrl(link)) {
          link.removeEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
            fpEvent('fpOpenSellerPage');
          });
        } else if (isSellerUrl(link)) {
          link.removeEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
            fpEvent('fpOpenSellerURL', { url: link.href });
          });
        } else if (isMyAccountUrl(link)) {
          link.removeEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
            fpEvent('fpOpenAccountURL', { url: link.href });
          });
        } else if (isExternalUrl(link.href)) {
          link.removeEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
            fpEvent('fpOpenURL', { url: link.href });
          });
        } else if (isEventShopUrl(link, window.location)) {
          link.removeEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
            fpEvent('fpOpenShopURL', { url: link.href });
          });
        }
      });
    };
  }, []);

  const options = {
    renderMark: {
      [MARKS.BOLD]: (
        text: React.ReactElement,
      ): React.ReactElement => <Bold className="bold">{text}</Bold>,
      [MARKS.ITALIC]: (
        text: React.ReactElement,
      ): React.ReactElement => (
        <SpanText className="italic">{text}</SpanText>
      ),
      [MARKS.UNDERLINE]: (
        text: React.ReactElement,
      ): React.ReactElement => (
        <SpanText className="underline">{text}</SpanText>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (
        _node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <Text className="excerpt">{children}</Text>
      ),
    },
    renderText: (text: string): string => text.replace('!', '?'),
  };

  const rotatorContent =
    rotatorData &&
    rotatorData.map((rotator, key) => (
      <div key={`option-${key}`}>
        {rotator
          ? documentToReactComponents(
              rotator as Document,
              options as Options,
            )
          : null}
      </div>
    ));

  return (
    <Rotator
      ariaLabel="Rotator"
      cartCount={0}
      desktopLinks={[]}
      loggedIn={!!user?.id}
      myAccountDropDownLinks={[]}
      secureDomain={process.env.CHECKOUT_APP}
      sellDomain={process.env.SELLER_APP}
      sellDropDownLinks={[]}
      tabIndex={0}
    >
      {rotatorContent}
    </Rotator>
  );
};

export { MobileAppHeader };
