import React from 'react';

type TextProps = {
  children: React.ReactElement;
  className: string;
};

const Bold = ({
  children,
  className,
}: TextProps): React.ReactElement => (
  <span className={className}>{children}</span>
);
const SpanText = ({
  children,
  className,
}: TextProps): React.ReactElement => (
  <span className={className}>{children}</span>
); // general
const Text = ({
  children,
  className,
}: TextProps): React.ReactElement => (
  <p className={className}>{children}</p>
);

export { Bold, Text, SpanText };
