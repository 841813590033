export const actionTypes = {
  RESET: 'RESET',
  LOAD_DATA: 'LOAD_DATA',
  LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
  LOAD_COLUMN_DATA: 'LOAD_COLUMN_DATA',
  LOAD_COLUMN_DATA_SUCCESS: 'LOAD_COLUMN_DATA_SUCCESS',
  LOAD_COLUMN_DATA_ERROR: 'LOAD_COLUMN_DATA_ERROR',
  LOAD_ROTATOR_DATA: 'LOAD_ROTATOR_DATA',
  LOAD_ROTATOR_DATA_SUCCESS: 'LOAD_ROTATOR_DATA_SUCCESS',
  LOAD_ROTATOR_DATA_ERROR: 'LOAD_ROTATOR_DATA_ERROR',
  HYDRATE: 'HYDRATE',
  IS_MOBILE: 'IS_MOBILE',
  SET_LOADER: 'SET_LOADER',
  SET_GIFTCARD_PRICE: 'SET_GIFTCARD_PRICE',
};

export function setMobileApp(device) {
  return {
    type: actionTypes.IS_MOBILE,
    isMobile: device.mobile,
    isOldMobile: device.oldMobile,
    isTablet: device.tablet,
    width: device.width,
    isMobileApp: device.isMobileApp,
    mobileAppVersion: device.mobileAppVersion,
  };
}
export function reset() {
  return { type: actionTypes.RESET };
}
export function loadHeaderData() {
  return { type: actionTypes.LOAD_ROTATOR_DATA };
}
export function loadFooterData() {
  return { type: actionTypes.LOAD_COLUMN_DATA };
}
export function setLoader(showLoader) {
  return {
    type: actionTypes.SET_LOADER,
    showLoader,
  };
}
export function setGiftCardPrice(customPrice) {
  return {
    type: actionTypes.SET_GIFTCARD_PRICE,
    price: customPrice,
  };
}
