export const dynamicLandingPageActionTypes = {
  LOAD_DYNAMIC_LANDING_PAGE_DATA: 'LOAD_DYNAMIC_LANDING_PAGE_DATA',
  LOAD_DYNAMIC_LANDING_DATA_SUCCESS:
    'LOAD_DYNAMIC_LANDING_DATA_SUCCESS',
  LOAD_DYNAMIC_LANDING_DATA_ERROR: 'LOAD_DYNAMIC_LANDING_DATA_ERROR',
};

export function loadDynamicLandingPage(params) {
  return {
    params,
    type: dynamicLandingPageActionTypes.LOAD_DYNAMIC_LANDING_PAGE_DATA,
  };
}
