export const noSearchResultsActionTypes = {
  LOAD_NO_SEARCH_RESULTS_DATA: 'LOAD_NO_SEARCH_RESULTS_DATA',
  LOAD_NO_SEARCH_RESULTS_DATA_SUCCESS:
    'LOAD_NO_SEARCH_RESULTS_DATA_SUCCESS',
  LOAD_NO_SEARCH_RESULTS_DATA_ERROR:
    'LOAD_NO_SEARCH_RESULTS_DATA_ERROR',
  CLEAR_NO_SEARCH_RESULTS_PAGE: 'CLEAR_NO_SEARCH_RESULTS_PAGE',
};

export function loadNoSearchResults() {
  return {
    type: noSearchResultsActionTypes.LOAD_NO_SEARCH_RESULTS_DATA,
  };
}

export function clearNoSearchResults() {
  return {
    type: noSearchResultsActionTypes.CLEAR_NO_SEARCH_RESULTS_PAGE,
  };
}
