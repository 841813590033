const TELEPHONE = '(844) 619-8902';
const HOMEPAGE_SLUG =
  'fashionphile-buy-sell-and-amp-consign-authentic-used-designer-luxury-items';
const PARTNERS_SLUG = 'fashionphile-or-partners-program';
const LOCATION_DETAILS_SLUG = 'fashionphile-location-details';
const INFLUENCER_SLUG = 'influencer';
const SOCIAL_FOOTER_SLUG = 'social-footer';
const AUTHENTICITY_SLUG = 'fashionphile-or-authenticity';
const LAYAWAY_SLUG = 'fashionphile-layaway';
const ABOUT_SLUG = 'about';
const NO_SEARCH_RESULTS_SLUG = 'no-search-results';
const JEWELRY_DISCLAIMER =
  'Due to grading limitations imposed by mounted stones, all grading of cut, color, clarity, and carat weight is approximate.';
const TRUTHY_INTEGER = 1;
const FALSY_INTEGER = 0;
const FAR_LEFT = 0;
const FAR_RIGHT = 2148;
const TOP_OF_PAGE = 0;
const SEARCHABLE_INDEX = 1;
const NOT_SEARCHABLE_INDEX = -1;
const HALF_DIVIDER = 2;
const DECIMAL_MULTIPLIER = 10;
const PERCENTAGE_MULTIPLIER = 100;
const MILLISECONDS_MULTIPLIER = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_TO_CACHE = 5;
const DEFAULT_STALE_TIME =
  MILLISECONDS_MULTIPLIER * SECONDS_IN_MINUTE * MINUTES_TO_CACHE;
const DEFAULT_MILLISECONDS_WAIT_DEBOUNCE = 250;

const CAROUSEL_MAX_SLIDES = 5;
const CAROUSEL_MAX_WIDTH = 1216;
const CAROUSEL_CARD_MOBILE_WIDTH = 192;
const CAROUSEL_CARD_DESKTOP_WIDTH = 224;
const CAROUSEL_MAX_GAP = 125;

const MAX_PATHS = 2;

const WIDTH = {
  SM: 577,
  MD: 796,
  LG: 993,
  XL: 1281,
  XXL: 1600,
  LARGEST: 3200,
};

const PADDING = {
  PIXEL: 1,
  MIN: 2,
  XXS: 4,
  XS: 8,
  SM: 16,
  MD: 24,
  LG: 32,
  XL: 48,
  XXL: 64,
  MAX: 96,
};

const API_RESPONSE_CODE = {
  UNAUTHORIZED_401: 401,
  INTERNAL_SERVER_ERROR_500: 500,
  SUCCESS_200: 200,
};

// see SortValue in .../types/Store.ts
const SORT_VALUES = [
  {
    dir: 'Ascending',
    key: 'price-asc',
    trackingType: 'Price',
    value: 'Price (low - high)',
  },
  {
    dir: 'Descending',
    key: 'price-desc',
    trackingType: 'Price',
    value: 'Price (high - low)',
  },
  {
    dir: 'Ascending',
    key: 'title-asc',
    trackingType: 'Alphabetical',
    value: 'Alphabetical (a-z)',
  },
  {
    dir: 'Descending',
    key: 'date-desc',
    trackingType: 'Age',
    value: 'Newest',
  },
  {
    dir: 'Ascending',
    key: 'date-asc',
    trackingType: 'Age',
    value: 'Oldest',
  },
  {
    dir: 'Descending',
    key: 'bestvalue-desc',
    trackingType: 'Value',
    value: 'Best Value',
  },
  {
    dir: 'Descending',
    key: 'popularity-desc',
    trackingType: 'Popularity',
    value: 'Most Popular',
  },
  {
    dir: 'Descending',
    key: 'pricedat-desc',
    trackingType: 'Recently Marked Down',
    value: 'Recently Marked Down',
  },
  {
    dir: 'Descending',
    key: 'relevance',
    trackingType: 'Relevance',
    value: 'Relevance',
  },
];
const PRODUCT_TYPES = {
  GIFT_CARD: 'GIFT_CARD',
  SWAG_ITEM: 'SWAG_ITEM',
  BRAND_PRODUCT: 'BRAND_PRODUCT',
  SOLD_OUT: 'SOLD_OUT',
};
const DISCOUNT_TIERS = {
  0: {
    start: 0,
    end: 29,
    rate: 0,
  },
  1: {
    start: 30,
    end: 44,
    rate: 5,
  },
  2: {
    start: 45,
    end: 74,
    rate: 10,
  },
  3: {
    start: 75,
    end: 119,
    rate: 20,
  },
  4: {
    start: 120,
    end: 149,
    rate: 30,
  },
  5: {
    start: 150,
    end: 179,
    rate: 31,
  },
};

const LAST_CALL_DESCRIPTION = `In Last Call, you will find our most deeply discounted items. Our Last Call Daily 
  Discounter slashes the prices down each item listed by a percentage every day for 30 days until they sell. 
  Most of our inventory never makes it to Last Call. The ones that do sell very quickly. If you're interested
  in any Last Call items, don't wait!`;
const MOBILE_PORTRAIT_BREAKPOINT = 576;
const MOBILE_BREAKPOINT = 769;
const TABLET_BREAKPOINT = 993;
const OLD_MOBILE_BREAKPOINT = 768;
const CUSTOM404_CONSTANTS = {
  desktop:
    'https://imagedelivery.net/qYsB-8vee77hLv8GooRU1g/1a5c9b24-d2dc-47da-a790-ae2e8e5ed000/public',
  tablet:
    'https://imagedelivery.net/qYsB-8vee77hLv8GooRU1g/91917e05-0cba-429f-840b-1530298fff00/public',
  mobile:
    'https://imagedelivery.net/qYsB-8vee77hLv8GooRU1g/4f72b8b4-a6b0-4cbd-b820-afee95a7b500/public',
};
const DATE_VALIDATOR =
  /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
const METADATA_ENTRY_IDS = {
  locationsDetail: 'RNcteZVsvb3v6qjcMjHOK',
  layaway: '4PjU3ilVa1gWfPEKZuuNRZ',
  influencer: '4Wpw2NRxo0Lu6b86FVjhAx',
  certified: '1e9ueJcS8TWHgn2s4ymBsF',
  default: '1bPkpfssZF1SMfeXAVx7WX',
  sellYourBag: '6SL4pUIB46WWY5gt9FxZBu',
  personalShopping: '3uyRDK07gZJdODIWBS41v4',
  sellWithUs: '4V6y8QLVjDu5ypS5yfcpSY',
  refresh: '1AHgunMHrftQ21JEX2HCD9',
  partnerPrograms: '2WszlURzi2neOAHt50cIRB',
  about: '4R7JbkNoXboqyZ2QBnvH0m',
  authenticity: 'wZmvXlGtwTU0eZ4vwWcOU',
  social: '79icqKQbeg7lEMQMA2RaIS',
};

const GIFT_CARD_ID = 10001;
const OPTIMIZELY_VARIATIONS = {
  RECOMMENDATIONS: {
    experimentId: '21641930084',
    variationId: '21633910259',
  },
};

export const EXAMPLE_PATH = 'cms-contentful';
export const CMS_NAME = 'Contentful';
export const CMS_URL = 'https://www.contentful.com';
export const HOME_OG_IMAGE_URL =
  'https://og-image.now.sh/Next.js%20Blog%20Example%20with%20**Contentful**.png?theme=light&md=1&fontSize=100px&images=https%3A%2F%2Fassets.vercel.com%2Fimage%2Fupload%2Ffront%2Fassets%2Fdesign%2Fnextjs-black-logo.svg&images=data%3Aimage%2Fsvg%2Bxml%3Bbase64%2CPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOSIgaGVpZ2h0PSIzMiI%2BCiAgPHBhdGggZmlsbD0iI0ZGRDg1RiIgZD0iTTkuNyAyMi4zQzggMjAuNyA3IDE4LjUgNyAxNnMxLTQuNyAyLjYtNi4zYzEuNC0xLjQgMS40LTMuNiAwLTVzLTMuNi0xLjQtNSAwQzEuOCA3LjYgMCAxMS42IDAgMTZzMS44IDguNCA0LjcgMTEuM2MxLjQgMS40IDMuNiAxLjQgNSAwIDEuMy0xLjQgMS4zLTMuNiAwLTV6Ij48L3BhdGg%2BCiAgPHBhdGggZmlsbD0iIzNCQjRFNyIgZD0iTTkuNyA5LjdDMTEuMyA4IDEzLjUgNyAxNiA3czQuNyAxIDYuMyAyLjZjMS40IDEuNCAzLjYgMS40IDUgMHMxLjQtMy42IDAtNUMyNC40IDEuOCAyMC40IDAgMTYgMFM3LjYgMS44IDQuNyA0LjdjLTEuNCAxLjQtMS40IDMuNiAwIDUgMS40IDEuMyAzLjYgMS4zIDUgMHoiPjwvcGF0aD4KICA8cGF0aCBmaWxsPSIjRUQ1QzY4IiBkPSJNMjIuMyAyMi4zQzIwLjcgMjQgMTguNSAyNSAxNiAyNXMtNC43LTEtNi4zLTIuNmMtMS40LTEuNC0zLjYtMS40LTUgMHMtMS40IDMuNiAwIDVDNy42IDMwLjIgMTEuNiAzMiAxNiAzMnM4LjQtMS44IDExLjMtNC43YzEuNC0xLjQgMS40LTMuNiAwLTUtMS40LTEuMy0zLjYtMS4zLTUgMHoiPjwvcGF0aD4KICA8Y2lyY2xlIGN4PSI3LjIiIGN5PSI3LjIiIHI9IjMuNSIgZmlsbD0iIzMwOEJDNSI%2BPC9jaXJjbGU%2BCiAgPGNpcmNsZSBjeD0iNy4yIiBjeT0iMjQuOCIgcj0iMy41IiBmaWxsPSIjRDU0NjVGIj48L2NpcmNsZT4KPC9zdmc%2B';

export const COOKIES = {
  PORT_KEY: 'port_key_j',
  IDENTITY_ID: 'identityId',
  EMAIL_POPUP: 'email_popup_counter',
  AFFIRM: 'affirmExperiment',
  SEGMENT_ID: 'ajs_anonymous_id',
  SEGMENT_USER_ID: 'ajs_user_id',
  REFERRER: 'referrer',
  CURRENT_SEARCH: 'current_search',
  FAVORITE_PRODUCTS: 'favoriteProducts',
  MOBILE_APP: 'x-fp-app',
  MOBILE_APP_OS: 'x-fp-os',
  MOBILE_APP_VERSION: 'x-fp-version',
};

export const API_GATEWAY_HEADERS = {
  IDENTITY_ID: 'x-fp-identityid',
  AUTH: 'Authorization',
  TIMEOUT: 20000,
};

export const SOCIAL_MEDIA = [
  {
    link: 'http://instagram.com/fashionphile',
    alt: 'instagram fashionphile',
    iconName: 'icon-brand-instagram',
  },
  {
    link: 'https://www.facebook.com/fashionphile',
    alt: 'facebook fashionphile',
    iconName: 'icon-brand-facebook-f',
  },
  {
    link: 'https://www.pinterest.com/fashionphile/',
    alt: 'pinterest fashionphile',
    iconName: 'icon-brand-pinterest',
  },
  {
    link: 'https://twitter.com/fashionphile',
    alt: 'twitter fashionphile',
    iconName: 'icon-brand-twitter',
  },
  {
    link: 'https://blog.fashionphile.com/',
    alt: 'blog fashionphile',
    iconName: 'icon-logo-knot',
  },
];

const CATEGORY_HANDBAG_STYLES_ID = 122;
const CATEGORY_ACCESSORIES_ID = 164;
const CATEGORY_JEWELRY_ID = 175;
const CATEGORY_WATCHES_ID = 265;
const CATEGORY_MERCHANDISE_ID = 271;
const CATEGORY_SWAG_ID = 272;

export {
  ABOUT_SLUG,
  AUTHENTICITY_SLUG,
  CUSTOM404_CONSTANTS,
  DATE_VALIDATOR,
  DISCOUNT_TIERS,
  GIFT_CARD_ID,
  HOMEPAGE_SLUG,
  INFLUENCER_SLUG,
  JEWELRY_DISCLAIMER,
  LAST_CALL_DESCRIPTION,
  LAYAWAY_SLUG,
  LOCATION_DETAILS_SLUG,
  METADATA_ENTRY_IDS,
  MOBILE_BREAKPOINT,
  MOBILE_PORTRAIT_BREAKPOINT,
  NO_SEARCH_RESULTS_SLUG,
  OLD_MOBILE_BREAKPOINT,
  OPTIMIZELY_VARIATIONS,
  PARTNERS_SLUG,
  PRODUCT_TYPES,
  SOCIAL_FOOTER_SLUG,
  SORT_VALUES,
  TABLET_BREAKPOINT,
  TELEPHONE,
  SEARCHABLE_INDEX,
  NOT_SEARCHABLE_INDEX,
  TRUTHY_INTEGER,
  FALSY_INTEGER,
  PERCENTAGE_MULTIPLIER,
  API_RESPONSE_CODE,
  FAR_LEFT,
  FAR_RIGHT,
  TOP_OF_PAGE,
  HALF_DIVIDER,
  DECIMAL_MULTIPLIER,
  MILLISECONDS_MULTIPLIER,
  SECONDS_IN_MINUTE,
  DEFAULT_STALE_TIME,
  DEFAULT_MILLISECONDS_WAIT_DEBOUNCE,
  CAROUSEL_MAX_WIDTH,
  CAROUSEL_CARD_MOBILE_WIDTH,
  CAROUSEL_CARD_DESKTOP_WIDTH,
  CAROUSEL_MAX_GAP,
  CAROUSEL_MAX_SLIDES,
  WIDTH,
  PADDING,
  CATEGORY_HANDBAG_STYLES_ID,
  CATEGORY_ACCESSORIES_ID,
  CATEGORY_JEWELRY_ID,
  CATEGORY_WATCHES_ID,
  CATEGORY_MERCHANDISE_ID,
  CATEGORY_SWAG_ID,
  MAX_PATHS,
};
