import { combineReducers } from 'redux';
import homepagePromoReducer from './homepagePromoReducer';
import headerFooterReducer from './headerFooterReducer';
import aboutPageReducer from './aboutPageReducer';
import partnersProgramReducer from './partnersProgramReducer';
import productPageReducer from './productPageReducer';
import algoliaListingPageReducer from './algoliaListingPageReducer';
import listingPageReducer from './listingPageReducer';
import influencerPageReducer from './influencerPageReducer';
import personalShopperPageReducer from './personalShopperPageReducer';
import navigationReducer from './navigationReducer';
import socialFooterTileReducer from './socialFooterTileReducer';
import noSearchResultsReducer from './noSearchResultsReducer';
import metadataReducer from './metadataReducer';
import newsletterReducer from './newsletterReducer';
import cartReducer from './cartReducer';
import dynamicLandingPageReducer from './dynamicLandingPageReducer';
import locationPageReducer from './locationPageReducer';
import appMetaReducer from './appMetaReducer';

export default combineReducers({
  homepagePromoReducer,
  headerFooterReducer,
  aboutPageReducer,
  partnersProgramReducer,
  productPageReducer,
  algoliaListingPageReducer,
  listingPageReducer,
  influencerPageReducer,
  navigationReducer,
  socialFooterTileReducer,
  personalShopperPageReducer,
  noSearchResultsReducer,
  metadataReducer,
  newsletterReducer,
  cartReducer,
  dynamicLandingPageReducer,
  locationPageReducer,
  appMetaReducer,
});
