/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { cloneDeep, get } from 'lodash';
import { NoSearchResultsReducer } from '../types/Store';
import { noSearchResultsActionTypes as actionTypes } from '../actions/noSearchResultsActions';
import {
  reducerErrorMessage,
  reducerHelper,
} from '../utilities/helpers';

const initialState = {
  noSearchData: {
    header: '',
    tiles: [],
  },
  error: null,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: NoSearchResultsReducer = initialState,
  action: any,
): NoSearchResultsReducer => {
  switch (action.type) {
    case HYDRATE: {
      const newState = {
        ...state,
        ...action.payload.noSearchResultsReducer,
      };
      newState.noSearchData = state.noSearchData;

      return newState;
    }
    case actionTypes.CLEAR_NO_SEARCH_RESULTS_PAGE: {
      const newState = reducerHelper(state, action.type);
      newState.noSearchData = initialState.noSearchData;
      newState.error = initialState.error;
      return newState;
    }
    case actionTypes.LOAD_NO_SEARCH_RESULTS_DATA_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_NO_SEARCH_RESULTS_DATA_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      const data = cloneDeep(action.data);
      const noSearchData = get(data, 'items.0.fields', {});

      noSearchData.tiles = noSearchData.tiles.map((tile: any) => {
        const { fields } = tile;
        return {
          cta: fields.ctaTextToDisplay,
          alt: fields.tileImageAltCopy,
          imageLink: fields.tileImage.fields.file.url,
          imageAlt: fields.tileImage.fields.title,
          ctaLink: fields.ctaLink,
          title: fields.title,
        };
      });
      return {
        ...newState,
        ...{ noSearchData },
      };
    }
    default:
      return state;
  }
};

export default reducer;
