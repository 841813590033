import { isEmpty, isNil, unset, get } from 'lodash';
import { QueryClient } from '@tanstack/react-query';
import { createClient, ContentfulClientApi } from 'contentful';
import { serializeError } from 'serialize-error';
import { Cookies } from 'react-cookie';
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  OLD_MOBILE_BREAKPOINT,
  GIFT_CARD_ID,
  SORT_VALUES,
  API_GATEWAY_HEADERS,
  COOKIES,
  NOT_SEARCHABLE_INDEX,
  TOP_OF_PAGE,
  FAR_LEFT,
  API_RESPONSE_CODE,
  SEARCHABLE_INDEX,
  WIDTH,
} from './constants';

import { Location } from '../types/Locations';
import { FooterDataEntityOrMyAccountDataEntity } from '../types/Store';

/**
 * Cookie domains map to URLs for our different environments
 *
 * Staging URL is https://staging-web.fashionphile.com/
 * Production URL is https://fashionphile.com/
 *
 * Local may be .fashionphile.test or localhost:8401 depending on
 * how a developer is running sd-nextjs. To capture this,
 * set the cookie domain to empty string "".
 *
 */
const COOKIE_DOMAIN = {
  staging: '.fashionphile.com',
  production: '.fashionphile.com',
  local: '',
  development: '.fashionphile.test',
  unistage: '.fashionphile.us',
  qa: '.fashionphile.us',
};

const DEGREES_TO_RADIANS_CONVERSION = 180;
const RADIANS_TO_DEGREES_CONVERSION = 180;
const DEGREES_TO_MILES_CONVERSION = 69;

type Distance = {
  store: Location;
  dist: number;
};

export const getCookieDomain = (): string =>
  COOKIE_DOMAIN[
    process.env.ENVIRONMENT as keyof typeof COOKIE_DOMAIN
  ] ?? COOKIE_DOMAIN.local;

const date = new Date();
export const DEFAULT_COOKIE_OPTIONS = {
  path: '/',
  expires: new Date(date.setFullYear(date.getFullYear() + 1)),
  domain: getCookieDomain(),
};

const DEFAULT_REFERRER_COOKIE_EXP_DAYS = 7;
const REFERRER_COOKIE_EXP_DAYS = process.env
  .NEXT_PUBLIC_REFERRER_COOKIE_EXP_DAYS
  ? parseInt(process.env.NEXT_PUBLIC_REFERRER_COOKIE_EXP_DAYS, 10)
  : DEFAULT_REFERRER_COOKIE_EXP_DAYS;

const today = new Date();
export const REFERRER_COOKIE_OPTIONS = {
  path: '/',
  expires: new Date(
    today.setDate(today.getDate() + REFERRER_COOKIE_EXP_DAYS),
  ),
  domain: getCookieDomain(),
};

/**
 * helper function that removes error from a successful reducer,
 * takes a copy of the state and returns it
 * @param {object} state - previous state
 * @param {string} actionType - action type
 * @returns {object} copied state
 */
const reducerHelper = <T>(state: T, actionType: string): T => {
  const errorActionType = actionType.replace('SUCCESS', 'ERROR');
  const newState = { ...state };
  unset(newState, `error.${errorActionType}`);
  unset(newState, 'error.url');
  unset(newState, 'error.stack');
  return newState;
};

/**
 * Helps create query srcset with proper query parameters for optimal contentful images
 */
const contentfulSrcSet = (
  fallback: string,
  dimensions: {
    [key: string]: number;
  } = {},
): string => {
  const breakpoints = ['sm', 'md', 'lg', 'xl', 'xxl', 'xxl2x'];
  const breakpointValues = [
    WIDTH.SM,
    WIDTH.MD,
    WIDTH.LG,
    WIDTH.XL,
    WIDTH.XXL,
    WIDTH.LARGEST,
  ];
  const definitions: string[] = [];
  // TO DO: add comment to explain this logic;
  const SECOND_FALLBACK = 2;
  const THIRD_FALLBACK = 3;
  breakpoints.forEach((breakpoint, breakpointIndex) => {
    if (dimensions[breakpoint] || dimensions[breakpoint] === null) {
      if (dimensions[breakpoint] === null) {
        // Set all 3 ratios to fallback
        definitions.push(
          `${fallback} ${breakpointValues[breakpointIndex]}w`,
        );
        definitions.push(
          `${fallback} ${
            breakpointValues[breakpointIndex] * SECOND_FALLBACK
          }w`,
        );
        definitions.push(
          `${fallback} ${
            breakpointValues[breakpointIndex] * THIRD_FALLBACK
          }w`,
        );
      } else {
        // 1x
        const optim = new URLSearchParams();
        optim.append('w', dimensions[breakpoint].toString());
        definitions.push(
          `${fallback}?${optim} ${breakpointValues[breakpointIndex]}w`,
        );

        // 2x
        const optim2x = new URLSearchParams();
        optim2x.append(
          'w',
          (dimensions[breakpoint] * SECOND_FALLBACK).toString(),
        );
        definitions.push(
          `${fallback}?${optim2x} ${
            breakpointValues[breakpointIndex] * SECOND_FALLBACK
          }w`,
        );

        // 3x
        const optim3x = new URLSearchParams();
        optim3x.append(
          'w',
          (dimensions[breakpoint] * THIRD_FALLBACK).toString(),
        );
        definitions.push(
          `${fallback}?${optim3x} ${
            breakpointValues[breakpointIndex] * THIRD_FALLBACK
          }w`,
        );
      }
    }
  });
  // Iterate through the definitions

  return definitions.join(', ');
};

/**
 * reducerErrorMessage is a helper function combines all cases statements
 * reduces DRY since several reducer cases have the same error message
 * @param {object} state - previous state
 * @param {string} action - action
 * @param {object} initialState - initial object for reducer state
 * @param {string} key - key for initial state property
 * @returns {object} copied state
 */
const reducerErrorMessage = <T>(
  state: { [key: string]: any } & T,
  action: {
    type: string;
    error: any;
    url?: string;
    stack?: string;
    customMessage?: string;
    status?: number;
  },
  initialState: { [key: string]: any } = {},
  key: string = '',
): { [key: string]: any } & T => {
  const newState = { ...state, error: state?.error ?? {} } as {
    [key: string]: any;
  } & T;
  if (!isEmpty(initialState) && key !== '') {
    newState[key as keyof T] = initialState[key];
  }
  newState.error[action.type] = action.error;
  if (action.url) {
    newState.error.url = action.url;
  }
  if (action.stack) {
    newState.error.stack = action.stack;
  }
  if (action.customMessage) {
    newState.error.customMessage = action.customMessage;
  }
  if (action.status) {
    newState.error.status = action.status;
  }
  return newState;
};

const getAccessToken = (
  isProd: boolean,
  isLanding: boolean,
): string | undefined => {
  if (isProd && isLanding) {
    return process.env.CONTENTFUL_DYNAMIC_ACCESS_TOKEN;
  }
  if (isProd && !isLanding) {
    return process.env.CONTENTFUL_ACCESS_TOKEN;
  }
  if (!isProd && isLanding) {
    return process.env.CONTENTFUL_DYNAMIC_PREVIEW_ACCESS_TOKEN;
  }
  return process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;
};

/**
 * get object for creating client
 * @returns client object
 */
const clientObj = (
  landingPage = '',
): {
  space: string;
  accessToken: string;
  host?: string;
} => {
  const isProd = process.env.ENVIRONMENT === 'production';
  const isLanding = landingPage === 'landingPage';
  const contentFulObj: {
    space: string;
    accessToken: string;
    host?: string;
  } = {
    space: isLanding
      ? process.env.CONTENTFUL_DYNAMIC_SPACE_ID || ''
      : process.env.CONTENTFUL_SPACE_ID || '',
    accessToken: getAccessToken(isProd, isLanding) || '',
  };

  if (!isProd) {
    contentFulObj.host = 'preview.contentful.com';
  }

  return contentFulObj;
};

/**
 * createClient
 * @returns createClient
 */
const getClient = (
  landingPage: string = '',
): ContentfulClientApi<undefined> => {
  return createClient(clientObj(landingPage));
};

/**
 * capitalize first letter of a string
 * @param {string} str
 * @returns {string}
 */
const capitalize = (str: string): string => {
  if (isNil(str) || str === '') {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * capitalize multiple words
 * @param {string} str
 * @returns {string}
 */
const capitalizeMulti = (str: string): string => {
  if (!str) {
    return '';
  }
  const strArr = str.split(' ');
  const newStr = strArr.reduce((acc, curr) => {
    return `${acc} ${capitalize(curr)}`;
  }, '');
  return newStr.trim();
};

/**
 * helper function for dollar formatting
 * @param {number} num - currency amount
 * @param {bool} decimal - show 2 digit decimal or not, default false
 * @returns {string} formatted dollar
 */
const currency = (num: number, decimal: boolean = false): string => {
  if (typeof num === 'undefined') {
    return '';
  }
  if (decimal) {
    return num.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }
  return `$${num.toLocaleString('en-US')}`;
};

/**
 * retrieve dimensions of dom element
 * @param {node} elem - dom node
 * @returns {object} - return object with properties top, left, and height
 */
const getCoords = (
  elem: any,
): { top: number; left: number; height: number } => {
  // thank you stack overflow
  const box = elem.getBoundingClientRect();
  const { body } = document;
  const docEl = document.documentElement;
  const scrollTop =
    window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft =
    window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
  const clientTop = docEl.clientTop || body.clientTop || TOP_OF_PAGE;
  const clientLeft = docEl.clientLeft || body.clientLeft || FAR_LEFT;
  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return {
    top: Math.round(top),
    left: Math.round(left),
    height: box.height,
  };
};

/**
 * local only, if product api call is coming from browser or server
 * @returns {string}
 */
const productApiHelper = (): string | undefined => {
  if (process.env.ENVIRONMENT !== 'local') {
    return process.env.API_GATEWAY;
  }

  // option to return a local instance of product api
  // if so, update your .env and add /api/v1 to your envs
  const domain =
    typeof window === 'undefined'
      ? process.env.PRODUCT_API_SERVER
      : process.env.PRODUCT_API;
  return domain;
};

const deviceHelper = (): {
  width: number;
  mobile: boolean;
  oldMobile: boolean;
  tablet: boolean;
  desktop: boolean;
  isMobileApp: boolean;
  mobileAppVersion: string;
} | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  const deviceInfo = {
    width: window.innerWidth,
    mobile: false,
    oldMobile: false,
    tablet: false,
    desktop: false,
    isMobileApp: false,
    mobileAppVersion: '',
  };

  if (window.innerWidth < MOBILE_BREAKPOINT) {
    deviceInfo.mobile = true;
  }
  if (window.innerWidth < OLD_MOBILE_BREAKPOINT) {
    deviceInfo.oldMobile = true;
  }
  if (window.innerWidth < TABLET_BREAKPOINT) {
    deviceInfo.tablet = true;
  }
  if (window.innerWidth >= TABLET_BREAKPOINT) {
    deviceInfo.desktop = true;
  }

  const cookies = new Cookies();
  const mobileAppCookie = cookies.get(COOKIES.MOBILE_APP);
  const mobileAppVersionCookie = cookies.get(
    COOKIES.MOBILE_APP_VERSION,
  );
  deviceInfo.isMobileApp = mobileAppCookie === '1';
  deviceInfo.mobileAppVersion = mobileAppVersionCookie || '';

  return deviceInfo;
};

/*
 * per Lauren, we need to keep contentful selection user friendly
 * helper function converts contentful string to readable format in component library
 * thereby rendering the button to its appropriate color
 * @param {string} color - contentful button color
 * @returns {string} a readable string that be render the component library btn
 */
type FPButton =
  | 'primary'
  | 'secondary'
  | 'pink'
  | 'ghost-black'
  | 'ghost-white'
  | 'clear'
  | undefined;
const convertButtonString = (color: string | undefined): FPButton => {
  let colorString: FPButton;
  if (!color) {
    return 'primary';
  }
  switch (color) {
    case 'Black':
      colorString = 'primary';
      break;
    case 'White':
      colorString = 'secondary';
      break;
    case 'Pink':
      colorString = 'pink';
      break;
    /**
     * TODO: remove entries 'Ghost-black' and 'Ghost-white' once we establish consistent color name spellings. For example,
     * convertButtonString expects 'Ghost-Black' but Contenful sends string as 'Ghost-black'. Both strings should be the same.
     */
    case 'Ghost-Black':
    case 'Ghost-black':
      colorString = 'ghost-black';
      break;
    case 'Ghost-White':
    case 'Ghost-white':
      colorString = 'ghost-white';
      break;
    case 'Clear':
      colorString = 'clear';
      break;
    default:
      colorString = 'primary';
  }
  return colorString;
};

/**
 * Helps clean image url
 * @param {String} url
 * @returns {String} cleaned url
 */
const sanitizeImageUrl = (url: string): string => {
  if (!url) {
    return '';
  }
  return url.startsWith('//') ? `https:${url}` : url;
};

/**
 * helper function for social tile and page, transform data to be jsx render-friendly
 * @param {array} data - contentful data coming in
 * @param {string} usage - identify it as 'page' or 'footer'
 * @returns {object} newly transformed data that easily be rendered in react
 */
const massageSocialData = (
  data: { socialTiles: [{ fields: any }] },
  usage: string,
): {
  title: string;
  igHandle: string;
  igUrl: string;
  imageUrl: string;
  imageAlt: string;
  ctaColor: string | undefined;
  ctaLink: string;
  ctaTextToDisplay: string;
  ctaTitle: string;
}[] => {
  const SOCIAL_TILES_MAX_IMAGES = 12;

  const myData =
    usage === 'footer'
      ? data.socialTiles.slice(0, SOCIAL_TILES_MAX_IMAGES)
      : data.socialTiles;

  return myData.map((ele) => {
    const title = get(ele, 'fields.title');
    const igHandle = get(ele, 'fields.username');
    const igUrl = get(ele, 'fields.igUrl');
    const imageUrl = sanitizeImageUrl(
      get(ele, 'fields.image.fields.file.url', ''),
    );
    const imageAlt = ele.fields.imageAltCopy
      ? get(ele, 'fields.imageAltCopy')
      : 'social tile placeholder';
    const {
      ctaColor: color,
      ctaLink,
      ctaTextToDisplay,
      title: ctaTitle,
    } = ele.fields.cta.fields;

    const ctaColor = convertButtonString(color);

    return {
      title,
      igHandle,
      igUrl,
      imageUrl,
      imageAlt,
      ctaColor,
      ctaLink,
      ctaTextToDisplay,
      ctaTitle,
    };
  });
};

/**
 * component library footer expects a data structure that api data doesn't 100% match
 * @param {array} data - an array of objects, this is the contentful data coming in
 * @returns {array} newly transformed data that easily be read by component library footer
 */
const parseFooterData = (
  data: FooterDataEntityOrMyAccountDataEntity[],
): Array<{
  title: string;
  list:
    | Array<{
        name: string;
        link: string;
      }>
    | undefined
    | null;
}> => {
  return data.map(
    (footerObj: {
      header: string;
      content?:
        | Array<{ text: string; link: string }>
        | null
        | undefined;
    }) => {
      const footerFieldObj = {
        title: footerObj.header,
        list: footerObj.content?.map(
          (item: { text: string; link: string }) => {
            const columnItem = {
              name: item.text,
              link: item.link,
            };
            return columnItem;
          },
        ),
      };

      return footerFieldObj;
    },
  );
};

const findMetadata =
  (search: string) =>
  (
    state: any,
  ):
    | {}
    | {
        title: string;
        description: string;
        keywords: string;
        ogTitle: string;
        ogImage: string;
        ogImageWidth: string;
        ogImageHeight: string;
        ogDescription: string;
        canonical: string;
      } => {
    const foundRecord = (
      state.metadataReducer?.metadata?.items || []
    ).find((record: any) => record.sys.id === search);
    if (foundRecord) {
      return {
        title: foundRecord?.fields?.metaTitle,
        description: foundRecord?.fields?.metaDescription,
        keywords: foundRecord?.fields?.metaKeywords,
        ogTitle: foundRecord?.fields?.ogtitle,
        ogImage: foundRecord?.fields?.ogimage?.fields?.file?.url,
        ogImageWidth:
          foundRecord?.fields?.ogimage?.fields?.file?.details?.image
            ?.width,
        ogImageHeight:
          foundRecord?.fields?.ogimage?.fields?.file?.details?.image
            ?.height,
        ogDescription: foundRecord?.fields?.ogdescription,
        canonical: foundRecord?.fields?.canonical,
      };
    }
    return {};
  };

const getCanonical = (uri: string): string => {
  return `${process.env.DOMAIN}${uri}`;
};

const productTypeHelper = (product: {
  [key: string]: any;
}): string => {
  if (product.id === GIFT_CARD_ID) {
    return 'GIFT_CARD';
  }
  if (product.isPurchasable) {
    if (product?.isSwagItem && !isEmpty(product?.productColors)) {
      return 'SWAG_ITEM';
    }
    return 'BRAND_PRODUCT';
  }
  return 'SOLD_OUT';
};

const errorHelper = (
  error: any,
): {
  stack: any;
  message: boolean;
  status: number | string;
  customMessage: string;
} => {
  const status = get(
    error,
    'response.status',
    API_RESPONSE_CODE.INTERNAL_SERVER_ERROR_500,
  );
  const errorObj = serializeError(error);
  let customMessage = error?.response?.data?.message;
  let customMessages: string[] = [];

  if (
    error?.response?.data?.errors &&
    Object.keys(error.response.data.errors).length > 0
  ) {
    // List of detailed errors
    customMessages = Object.keys(error.response.data.errors).map(
      (errorKey) => error.response.data.errors[errorKey],
    );
    customMessage = customMessages.join(', ');
  }

  const message = typeof errorObj.message !== 'undefined';
  const stack =
    typeof errorObj.stack !== 'undefined' ? errorObj.stack : '';
  return { stack, message, status, customMessage };
};

const getSortValues = (search: string): typeof SORT_VALUES => {
  if (search !== '') {
    return SORT_VALUES;
  }

  return SORT_VALUES.slice(0, SORT_VALUES.length - 1);
};

const undefToNull = <T extends { [key: string]: any }>(obj: T): T => {
  return obj === undefined
    ? (obj as T)
    : Object.keys(obj).reduce<T>((acc, key: keyof T) => {
        const value = obj[key];
        (acc as any)[key] = value === undefined ? null : value;
        return acc;
      }, {} as T);
};

const cartHeaderHelper = (
  identityId: string,
  portKey: string,
): any => {
  return {
    Accept: 'application/json, text/plain, */*',
    ...(identityId
      ? { [API_GATEWAY_HEADERS.IDENTITY_ID]: identityId }
      : {}),
    ...(portKey
      ? { [API_GATEWAY_HEADERS.AUTH]: `Bearer ${portKey}` }
      : {}),
  };
};

const sanitize = (originalString: string): string => {
  const stringWithoutSpecialCharacters = originalString.replace(
    /[&/\\@,$~%.'"?<>{}]/g,
    '',
  );
  return stringWithoutSpecialCharacters;
};

const formatPhone = (phone: number | string): string | undefined => {
  let phoneAsString;
  if (typeof phone === 'number') {
    phoneAsString = `${phone}`;
  } else {
    phoneAsString = sanitize(phone ?? '');
  }

  const phoneTest = new RegExp(
    /^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/,
  );

  const formattedPhone = phoneAsString.trim();
  const results = phoneTest.exec(formattedPhone);
  const MIN_PHONE_LENGTH = 8;
  const AREA_CODE = 3;
  const TELEPHONE_PREFIX = 4;
  const LINE_NUMBER = 5;
  if (results !== null && results.length > MIN_PHONE_LENGTH) {
    return `(${results[AREA_CODE]}) ${results[TELEPHONE_PREFIX]}-${
      results[LINE_NUMBER]
    }${
      typeof results[MIN_PHONE_LENGTH] !== 'undefined'
        ? ` x${results[MIN_PHONE_LENGTH]}`
        : ''
    }`;
  }
};

const calculateNearestStores = (
  lat: number,
  lon: number,
  stores: Location[],
  distanceThreshold: number,
): Location[] => {
  // User-inputted address or current page's store's coordinates in radians
  const radLon = lon * (Math.PI / DEGREES_TO_RADIANS_CONVERSION);
  const radLat = lat * (Math.PI / DEGREES_TO_RADIANS_CONVERSION);

  const distances: Distance[] = [];
  stores.forEach((store: Location) => {
    const comparedStoreLon = store?.address?.lon;
    const comparedStoreLat = store?.address?.lat;
    // Converting store we're comparing to's address to radians
    const radComparedStoreLon = comparedStoreLon
      ? comparedStoreLon * (Math.PI / DEGREES_TO_RADIANS_CONVERSION)
      : 0;
    const radComparedStoreLat = comparedStoreLat
      ? comparedStoreLat * (Math.PI / DEGREES_TO_RADIANS_CONVERSION)
      : 0;

    // Haversine formula (https://www.geodatasource.com/developers/javascript)
    const radTheta = radComparedStoreLon - radLon;
    let dist =
      Math.sin(radComparedStoreLat) * Math.sin(radLat) +
      Math.cos(radComparedStoreLat) *
        Math.cos(radLat) *
        Math.cos(radTheta);

    dist = Math.acos(dist);
    dist *= RADIANS_TO_DEGREES_CONVERSION / Math.PI;
    dist *= DEGREES_TO_MILES_CONVERSION;

    if (dist <= distanceThreshold) {
      distances.push({ store, dist });
    }
  });

  distances.sort((a: Distance, b: Distance) =>
    a.dist > b.dist ? SEARCHABLE_INDEX : NOT_SEARCHABLE_INDEX,
  );

  const nearStores = distances.map(({ store }) => store);

  return nearStores;
};

const isCompatibleVersion = (
  minCompatibleVersion: string,
  currentVersion: string,
): boolean => {
  // test if both params are version strings
  const isVersionFormat = /^[0-9](\.[0-9]{1,2})?(\.[0-9])?$/;
  if (
    !isVersionFormat.test(minCompatibleVersion) ||
    !isVersionFormat.test(currentVersion)
  ) {
    return false;
  }

  // localeCompare returns 0 if versions are the same, negative if version is older and positive is version is newer
  const SAME_VERSION = 0;
  const checkVersion = currentVersion.localeCompare(
    minCompatibleVersion,
    undefined,
    { numeric: true, sensitivity: 'base' },
  );
  if (checkVersion >= SAME_VERSION) {
    return true;
  }
  return false;
};
export const queryClient = new QueryClient();
export {
  reducerHelper,
  reducerErrorMessage,
  getClient,
  capitalize,
  currency,
  getCoords,
  productApiHelper,
  deviceHelper,
  convertButtonString,
  massageSocialData,
  parseFooterData,
  findMetadata,
  capitalizeMulti,
  getCanonical,
  contentfulSrcSet,
  productTypeHelper,
  errorHelper,
  getSortValues,
  undefToNull,
  cartHeaderHelper,
  sanitizeImageUrl,
  formatPhone,
  calculateNearestStores,
  isCompatibleVersion,
};
