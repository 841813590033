import { Cookies } from 'react-cookie';
import segmentEvents from '../segment/events';
// import { getCookieDomain } from '../helpers';

const cookies = new Cookies();

export const onDecision = ({ decisionInfo, userId, type }) => {
  // Check if a decision event was dispatched
  if (type === 'flag') {
    if (decisionInfo.decisionEventDispatched) {
      // Get experiment bucketing info
      const experimentKey = decisionInfo.ruleKey;
      const { variationKey, flagKey } = decisionInfo;

      const experimentCookieId = `${flagKey}_experiment_viewed`;
      const experimentViewed = !!cookies.get(experimentCookieId);

      if (!experimentViewed) {
        // Send data to analytics provider here
        if (process.env.ENVIRONMENT !== 'production') {
          console.log('window check', typeof window);
        }

        if (typeof window !== 'undefined') {
          window.optimizelyReactInstance.track(
            'opt_experiment_viewed',
            userId,
          );
          if (process.env.ENVIRONMENT !== 'production') {
            console.log('opt_experiment_viewed', userId);
          }
        }

        if (process.env.ENVIRONMENT !== 'production') {
          console.log('experimentKey', experimentKey);
          console.log('variationKey', variationKey);
        }
        segmentEvents.emit('experiment_viewed', {
          experiment_name: experimentKey ?? '',
          variation_name: variationKey ?? '',
        });
        if (process.env.ENVIRONMENT !== 'production') {
          console.log('setting cookie');
        }
        // cookies.set(experimentCookieId, true, {
        //   path: '/',
        //   domain: `${
        //     process.env.ENVIRONMENT !== 'local' ? 'www' : ''
        //   }${getCookieDomain()}`,
        // });
      }
    }
  }
};
