export type EventTypes =
  | 'fpAddToCart'
  | 'fpAddToFavorites'
  | 'fpCheckout'
  | 'fpLoadingFinish'
  | 'fpLoadingStart'
  | 'fpLogin'
  | 'fpOpenCart'
  | 'fpOpenSellerPage'
  | 'fpOpenSellerURL'
  | 'fpRegister'
  | 'fpRemoveFromCart'
  | 'fpRemoveFromFavorites'
  | 'fpOpenSellerLocation'
  | 'fpOpenAccountURL'
  | 'fpOpenURL'
  | 'fpOpenShopURL'
  | 'fpAnalyticsTrackEvent'
  | 'fpAnalyticsPageViewEvent'
  | 'fpShareContent'
  | 'fpSearchEnd';

type FlutterQueue = {
  eventName: string;
  payload: any;
};

let isReady = false;

const preReadyQueue: FlutterQueue[] = [];

if (typeof window !== 'undefined') {
  const initFlutter = (): void => {
    isReady = true;
    preReadyQueue.forEach((pre: FlutterQueue) => {
      window.dispatchEvent(
        new CustomEvent(pre.eventName, { detail: pre.payload }),
      );
    });
    preReadyQueue.length = 0;
  };
  window.addEventListener(
    'flutterInAppWebViewPlatformReady' as keyof WindowEventMap,
    initFlutter,
    true,
  );
}

export const fpAddEvent = (
  eventName: EventTypes,
  callback: (e: CustomEvent) => void,
): void => {
  if (typeof window !== 'undefined') {
    window.addEventListener(
      eventName as keyof WindowEventMap,
      callback as EventListener,
    );
  }
};

export const fpRemoveEvent = (
  eventName: EventTypes,
  callback: (e: CustomEvent) => void,
): void => {
  if (typeof window !== 'undefined') {
    window.removeEventListener(
      eventName as keyof WindowEventMap,
      callback as EventListener,
    );
  }
};

export const fpEvent = (
  eventName: EventTypes,
  payload?: any,
): void => {
  if (typeof window !== 'undefined') {
    if (isReady) {
      window.dispatchEvent(
        new CustomEvent(eventName, { detail: payload }),
      );
    } else {
      preReadyQueue.push({ eventName, payload });
    }
  }
};

export const isLocationUrl = (urlObject: any): Boolean => {
  const { pathname } = urlObject;
  if (/(\/locations\/|\/locations$)/i.test(pathname)) {
    return true;
  }
  return false;
};

export const isSellYourBagUrl = (urlObject: any): Boolean => {
  const { pathname } = urlObject;
  if (/(\/sell-your-bag)/i.test(pathname)) {
    return true;
  }
  return false;
};

export const isSellerUrl = (urlObject: any): Boolean => {
  const { pathname } = urlObject;
  if (
    /(\/sell-with-us|\/designer-directory|\/quote-tracker|\/virtual-appointment|\/white-glove|\/shiplist-label|\/shiplist-confirm|\/shiplist|\/sold-items)/i.test(
      pathname,
    )
  ) {
    return true;
  }
  return false;
};

export const isMyAccountUrl = (urlObject: any): Boolean => {
  const { pathname } = urlObject;
  if (/(\/account\/)/i.test(pathname)) {
    return true;
  }
  return false;
};

export const isExternalUrl = (url: string): Boolean => {
  if (url === '') {
    return false;
  }
  if (/^(?!.*.fashionphile.)(?!.*localhost.)/i.test(url)) {
    return true;
  }
  return false;
};

export const isShopUrl = (urlObject: any): Boolean => {
  const { pathname } = urlObject;
  if (
    /(\/p\/|\/c\/|\/l\/|\/shop\/|\/influencer|\/partners-program|\/social|\/sold-items)/i.test(
      pathname,
    ) ||
    pathname === `/`
  ) {
    return true;
  }
  return false;
};

export const isEventLocationUrl = (
  urlObject: any,
  originObject: any,
): Boolean => {
  if (isLocationUrl(urlObject) && isShopUrl(originObject)) {
    return true;
  }
  return false;
};

export const isEventShopUrl = (
  urlObject: any,
  originObject: any,
): Boolean => {
  if (isLocationUrl(originObject) && isShopUrl(urlObject)) {
    return true;
  }
  return false;
};
