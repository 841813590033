import { AnalyticsBrowser, ID } from '@segment/analytics-next';
import { deviceHelper } from '../helpers';
import { fpEvent } from '../fpEvents';

export const analytics = new AnalyticsBrowser();

export const loadSegment = (
  isCCPADoNotSellRequested = false,
): any => {
  let analyticsPreferences = {};
  if (typeof window !== 'undefined') {
    analyticsPreferences =
      (navigator as any)?.globalPrivacyControl ||
      isCCPADoNotSellRequested
        ? {
            load: {
              integrations: {
                'Data Lakes': true,
                Optimizely: true,
                'Google Analytics': false,
                'Actions Google Analytic 4': false,
                'Google AdWords New': false,
                'Google Enhanced Conversions': false,
                'Facebook Pixel': false,
                'Facebook Conversions API (Actions)': false,
                'Bing Ads': false,
                'Salesforce Marketing Cloud': false,
                'Impact Partnership Cloud': false,
                'Pinterest Tag': false,
                'Google Tag Manager': false,
                'Facebook CAPI Order Completed Function (Fashionphile)':
                  false,
                'Google Analytics 4 Web': false,
              },
            },
          }
        : {};
  }

  const options = {
    writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || '',
    page: false,
    host: process.env.NEXT_PUBLIC_SEGMENT_HOST || 'cdn.segment.com',
    ...analyticsPreferences,
  };

  analytics.load(options).catch((e) => {
    console.error('Error loading segment script: ', e);
  });
};

export const setSegmentId = (userId: ID, properties = {}): void => {
  const { isMobileApp } = deviceHelper() || {};

  if (!isMobileApp) {
    analytics?.identify(userId, properties);
  }
};

export const trackPage = (
  pageName?: string,
  properties?: any,
): void => {
  const { isMobileApp } = deviceHelper() || {};

  const context: { campaign: any } = {
    campaign: {},
  };

  const params = new URLSearchParams(window.location.search);
  const utmSource = params.get('utm_source');
  const utmMedium = params.get('utm_medium');
  const utmName = params.get('utm_campaign');
  const utmTerm = params.get('utm_term');
  const utmContent = params.get('utm_content');

  if (utmSource) {
    context.campaign.source = utmSource;
  }
  if (utmMedium) {
    context.campaign.medium = utmMedium;
  }
  if (utmName) {
    context.campaign.name = utmName;
  }
  if (utmTerm) {
    context.campaign.term = utmTerm;
  }
  if (utmContent) {
    context.campaign.content = utmContent;
  }

  const pageHasContextValues =
    Object.values(context.campaign).length > 0;

  if (isMobileApp) {
    fpEvent('fpAnalyticsPageViewEvent', {
      properties: {
        name: pageName,
        ...properties,
      },
    });
  } else if (pageHasContextValues) {
    analytics?.page(pageName, properties, context);
  } else {
    analytics?.page(pageName, properties);
  }
};

export const trackEvent = (
  action: string,
  params?: any,
  traits?: any,
): void => {
  const { isMobileApp } = deviceHelper() || {};
  const localStorageData = window?.localStorage
    ? window.localStorage.getItem('ajs_anonymous_id')
    : '';
  const anonymousId = localStorageData
    ? JSON.parse(localStorageData)
    : '';

  if (isMobileApp) {
    fpEvent('fpAnalyticsTrackEvent', {
      event: action,
      properties: { ...params },
      integrations: {
        optimizely: { userId: anonymousId },
      },
      traits,
    });
  } else {
    analytics?.track(action, params, {
      integrations: {
        optimizely: { userId: anonymousId },
      },
      ...traits,
    });
  }
};

export const setAnonymousId = (identityId: string): void => {
  window.localStorage.setItem(
    'ajs_anonymous_id',
    JSON.stringify(identityId),
  );
  document.cookie = `ajs_anonymous_id=${encodeURIComponent(
    identityId,
  )};`;
};

export const resetSegment = (identityId: string): void => {
  const A_STRING_INDEX = 0;
  const UNDERSCORE_STRING_INDEX = 4;
  // eslint-disable-next-line no-restricted-syntax
  for (const x in localStorage) {
    if (
      x.substr(A_STRING_INDEX, UNDERSCORE_STRING_INDEX) === 'ajs_'
    ) {
      delete localStorage[x];
    }
  }
  setAnonymousId(identityId);
};
