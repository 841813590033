import React, { useEffect } from 'react';
import { SFEmbeddedWindow as SFWindow } from '../../types/LiveChat';

const initESW = (gslbBaseURL: string = ''): void => {
  if (
    typeof window === 'undefined' ||
    !(window as SFWindow)?.embedded_svc
  ) {
    // this object will be generated by the script below, else it's broken
    return;
  }
  try {
    (window as SFWindow).embedded_svc.settings.displayHelpButton =
      true;
    (window as SFWindow).embedded_svc.settings.defaultMinimizedText =
      'Chat with Us';
    (window as SFWindow).embedded_svc.settings.loadingText =
      'Loading';
    (window as SFWindow).embedded_svc.settings.language = 'en';
    (window as SFWindow).embedded_svc.settings.enabledFeatures = [
      'LiveAgent',
    ];
    (window as SFWindow).embedded_svc.settings.entryFeature =
      'LiveAgent';
    (window as SFWindow).embedded_svc.settings.chatbotAvatarImgURL =
      'https://fashionphile.file.force.com/servlet/servlet.ImageServer?id=0153l0000067dGb&oid=00D1N000002r6Jz&lastMod=1687385192000';
    if (
      process.env.NEXT_PUBLIC_CHATBOT_CUSTOM_DOMAIN_ENABLED === 'true'
    ) {
      const SECOND_LEVEL_DOMAIN_INDEX = -2;
      (
        window as SFWindow
      ).embedded_svc.settings.storageDomain = `${document.domain
        .split('.')
        .slice(SECOND_LEVEL_DOMAIN_INDEX)
        .join('.')}`;
    }
    (window as SFWindow).embedded_svc.init(
      `${process.env.NEXT_PUBLIC_CHATBOT_SF_URL}`,
      `${process.env.NEXT_PUBLIC_CHATBOT_HELP_URL}`,
      gslbBaseURL,
      `${process.env.NEXT_PUBLIC_CHATBOT_ORG_ID}`,
      'LiveChat',
      {
        baseLiveAgentContentURL: `${process.env.NEXT_PUBLIC_CHATBOT_CONTENT_URL}`,
        deploymentId: '5723l000000XdHh',
        buttonId: '5733l000000XdTJ',
        baseLiveAgentURL: `${process.env.NEXT_PUBLIC_CHATBOT_AGENT_URL}`,
        eswLiveAgentDevName:
          'EmbeddedServiceLiveAgent_Parent04I3l000000Kz02EAC_1755481a27a',
        isOfflineSupportEnabled: false,
      },
    );
  } catch (error) {
    console.error(
      'Error initializing Salesforce integration:',
      error,
    );
  }
};

const LiveChat = (): React.ReactElement => {
  useEffect(() => {
    // requires browser context
    if (
      typeof window === 'undefined' ||
      typeof document === 'undefined'
    ) {
      return;
    }
    // Do nothing if the script is already loaded.
    if (document.getElementById('sf-live-agent-script')) {
      return;
    }
    const s = document.createElement('script');
    s.setAttribute(
      'src',
      `${process.env.NEXT_PUBLIC_CHATBOT_EMBEDDED_URL}`,
    );
    s.setAttribute('id', 'sf-live-agent-script');
    s.onload = (): void => initESW();
    s.onerror = (): void => {
      console.error('Salesforce Live Agent script failed to load.');
    };
    document.body.appendChild(s);
  }, []);
  return <></>;
};

export default LiveChat;
