/* eslint-disable quote-props */
import {
  CallEffect,
  PutEffect,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { serializeError } from 'serialize-error';
import { ProductResponseData } from '../types/Sagas';
import { productPageActionTypes as actionTypes } from '../actions/productPageActions';
import { productApiHelper } from '../utilities/helpers';
import { API_GATEWAY_HEADERS } from '../utilities/constants';

type ProductPageActionPayload = {
  productId: string;
  productSlug: string;
  type: string;
};

type ProductPageSuccessOrErrorResponse = ProductResponseData &
  AxiosError;

function* loadProductPageSaga({
  productId,
  productSlug,
}: ProductPageActionPayload): Generator<
  CallEffect | PutEffect,
  void,
  ProductPageSuccessOrErrorResponse
> {
  const domain = productApiHelper();
  const url = `${domain}/product/${productId}`;
  try {
    const options: AxiosRequestConfig = {
      headers: {
        Accept: 'application/json, text/plain, */*',
      },
      timeout: API_GATEWAY_HEADERS.TIMEOUT,
    };
    const res = yield call(axios.get, url, options);
    yield put({
      type: actionTypes.LOAD_PRODUCT_PAGE_SUCCESS,
      data: res.data,
      slug: productSlug,
      url,
    });
  } catch (error) {
    const errorObj = serializeError(error);
    const message =
      typeof errorObj.message !== 'undefined'
        ? errorObj.message
        : 'There was an error.';
    const stack =
      typeof errorObj.stack !== 'undefined' ? errorObj.stack : '';

    yield put({
      type: actionTypes.LOAD_PRODUCT_PAGE_ERROR,
      error: message,
      stack,
      url,
    });
  }
}

function* loadSagaAll(): Generator {
  yield takeEvery(actionTypes.LOAD_PRODUCT_PAGE, loadProductPageSaga);
}

export default loadSagaAll;
