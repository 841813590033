export enum LocationType {
  FLAGSHIP = 'flagship',
  FASHIONPHILE = 'fashionphile',
  SALON = 'salon',
  STUDIO = 'selling-studio',
  SERVICE_DESK = 'service-desk',
  POP_UP = 'pop-up',
}

export enum LocationServices {
  SHOP = 'Shop',
  SELL = 'Sell',
  DROP_OFF = 'Drop off',
  PICK_UP = 'Pick up',
  RETURNS = 'Returns',
  MINI_SPA = 'Mini spa',
}

interface ContentfulImage {
  description: string;
  file: { url?: string };
  fields: any;
  contentTypeId: string;
}

interface ContentfulServicesOffered {
  serviceHeadline: string;
  serviceDescription: string;
  tileImage: ContentfulImage;
  description: string;
  tileLink: string;
  fields: any;
  contentTypeId: string;
}

export type Location = {
  address?: { lon: number; lat: number };
  addressField?: string;
  appointments?: boolean;
  city?: string;
  citySlug?: string;
  customerTitle?: string;
  googleCid?: string;
  heroImage?: ContentfulImage;
  heroMobileImage?: ContentfulImage;
  hoursOfOperation?: string;
  holidayHours?: string;
  id?: number;
  inStoreLocationDetails?: string;
  metaDescription?: string;
  metaOgImage?: ContentfulImage;
  metaTitle?: string;
  noIndex?: boolean;
  nearStoresSlugs?: string[];
  neimanMarcusLocationDetails?: string;
  phoneNumber?: string;
  servicesOffered?: ContentfulServicesOffered[];
  slug?: string;
  stateShortCode?: string;
  street?: string;
  street2?: string;
  title?: string;
  type?:
    | LocationType.FLAGSHIP
    | LocationType.FASHIONPHILE
    | LocationType.SALON
    | LocationType.STUDIO
    | LocationType.SERVICE_DESK
    | LocationType.POP_UP;
  walkInDescription?: string;
  zipcode?: string;
};

interface Brand {
  id: number;
  name: string;
  parent_id: number | null;
  slug: string;
}

export type ServicesTile = {
  alt: string;
  description: string;
  image: string;
  link?: string;
  title: string;
};

export type PopularProduct = {
  authentication_status: string;
  brands: Brand[];
  condition: string;
  discount_disabled: boolean;
  discounted_price: string;
  discounted_tier: number;
  priced_at: string;
  following_count: number;
  id: number;
  is_featured: boolean;
  made_available_at: string;
  price: string;
  purchase_price: string;
  quantity: number;
  retail_price: string;
  ships_with: string;
  sku: string;
  title: string;
  title_without_brand: string;
  best_value: number;
  deduped_title: string;
  thumbnail: string;
  type: string;
  updated_at: string;
  slug: string;
};

export interface PopularProductResponse {
  results: PopularProduct[];
  totalResults: number;
}
