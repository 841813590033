/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { isEmpty, isNil, uniqBy, get } from 'lodash';
import {
  GIFT_CARD_ID,
  PERCENTAGE_MULTIPLIER,
} from '../utilities/constants';
import { productPageActionTypes as actionTypes } from '../actions/productPageActions';
import {
  reducerErrorMessage,
  reducerHelper,
  capitalize,
  productTypeHelper,
} from '../utilities/helpers';
import {
  ProductPageReducer,
  CarouselData,
  ProductDataEntity,
} from '../types/Store';

const initialState = {
  productData: null,
  error: null,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ProductPageReducer = initialState,
  action: any,
): ProductPageReducer => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.productPageReducer };
    }
    case actionTypes.CLEAR_PRODUCT_PAGE: {
      const newState = reducerHelper(state, action.type);
      newState.productData = initialState.productData;
      return newState;
    }
    case actionTypes.SET_GIFTCARD_PRICE: {
      const newState = reducerHelper(state, action.type);
      newState.productData = {
        ...newState.productData,
        price: action.price,
        salePrice: action.price,
        discountedPrice: action.price,
      };
      return newState;
    }
    case actionTypes.LOAD_PRODUCT_PAGE_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_PRODUCT_PAGE_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      const product = action.data.data;
      const breadcrumbs: { label: string; href?: string }[] = [];
      // temporary hack for missing primary category
      if (isNil(product.primaryCategory)) {
        product.primaryCategory = get(
          product,
          'breadcrumbs.parent',
          null,
        );
      }

      product.brandUrl = '';

      if (product.id !== GIFT_CARD_ID) {
        product.brandUrl =
          product.breadcrumbs.brand_url ??
          product.breadcrumbs.brandUrl;

        breadcrumbs.push({
          label: `${product.breadcrumbs.brand}: All`,
          href: product.brandUrl,
        });
        const parentChild = get(product, 'breadcrumbs.parent', '');
        if (parentChild) {
          breadcrumbs.push({
            label: parentChild,
            href:
              product.breadcrumbs.parent_url ??
              product.breadcrumbs.parentUrl,
          });
        }

        // will filter this out of the api on next iteration
        const child = get(product, 'breadcrumbs.child', '');
        if (child && child !== 'Mens' && child !== 'Womens') {
          breadcrumbs.push({
            label: child,
            href:
              product.breadcrumbs.child_url ??
              product.breadcrumbs.childUrl,
          });
        }
        breadcrumbs.push({
          label: product.title,
        });
      }

      product.breadcrumbs = breadcrumbs;
      product.isSizeRef = false;
      product.images = product.images.map(
        (
          image: {
            thumb: string; // TODO: follow up on these types, possible unify
            main: string;
            large: string;
            isSizeRef: boolean;
          },
          key: string,
        ) => {
          let altText = `${product.brandName} ${
            product.titleWithoutBrand
          } image ${key + 1} of ${product.images.length}`;
          let { thumb, main, large } = image;
          if (process.env.ENVIRONMENT === 'local') {
            thumb = thumb.replace(
              'local-product-api.fashionphile.test',
              'www.fashionphile.com',
            );
            main = main.replace(
              'local-product-api.fashionphile.test',
              'www.fashionphile.com',
            );
            large = large.replace(
              'local-product-api.fashionphile.test',
              'www.fashionphile.com',
            );
            thumb = thumb.replace('main', 'thumb');
            large = large.replace('main', 'large');
          }
          if (image.isSizeRef) {
            product.isSizeRef = true;
            altText = `${altText}. Model image displays an item of the same style and size. It does not reflect the condition, "comes with" items, material, or color of the item. Model is 5ft 8in.`;
          }
          return { thumb, main, large, altText };
        },
      );
      product.condition =
        product.condition === 'very_good'
          ? 'Very Good'
          : capitalize(product.condition);
      product.conditionsText = null;
      if (
        !isNil(product.conditions) &&
        !isEmpty(product.conditions)
      ) {
        product.conditions = uniqBy(product.conditions, 'name').map(
          (cond: any) => cond.name.trim(),
        ); // TODO: this seems broken
        product.conditionsText = product.conditions.join(', ');
      }

      product.price =
        product.price !== null ? parseFloat(product.price) : null;
      product.discountedPrice =
        product.discountedPrice !== null
          ? parseFloat(product.discountedPrice)
          : null;
      product.salePrice =
        product.salePrice !== null
          ? parseFloat(product.salePrice)
          : null;
      product.retailPrice =
        product.retailPrice !== null
          ? parseFloat(product.retailPrice)
          : null;
      product.discount = null;
      product.dos = 0;
      product.shipsWithList = null;
      if (
        product.shipsWith !== null &&
        product.shipsWith.trim() !== ''
      ) {
        product.shipsWithList = product.shipsWith.split(',');
      }
      if (product.discountedPrice < product.price) {
        product.dos = Math.round(
          (1 - product.discountedPrice / product.price) *
            PERCENTAGE_MULTIPLIER,
        );
        product.discount = `${product.dos}% off`;
      }
      if (isNil(product.titleWithoutBrand)) {
        const brand = product.brandName.toUpperCase();
        product.titleWithoutBrand = product.title.replace(brand, '');
      }
      product.oldSlug =
        product.slug !== action.slug ? action.slug : null;

      const discountValue = 0.1;
      product.layawayDownpaymentAmount = `$${Math.round(
        product.discountedPrice * discountValue,
      )}`;

      product.url = action.url;
      product.subCategories = product.subCategories.map(
        (cat: { [key: string]: string }) => cat.name, // TODO: type correct product info
      );
      const carouselData: CarouselData = {
        title: '',
        header: '',
        productIDs: '',
        productCount: 0,
      };
      if (product.recommendedProducts?.length) {
        carouselData.productData = product.recommendedProducts.map(
          (carouselItem: ProductDataEntity) => ({
            ...carouselItem,
            brand: carouselItem.brand,
            condition: carouselItem.condition,
            discountedPrice: carouselItem.discountedPrice,
            image: { thumb: carouselItem.image },
            price: carouselItem.price,
            productId: carouselItem.id,
            slug: carouselItem.slug,
            title: carouselItem.title,
            titleWithoutBrand: carouselItem.titleWithoutBrand,
          }),
        );
        carouselData.productData = carouselData?.productData?.filter(
          (cProduct) => cProduct.id !== product.id,
        );
        carouselData.header = 'You may also like';
        product.carouselData = carouselData;
        product.recommendedProductIds = [product.id.toString()];
        product.recommendedProducts.forEach(
          (fproduct: {
            [key: string]: string | number | unknown;
            id: number;
          }) => {
            product.recommendedProductIds.push(
              fproduct.id.toString(),
            );
          },
        );
      }
      product.productType = productTypeHelper(product);
      product.authenticCta = `We guarantee this is an authentic ${product.brandName} item or 100% of your money back. `;
      product.disclaimer = `${product.brandName}
        is a registered trademark of
        ${product.brandName}. FASHIONPHILE is not affiliated with
        ${product.brandName}.`;

      return {
        ...newState,
        ...{ productData: product },
      };
    }
    default:
      return state;
  }
};

export default reducer;
