/* eslint-disable indent */
import { HYDRATE } from 'next-redux-wrapper';
import { cloneDeep, get } from 'lodash';
import type { DynamicLandingPageReducer } from '../types/Store';
import { dynamicLandingPageActionTypes as actionTypes } from '../actions/dynamicLandingPageActions';
import {
  getCanonical,
  reducerErrorMessage,
  reducerHelper,
} from '../utilities/helpers';

const initialState = {
  dynamicLandingPgData: {
    contentBlockIdArr: [],
    slug: '',
  },
  error: null,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: DynamicLandingPageReducer = initialState,
  action: any,
): DynamicLandingPageReducer => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...action.payload.dynamicLandingPageReducer,
      };
    }
    case actionTypes.LOAD_DYNAMIC_LANDING_DATA_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_DYNAMIC_LANDING_DATA_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      const data = cloneDeep(action.data);
      const dynamicLandingPgData = get(data, 'fields', {});

      const slug = get(dynamicLandingPgData, 'slug', '');
      const crawlable =
        get(
          dynamicLandingPgData,
          'crawlable',
          '',
        ).toLocaleLowerCase() === 'no';

      const metadata = {
        ogTitle: get(dynamicLandingPgData, 'ogTitle', ''),
        ogUrl: get(
          dynamicLandingPgData,
          'ogImage.fields.file.url',
          '',
        ),
        ogImage: get(
          dynamicLandingPgData,
          'ogImage.fields.file.url',
          '',
        ),
        ogImageAlt: get(dynamicLandingPgData, 'ogImageAlt', ''),
        ogImageWidth: get(
          dynamicLandingPgData,
          'ogImage.fields.file.details.image.width',
          '',
        ),
        ogImageHeight: get(
          dynamicLandingPgData,
          'ogImage.fields.file.details.image.height',
          '',
        ),
        ogDescription: get(dynamicLandingPgData, 'ogDescription', ''),
        description: get(dynamicLandingPgData, 'metaDescription', ''),
        title: get(dynamicLandingPgData, 'metaTitle', ''),
        keywords: get(dynamicLandingPgData, 'metaKeywords', ''),
        canonical: getCanonical(`/c/${slug}`),
        noindex: crawlable,
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
      };
      const { contentBlocks } = dynamicLandingPgData;
      const contentBlockIdArr = (contentBlocks || []).map(
        (cblock: any) => {
          return cblock.sys.contentType.sys.id;
        },
      );
      dynamicLandingPgData.contentBlockIdArr = contentBlockIdArr;
      dynamicLandingPgData.slug = slug;

      return {
        ...newState,
        dynamicLandingPgData,
        metadata, //  TODO: ask why this is special metadata
      };
    }
    default:
      return state;
  }
};

export default reducer;
