import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  FormInput,
  Modal,
  Button,
  Icon,
} from '@fashionphile/component-library';
import classNames from 'classnames';
import { newsletter } from '../../actions/newsletterActions';
import emailValidator, {
  isValidEmailFormat,
} from '../../utilities/emailValidator';
import segmentEvents, {
  EVENTS,
} from '../../utilities/segment/events';
import {
  FAR_LEFT,
  MOBILE_PORTRAIT_BREAKPOINT,
  TOP_OF_PAGE,
} from '../../utilities/constants';

const EmailPopup = ({
  currentCount,
  incrementCount,
}: {
  currentCount: number;
  incrementCount: (setToMax?: boolean) => void;
}): React.ReactElement => {
  const [phase, setPhase] = useState(0);
  const [currentEmail, setCurrentEmail] = useState('');
  const [error, setError] = useState('');
  const [landScapeMode, setLandscapeMode] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);

  // store device width regardless of page orientation
  const minValue = Math.min(
    window?.screen?.width ?? FAR_LEFT,
    window?.screen?.height ?? TOP_OF_PAGE,
  );
  const isMobileScreen = minValue < MOBILE_PORTRAIT_BREAKPOINT;
  const dispatch = useDispatch();

  const CLICKS_ALLOWED = 3;

  const handleJoin = async (): Promise<void> => {
    if (currentEmail === '') {
      setError('Enter email address.');
    } else if (!isValidEmailFormat(currentEmail)) {
      setError('Please enter a valid email address.');
    } else {
      const isValid = await emailValidator(currentEmail);
      if (isValid) {
        dispatch(newsletter(currentEmail));
        setPhase(1);
      } else {
        setError('Please enter a valid email address.');
      }
    }
  };

  const handleResize = (): void => {
    const isLandscape =
      typeof window?.screen?.orientation?.type === 'undefined'
        ? window?.matchMedia('(orientation:landscape)').matches
        : window?.screen?.orientation?.type?.includes('landscape');
    if (isMobileScreen && isLandscape) {
      setLandscapeMode(true);
    } else {
      setLandscapeMode(false);
    }
  };

  useEffect(() => {
    // call eventhandler since Chrome does not fire resize event on page load.
    handleResize();
  }, []);

  useEffect(() => {
    if (currentCount === CLICKS_ALLOWED) {
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      segmentEvents.emit(EVENTS.modalOpen, {
        type: 'be in the know',
        url: window?.location?.href,
      });
    }
    window.addEventListener('resize', handleResize);
    return (): void => {
      window.removeEventListener('resize', handleResize);
      document.body.style.position = '';
      document.body.style.width = 'inherit';
    };
  }, [currentCount]);

  const inputBoxChange = (change: any): void => {
    setCurrentEmail(change?.target?.value);
  };

  const handleFocus = (): void => {
    setInputFocus(true);
  };
  const handleBlur = (): void => {
    setInputFocus(false);
  };

  const signupContent = (
    <>
      <h1>Be in the know.</h1>
      <p>
        Join for a first look at new arrivals, exclusive sales, and
        discounts on your favorite brands.
      </p>
      {landScapeMode ? (
        <FormInput
          id="emailPopupFormInputLandScapeMode"
          ariaLabel="Email Address"
          className="inputBox"
          label="Email Address"
          value={currentEmail}
          error={error}
          onChange={inputBoxChange}
          buttonPosition="right"
          buttonVariant="primary"
          buttonContent="JOIN NOW"
          buttonOnClick={handleJoin}
          onKeyDown={(event: any): void => {
            if (
              event.code === 'Enter' ||
              event.code === 'NumpadEnter'
            ) {
              event.preventDefault();
              handleJoin();
            }
          }}
        />
      ) : (
        <>
          <FormInput
            id="emailPopupFormInputNotLandScapeMode"
            ariaLabel="Email Address"
            onFocus={isMobileScreen ? handleFocus : undefined}
            onBlur={isMobileScreen ? handleBlur : undefined}
            className="inputBox"
            label="Email Address"
            value={currentEmail}
            error={error}
            onChange={inputBoxChange}
          />
          <Button
            ariaLabel="Join Now"
            content="Join Now"
            className="modalButton"
            onClick={handleJoin}
          />
        </>
      )}
    </>
  );

  const completedContent = (
    <>
      <div className="completeFillerTop" />
      <h1>
        <div>
          <Icon
            className="successIcon"
            name="icon-check-circle-solid"
          />
        </div>
        {`You're in!`}
      </h1>
      <p>You have been successfully subscribed to our newsletter.</p>
      <div className="completeFiller" />
    </>
  );

  return (
    <>
      <Modal
        descriptionId="emailPopupModalDescriptionId"
        title=""
        titleId="emailPopupModalTitleId"
        className="emailPopup customStyles"
        isDrawer={false}
        isPreventScroll={false}
        key="email-popup"
        ariaLabel="Email Signup Modal"
        classNameModal={classNames(
          'emailPopupModal',
          landScapeMode && 'landscape',
          inputFocus && 'mobileStyles',
        )}
        closeOnClick={(): void => {
          incrementCount(true);
          segmentEvents.emit(EVENTS.modalClose, {
            type: 'in the know email modal',
            url: window?.location?.href,
          });
        }}
        buttonContent={phase === 1 ? 'Start Shopping' : undefined}
        description={phase === 0 ? signupContent : completedContent}
        size="sm"
      />
    </>
  );
};

export default EmailPopup;
