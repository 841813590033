/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { HYDRATE } from 'next-redux-wrapper';
import { cloneDeep, get, clone, find } from 'lodash';
import { listingPageActionTypes as actionTypes } from '../actions/listingPageActions';
import {
  reducerErrorMessage,
  reducerHelper,
  capitalize,
  getCanonical,
  getSortValues,
} from '../utilities/helpers';
import { LAST_CALL_DESCRIPTION } from '../utilities/constants';
import {
  Filter,
  ListingData,
  ListingPageReducer,
  SortValue,
} from '../types/Store';

const TITLE = 'Shop Pre owned Designer Handbags | Used Designer Bags';
const DESCRIPTION =
  'Fashionphile offers a wide selection of pre-owned designer handbags and accessories. Add quality, used designer bags and more to your collection today!';
const initialState = {
  listingData: {
    slug: [],
    bio: '',
    uri: '/shop',
    title: '',
    heading: '',
    description: null,
    favorites: [],
    pages: 0,
    shopLink: null,
    tags: {
      ogTitle: '',
      ogUrl: '',
      ogImage: '',
      ogType: 'productListing',
      title: '',
      description: '',
      keywords: '',
      canonical: '',
    },
    appliedFilters: {},
    orderedFilters: [],
    loaded: false,
    results: [],
    url: '',
    search: '',
    query: {},
    categoryName: '',
    brandName: '',
    tealium: {
      categoryName: [],
      brandName: [],
      browseRefineType: [],
      browseRefineValue: [],
    },
    imagePath: '',
    sort: {
      key: 'date-desc',
      value: 'Newest',
    },
    subHeading: '',
    sortValues: [],
    ogImage: '',
  },
  error: null,
  showSpinner: false,
  isCPLP: false,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ListingPageReducer = initialState,
  action: any,
): ListingPageReducer => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...action.payload.listingPageReducer,
      };
    }
    case actionTypes.CLEAR_LISTING_PAGE: {
      return {
        ...initialState,
        isCPLP: state.isCPLP,
      };
    }
    case actionTypes.LOAD_PRODUCT_LISTING_PAGE: {
      const newState = reducerHelper(state, action.type);
      newState.showSpinner = true;
      newState.isCPLP = action.isCPLP;
      return newState;
    }
    case actionTypes.LOAD_PRODUCT_LISTING_PAGE_ERROR: {
      const newState = reducerErrorMessage(
        state,
        action,
        initialState,
        'listingData',
      );
      newState.showSpinner = false;
      newState.isCPLP = action.isCPLP;
      return newState;
    }
    case actionTypes.LOAD_PRODUCT_LISTING_PAGE_SUCCESS: {
      const newState = reducerHelper(state, action.type);
      const listingData = action.data as ListingData;
      listingData.categoryType = action.categoryType;
      listingData.slug = action.slug;
      const canonicalSlug: (string | null)[] =
        clone(listingData.slug) ?? [];
      if (listingData?.slug && listingData.slug.length > 0) {
        listingData.uri = `/shop/${listingData.slug.join('/')}`;
      }
      listingData.title = get(listingData, 'selected.title', '');
      listingData.heading = get(listingData, 'selected.name', '');
      listingData.subHeading = '';
      listingData.search = state.isCPLP ? '' : action.search;
      listingData.description = get(
        listingData,
        'selected.description',
        '',
      );
      listingData.bio = get(listingData, 'selected.bio', '');
      listingData.imagePath = get(
        listingData,
        'selected.imagePath',
        '',
      );
      listingData.sortValues = getSortValues(
        action.search,
      ) as SortValue[];
      if (
        process.env.ENVIRONMENT !== 'production' &&
        !!listingData.bio
      ) {
        listingData.bio = listingData.bio.replace(
          /https:\/\/www.fashionphile.com/g,
          process.env.DOMAIN!,
        );
      }
      if (listingData.categoryType === 'brands') {
        listingData.brandName = listingData.heading;
      }
      if (action.sortBy !== '') {
        listingData.sort = find(listingData.sortValues, {
          key: action.sortBy,
        });
      } else {
        listingData.sort = newState.listingData.sort;
      }
      // will most likely come from cookies later
      listingData.results = listingData?.results?.map((oldProd) => {
        const prod = cloneDeep(oldProd);

        if (process.env.ENVIRONMENT === 'local' && !!prod.thumbnail) {
          const thumb = prod.thumbnail.replace(
            'local-product-api.fashionphile.test',
            'www.fashionphile.com',
          );
          prod.thumbnail = thumb;
        }
        // hack for brand name
        if (prod.brands) {
          prod.brands.forEach((aBrand: any) => {
            if (aBrand.parent_id === null) {
              prod.brandName = aBrand.name;
            }
          });
        } else if (prod.brand_name) {
          prod.brands = [prod.brand_name];
          prod.brandName = prod.brand_name;
        }
        if (prod.condition) {
          prod.condition =
            prod.condition === 'very_good'
              ? 'Very Good'
              : capitalize(prod.condition);
        }
        if (prod.price) {
          prod.price =
            prod.price !== null ? parseFloat(prod.price) : null;
          prod.discounted_price =
            prod.discounted_price !== null
              ? parseFloat(prod.discounted_price)
              : null;
          prod.isDiscounted = prod.discounted_price < prod.price;
        }
        prod.favorite = false;
        return prod;
      });
      listingData.pages =
        listingData?.totalResults && listingData?.pageSize
          ? Math.ceil(listingData.totalResults / listingData.pageSize)
          : 0;
      listingData.shopLink = null; // link to shop page in filter list
      listingData.appliedFilters = {};

      // set page information for the various page types:
      // brand, category, discount pages, new arrivals
      if (
        listingData?.slug &&
        listingData.slug.length > 0 &&
        listingData.slug[0] !== 'sold-items'
      ) {
        if (action.categoryType === 'new-arrivals') {
          listingData.title = 'Shop New Arrivals | FASHIONPHILE';
          listingData.description = `Shop FASHIONPHILE's newest selection of pre-owned luxury designer handbags, jewelry, watches & more. Add quality, used luxury designer items to your collection today!`;
          listingData.heading = 'New Arrivals';
          listingData.shopLink = 'New Arrivals';
          listingData.ogImage =
            'https://imagedelivery.net/qYsB-8vee77hLv8GooRU1g/625b4eb1-8b72-4013-e52e-587e95b0e700/public';
        } else if (action.categoryType === 'discounted') {
          const mapPercentage: { [key: string]: string } = {
            1: 'Discounted: 5%',
            2: 'Discounted: 10%',
            3: 'Discounted: 20%',
            4: 'Discounted: 30%',
            5: 'Last Call',
            all: 'All Discounted',
          }; // TODO should these be strings?
          listingData.title = `Shop ${
            mapPercentage[listingData.slug[1]]
          } | Pre owned Designer Handbags | Used Designer Bags`;
          listingData.description = DESCRIPTION;
          if (listingData.slug[1] === '5') {
            listingData.bio = LAST_CALL_DESCRIPTION;
          }
          listingData.heading = mapPercentage[listingData.slug[1]];
        } else if (action.categoryType === 'collection') {
          if (listingData.imagePath) {
            if (process.env.ENVIRONMENT === 'local') {
              listingData.imagePath = listingData.imagePath.replace(
                /http:\/\/local-product-api.fashionphile.test/g,
                'https://www.fashionphile.com',
              );
            }
            listingData.imagePath = listingData.imagePath.replace(
              'product-images',
              'category-images',
            );
          }
          if (listingData.title === '') {
            listingData.title = `${listingData.heading} | FASHIONPHILE`;
          }
        }
      } else {
        // shop home page
        listingData.title = TITLE;
        listingData.description = DESCRIPTION;
      }
      if (listingData.search !== '') {
        listingData.heading = listingData.search;
        listingData.subHeading =
          listingData.results && listingData.results.length > 0
            ? 'Search results for:'
            : 'No results were found for';
      }

      if (
        ['brand', 'category'].includes(
          get(listingData, 'selected.type', ''),
        ) &&
        get(listingData, 'selected.parentSlug', null) !== null
      ) {
        canonicalSlug[0] = get(
          listingData,
          'selected.parentSlug',
          null,
        );
        canonicalSlug[1] = get(listingData, 'selected.slug', null);
      }
      let canonURI: string;
      if (listingData?.slug?.[0] === 'sold-items') {
        canonURI = '/sold-items';
      } else if (listingData?.customPLP) {
        canonURI = `/l${
          canonicalSlug.length > 0
            ? `/${canonicalSlug.join('/')}`
            : ''
        }`;
      } else {
        canonURI = `/shop${
          canonicalSlug.length > 0
            ? `/${canonicalSlug.join('/')}`
            : ''
        }`;
      }
      const canonicalUrl = getCanonical(canonURI);
      listingData.tags = {
        ogTitle:
          listingData?.customPLP?.metaTitle ||
          listingData.title ||
          '',
        ogUrl: canonicalUrl || '',
        ogImage:
          listingData.ogImage ||
          'https://www.fashionphile.com/images/knot.svg',
        ogType: 'productListing',
        title:
          listingData?.customPLP?.metaTitle ||
          listingData.title ||
          '',
        description:
          listingData?.customPLP?.description ||
          listingData.description ||
          '',
        keywords: listingData?.keywords || '',
        canonical: canonicalUrl || '',
        ogDescription: listingData.description || '',
        noindex: listingData?.customPLP?.noIndex
          ? listingData?.customPLP?.noIndex
          : false,
      };
      listingData.loaded = true;
      listingData.url = action.url;
      listingData.query = action.query;

      // set applied filters
      if (listingData.orderedFilters?.length) {
        const [parentCatSlug = '', categorySlug = ''] =
          listingData.slug || [];
        listingData.orderedFilters.forEach((parentFilter: Filter) => {
          const {
            slug: parentSlug,
            name: parentName,
            children,
          } = parentFilter;

          // parse applied filters
          children?.forEach((filterVal: Filter) => {
            const appliedFilter =
              (categorySlug !== filterVal.slug ||
                parentCatSlug === 'discounted') &&
              filterVal.applied;

            if (!appliedFilter) {
              return;
            }

            if (!listingData.appliedFilters[parentSlug]) {
              listingData.appliedFilters[parentSlug] = [];
            }
            // inject parent name + slug metadata
            listingData.appliedFilters[parentSlug].push({
              ...filterVal,
              parentName,
              parentSlug,
            });
          });
        });
      }
      // tealium
      listingData.tealium = {
        categoryName: [],
        brandName: [],
        browseRefineType: [],
        browseRefineValue: [],
      };
      if (
        listingData.slug &&
        listingData.slug.length > 1 &&
        listingData.slug[0] !== 'collection' &&
        listingData.slug[0] !== 'brands' &&
        listingData.slug[0] !== 'discounted'
      ) {
        if (listingData.tealium?.categoryName) {
          listingData.tealium?.categoryName.push(listingData.slug[1]);
        } else {
          listingData.tealium.categoryName = [listingData.slug[1]];
        }
      }
      if (listingData.brandName) {
        if (listingData.tealium.brandName) {
          listingData.tealium.brandName.push(listingData.brandName);
        } else {
          listingData.tealium.brandName = [listingData.brandName];
        }
      }
      if (listingData.query) {
        Object.keys(listingData.query).forEach((key) => {
          if (
            key !== 'sort' &&
            key !== 'page' &&
            key !== 'pageSize'
          ) {
            if (listingData.tealium.browseRefineType) {
              listingData.tealium.browseRefineType.push(key);
            } else {
              listingData.tealium.browseRefineType = [key];
            }
            const vals: string[] = listingData.query[key].split(' ');
            if (listingData.tealium.browseRefineValue) {
              listingData.tealium.browseRefineValue.push(...vals);
            } else {
              listingData.tealium.browseRefineValue = vals;
            }
          }
        });
      }
      newState.showSpinner = false;
      return {
        ...newState,
        ...{ listingData },
      };
    }
    default:
      return state;
  }
};

export default reducer;
