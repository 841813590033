/* eslint-disable @typescript-eslint/naming-convention */
import { ProductDataEntity } from '../../../types/Store';
import { SegmentFavorite } from '../interfaces';
import { productMapper } from './productMapper';

export default (
  data: Partial<ProductDataEntity>,
): SegmentFavorite => {
  const {
    brand,
    category,
    color,
    condition,
    currency,
    days_on_sale,
    discount_tier,
    last_call,
    material,
    name,
    price,
    product_discount,
    product_discount_amount,
    product_id,
    product_recommended,
    quantity,
    url,
    value,
  } = productMapper(data);

  return {
    brand,
    brands: null,
    categories: null,
    category,
    color,
    condition,
    currency,
    days_on_sale,
    discount_tier,
    last_call,
    material,
    name,
    price,
    product_discount,
    product_discount_amount,
    product_id,
    product_recommended,
    product_subcategory: null,
    quantity,
    sku: null,
    style: null,
    url,
    value,
  };
};
