export const appMetaActionTypes = {
  SET_LISTENING: 'SET_LISTENING',
};

export function setListening(isListening) {
  return {
    type: appMetaActionTypes.SET_LISTENING,
    isListening,
  };
}
