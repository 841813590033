/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { HYDRATE } from 'next-redux-wrapper';
import { cloneDeep } from 'lodash';
import { algoliaListingPageActionTypes as actionTypes } from '../actions/algoliaListingPageActions';
import {
  getCanonical,
  reducerErrorMessage,
  reducerHelper,
} from '../utilities/helpers';
import { AlgoliaListingPageReducer } from '../types/Store';
import {
  LAST_CALL_DESCRIPTION,
  MAX_PATHS,
} from '../utilities/constants';

const TITLE = 'Shop Pre owned Designer Handbags | Used Designer Bags';
const DESCRIPTION =
  'Fashionphile offers a wide selection of pre-owned designer handbags and accessories. Add quality, used designer bags and more to your collection today!';
const initialState: AlgoliaListingPageReducer = {
  algoliaListingData: {
    bio: '',
    carousel: [],
    categoryId: 0,
    categoryName: '',
    categoryPageId: '',
    categoryType: '',
    filters: '',
    heading: '',
    legacyType: '',
    listingPageSlug: null,
    loaded: false,
    page: 1,
    query: {},
    search: '',
    sort: 'date-desc',
    defaultSort: 'date-desc',
    subHeading: '',
    tags: {
      title: TITLE,
      description: DESCRIPTION,
      keywords: '',
      ogTitle: TITLE,
      ogDescription: DESCRIPTION,
      ogImage: 'https://www.fashionphile.com/images/knot.svg',
      ogImageWidth: '',
      ogImageHeight: '',
      ogType: 'productListing',
      noindex: false,
      ogUrl: '',
      canonical: '',
    },
    uri: '',
  },
  error: null,
};

const reducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AlgoliaListingPageReducer = initialState,
  action: any,
): AlgoliaListingPageReducer => {
  switch (action.type) {
    case HYDRATE: {
      return {
        ...state,
        ...action.payload.algoliaListingPageReducer,
      };
    }
    case actionTypes.LOAD_ALGOLIA_LISTING_PAGE_ERROR: {
      return reducerErrorMessage(state, action);
    }
    case actionTypes.LOAD_ALGOLIA_LISTING_PAGE_SUCCESS: {
      const newState = reducerHelper(
        state,
        action.type,
      ) as AlgoliaListingPageReducer;
      const clonedData = cloneDeep(action.data);
      const algoliaListingData = {
        ...initialState.algoliaListingData,
        ...clonedData,
      };
      algoliaListingData.loaded = true;
      if (algoliaListingData.listingPageSlug.length > 1) {
        algoliaListingData.categoryType =
          algoliaListingData.listingPageSlug[0] || '';
        if (
          algoliaListingData.listingPageSlug.length === MAX_PATHS &&
          algoliaListingData.listingPageSlug[0] !== 'discounted'
        ) {
          algoliaListingData.categoryName =
            algoliaListingData.listingPageSlug[1] || '';
        }
      } else {
        algoliaListingData.categoryType =
          algoliaListingData.legacyType;
        algoliaListingData.categoryName =
          algoliaListingData.categoryPageId;
      }

      if (
        algoliaListingData?.listingPageSlug &&
        algoliaListingData.listingPageSlug.length > 0
      ) {
        algoliaListingData.uri = `/shop/${algoliaListingData.listingPageSlug.join(
          '/',
        )}`;
      } else {
        algoliaListingData.uri = '/shop';
      }

      if (
        process.env.ENVIRONMENT !== 'production' &&
        !!algoliaListingData.bio
      ) {
        algoliaListingData.bio = algoliaListingData.bio.replace(
          /https:\/\/www.fashionphile.com/g,
          process.env.DOMAIN!,
        );
      }

      // TO DO: Delete this is content-api has the data;
      if (
        algoliaListingData?.listingPageSlug &&
        algoliaListingData.listingPageSlug.length > 0
      ) {
        if (
          algoliaListingData.listingPageSlug[0] === 'new-arrivals'
        ) {
          algoliaListingData.tags.title =
            'Shop New Arrivals | FASHIONPHILE';
          algoliaListingData.tags.ogTitle =
            'Shop New Arrivals | FASHIONPHILE';
          algoliaListingData.description = `Shop FASHIONPHILE's newest selection of pre-owned luxury designer handbags, jewelry, watches & more. Add quality, used luxury designer items to your collection today!`;
          algoliaListingData.heading = 'New Arrivals';
          algoliaListingData.tags.ogImage =
            'https://imagedelivery.net/qYsB-8vee77hLv8GooRU1g/625b4eb1-8b72-4013-e52e-587e95b0e700/public';
        } else if (algoliaListingData.categoryType === 'discounted') {
          const mapPercentage: { [key: string]: string } = {
            1: 'Discounted: 5%',
            2: 'Discounted: 10%',
            3: 'Discounted: 20%',
            4: 'Discounted: 30%',
            5: 'Last Call',
            all: 'All Discounted',
          };
          algoliaListingData.tags.title = `Shop ${
            mapPercentage[algoliaListingData.listingPageSlug[1]]
          } | Pre owned Designer Handbags | Used Designer Bags`;
          algoliaListingData.tags.description = DESCRIPTION;
          if (algoliaListingData.listingPageSlug[1] === '5') {
            algoliaListingData.bio = LAST_CALL_DESCRIPTION;
          }
          algoliaListingData.heading =
            mapPercentage[algoliaListingData.listingPageSlug[1]];
        } else if (action.categoryType === 'collection') {
          if (algoliaListingData.tags.title === '') {
            algoliaListingData.tags.title = `${algoliaListingData.heading} | FASHIONPHILE`;
          }
        }
      } else {
        algoliaListingData.tags.title = TITLE;
        algoliaListingData.tags.description = DESCRIPTION;
      }
      if (algoliaListingData.search) {
        algoliaListingData.heading = algoliaListingData.search;
        algoliaListingData.subHeading = 'Search results for:';
      }

      algoliaListingData.tags.ogTitle =
        algoliaListingData.tags.title || '';
      algoliaListingData.tags.ogImage =
        algoliaListingData.tags.ogImage ||
        'https://www.fashionphile.com/images/knot.svg';

      const canonicalUrl = getCanonical(algoliaListingData.uri);

      algoliaListingData.tags.ogUrl = canonicalUrl || '';
      algoliaListingData.tags.canonical = canonicalUrl || '';

      return {
        ...newState,
        algoliaListingData,
      };
    }
    default:
      return state;
  }
};

export default reducer;
